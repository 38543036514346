<script setup>

import { computed, ref, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthActions } from '@auth';
import { i18n, PopUpManager as PopUp, STATE, verifyField } from '@/Ship';
import { useProfileActions } from '@profile';

const signIn = useAuthActions('signIn');
const getProfileResource = useProfileActions('getProfileResource');

const router = useRouter();
const profile = getProfileResource();
const rememberMe = ref(false);
const showPassword = ref(false);

const payload = reactive({
    email: '',
    password: '',
});

const errors = reactive({
    email: null,
    password: null,
});

const email = computed({
    get: () => payload.email,
    set: (value) => {
        payload.email = value;
        errors.email = verifyField(email.value, ['require', 'email']);
        return true;
    }
});

const password = computed({
    get: () => payload.password,
    set: (value) => {
        payload.password = value;
        return true;
    }
});

if (localStorage.getItem('rememberMe')) {
    payload.email = localStorage.getItem('email');
    payload.password = localStorage.getItem('password');
    rememberMe.value = true;
}

const login = () => {
    signIn(payload.email, payload.password).then(() => {
        if (rememberMe.value) {
            localStorage.setItem('email', email.value);
            localStorage.setItem('password', password.value);
            localStorage.setItem('rememberMe', true);
        } else {
            localStorage.removeItem('username');
            localStorage.removeItem('password');
            localStorage.removeItem('rememberMe');
        }

        return profile.load().then(() => {
            STATE.IS_LOGIN = true;
            router.push({ name: 'Welcome', params: router.currentRoute.value.params });
        });
    }).catch(() => {
        PopUp
            .open(require('@auth/components/signUpInfoPonpUp'))
            .setCaption(i18n.global.t('base.info'))
            .setPayload({payload: {
                mainText: i18n.global.t('base.something_wrong'),
                additionText: i18n.global.t('auth.wrong_login_or_password')
            }});
    });
}
</script>

<template>
    <form class="sign-in" v-on:submit.prevent="login">
        <g-caption size="2" weight="600">{{ $t('auth.sign_in', 'Sign In') }}</g-caption>
        <fieldset>
            <legend>E-Mail</legend>
            <g-input v-model="email" v-bind:error="errors.email" autocomplete="on"/>
        </fieldset>

        <fieldset>
            <legend>{{ $t('auth.password', 'Password') }}</legend>
            <g-input v-model="password"
                     v-bind:type="showPassword ? 'text' : 'password'"
                     v-bind:error="errors.password">
                <g-symbol v-bind:name="showPassword ? 'eye-open' : 'eye-close'" width="24" height="24" v-on:click="showPassword = !showPassword"/>
            </g-input>
        </fieldset>

        <g-flex align="center" justify="between">
            <g-checkbox v-model:checked="rememberMe" class="white">{{ $t('auth.remember', 'Remember me') }}</g-checkbox>

            <g-button type="submit" class="login-btn">
                {{ $t('auth.sign_in') }}
            </g-button>
        </g-flex>
    </form>
</template>

<style lang="scss" scoped>
.sign-in {
    max-width: 460px;
    box-sizing: border-box;
}

.login-btn {
    color: white;
    padding: 0 20px;
    background-color: var(--secondary, $secondary);

    &:hover {
        color: black;
        background-color: var(--secondary-hover, $secondary-hover);
    }
}

fieldset {
    width: 280px;
    margin-bottom: 8px;
}

.g-input {
    color: #dddddd;
    background-color: var(--body-bg, $body-bg);

    &:deep(input):-webkit-autofill,
    &:deep(input):-webkit-autofill:hover,
    &:deep(input):-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 40rem var(--body-bg, $body-bg) inset;
    }
}
</style>
