export default [
    {
        name: 'Welcome',
        path: `/:locale(${process.env.VUE_APP_AVAILABLE_LOCALES})?`,
        redirect: to => {
            return { name: 'Leads' }
        },
        //component: () => import('./View.vue'),
    }, {
        name: 'Console',
        path: `/:locale(${process.env.VUE_APP_AVAILABLE_LOCALES})?/console`,
        component: () => import('./View.vue'),
    }
];
