import { useUserResources } from '@user';

export default (reload = false, referer = null) => {
    const user = useUserResources('User');
    if (reload || (!user.isLoaded && !user.isLoading)) {
        user.setIncludes(['roles', 'whitelist', 'teamleads', 'teams', 'brands']);
    }

    return user;
};
