import { useUserResources } from '@user';

export default () => {
    const whitelist = useUserResources('Whitelist');
    if (!whitelist.isLoaded && !whitelist.isLoading) {
        //whitelist.setIncludes([]);
        whitelist.load();
    }

    return whitelist;
};
