<script setup>
import { defineEmits, watch, reactive, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useUserActions } from '@user';
import { i18n, verifyField } from '@/Ship';
import { useSettingsResources } from "@settings";
import { useAuthComponents } from "@auth";
import { useTeamActions } from "@team";

const emit = defineEmits(['performed','close']);

const PasswordInput = useAuthComponents('PasswordInput');

const getUserResource = useUserActions('getUserResource');
const user = getUserResource();

const { getTeamResource } = useTeamActions();
const teams = getTeamResource();

const settings = useSettingsResources('Settings');
if (!settings.roles) {
    settings.load('roles');
}

const router = useRouter();

const isAllow = ref(false);
const isComplex = ref(false);

const payload = reactive({
    name: '',
    email: '',
    password: '',
    status: 'Active',
    roles: [],
    teams: [],
});

const errors = reactive({
    name: false,
    email: false,
});

const statusList = [
    { name: 'Active' },
    { name: 'Suspended' },
    { name: 'Deleted' }
];

const name = computed({
    get: () => {
        return payload.name;
    },
    set: (value) => {
        payload.name = value;
        errors.name = verifyField(value, ['require', 'minLength:2']);
        isAllow.value = !Object.values(errors).some(error => error !== null);
    }
});

const email = computed({
    get: () => {
        return payload.email;
    },
    set: (value) => {
        payload.email = value;
        errors.email = verifyField(value, ['require', 'email']);
        isAllow.value = !Object.values(errors).some(error => error !== null);
    }
});

function makeUser() {
    user.create(payload).then(response => {
        emit('close');
        user.index = user.state.findIndex(item => item.id === response.id);
        router.push({ name: 'User', params: { ...router.currentRoute.value.params, id: response.id } });
    }).catch(error => {
        if (error.response && error.response.data && error.response.data.description) {
            const errorDescriptions = error.response.data.description;

            for (const key in errorDescriptions) {
                if (errors[key] !== undefined) {
                    errors[key] = errorDescriptions[key][0];
                }
            }
        }
    });
}

</script>

<template>
    <form class="make-user" v-on:submit.prevent="makeUser">
        <g-flex class="container" justify="between" wrap>
            <fieldset>
                <legend>{{ $t('user.username', 'Username') }}</legend>
                <g-input v-model="name" v-bind:error="errors.name"/>
            </fieldset>

            <fieldset>
                <legend>Email</legend>
                <g-input v-model="email" type="email" v-bind:error="errors.email"/>
            </fieldset>

            <fieldset>
                <legend>{{ $t('setting.status', 'Status') }}</legend>
                <g-select v-model="payload.status"
                          v-bind:options="statusList"
                          option-text="name"
                          option-value="name"/>
            </fieldset>

            <fieldset>
                <legend>{{ $t('user.password', 'Password') }}</legend>
                <password-input v-model="payload.password" v-model:complex="isComplex"/>
            </fieldset>
        </g-flex>

        <fieldset class="roles">
            <legend>{{ $t('parts.roles') }}</legend>
            <div>
                <g-checkbox v-for="role in settings.roles"
                            v-model="payload.roles"
                            v-bind:value="role.id"
                            v-bind:key="`role-${role.id}`">
                    {{ role.name }}
                </g-checkbox>
            </div>
        </fieldset>

        <fieldset class="teams" v-bind:class="{ backlight: !!payload.teams }">
            <legend>{{ $t('parts.teams') }}</legend>
            <div>
                <g-checkbox v-for="team in teams.state"
                            v-model="payload.teams"
                            v-bind:value="team.id"
                            v-bind:key="`team-${team.id}`">
                    {{ team.name }}
                </g-checkbox>
            </div>
        </fieldset>

        <g-flex class="btn-bar" gap="5" justify="end">
            <g-button class="cancel-btn" type="button" v-on:click="$emit('close')">{{ $t('base.cancel') }}</g-button>
            <g-button class="save-btn" type="submit" v-bind:disabled="!isAllow">{{ $t('base.make') }}</g-button>
        </g-flex>
    </form>
</template>

<style scoped lang="scss">
.make-user {
    width: 460px;
}

.container > fieldset {
    width: 48%;
    margin-top: 8px;

    & > .g-input,
    & > .g-select,
    & > .password-input:deep(.g-input) {
        background-color: var(--body-bg, $body-bg);
    }

    & > .password-input:deep(.password-level > li:not(.active)) {
        background-color: #bbbbbb;
    }
}

.teams,
.roles {
    margin: 5px 0;

    & > div {
        padding: 10px;
        column-count: 3;
        column-gap: 5px;
        max-width: 460px;
        background-color: var(--body-bg, $body-bg);
        border-radius: var(--bar-rounding, $bar-rounding);
        border: 1px solid var(--input-border, $input-border);

        & > .g-checkbox {
            width: 100%;
            padding: 5px 0;
            display: inline-block;
        }
    }
}

.btn-bar {
    padding-top: 20px;

    & > .g-button {
        width: 100px;
    }
}
</style>