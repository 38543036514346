<script setup>
import { computed, defineProps, defineEmits } from 'vue';

const props = defineProps({
    total: {
        type: Number,
        required: true
    },
    perPage: {
        type: Number,
        required: true
    },
    modelValue: {
        type: Number,
        default: 1
    },
});

const emit = defineEmits(['update:modelValue', 'change']);

const totalPage = computed(() => {
    return Math.ceil(props.total / props.perPage) || 0;
});

const pages = computed(() => {
    const pages = [];
    for (let i = 1; i <= totalPage.value; i++) {
        pages.push(i);
    }

    return pages;
});

const end = computed(() => {
    return pages.value.slice(totalPage.value - 3, props.total);
});

const beginning = computed(() => {
    return props.modelValue < totalPage.value - 3
        ? pages.value.slice(props.modelValue - 2, props.modelValue + 1)
        : pages.value.slice(totalPage.value - 6, totalPage.value - 3);
});

function setPage(page) {
    emit('update:modelValue', page);
    emit('change', page);
}
</script>

<template>
    <ul v-if="totalPage > 1" class="g-pagination">
        <!--
        <li class="btn-prev"
            v-bind:class="{disabled: modelValue <= 1}"
            v-on:click="setPage(1)">
            <g-symbol name="arrow-first" width="12" height="12"/>
        </li>
        -->
        <li class="btn-prev"
            v-bind:class="{disabled: modelValue <= 1}"
            v-on:click="setPage(modelValue - 1)">
            <g-symbol name="arrow-previous" width="12" height="12"/>
        </li>
        <template v-if="totalPage > 9">
            <li v-for="page in beginning.length ? beginning : 3"
                v-bind:key="`page-${page}`"
                v-bind:class="{ active: modelValue === page }"
                v-on:click="setPage(page)">
                {{ page }}
            </li>
            <li v-if="modelValue < totalPage - 4">...</li>
            <li v-for="page in end"
                v-bind:key="`page-${page}`"
                v-bind:class="{ active: modelValue === page }"
                v-on:click="setPage(page)">
                {{ page }}
            </li>
        </template>
        <template v-else>
            <li v-for="page in totalPage"
                v-bind:key="`page-${page}`"
                v-bind:class="{ active: modelValue === page }"
                v-on:click="setPage(page)">
                {{ page }}
            </li>
        </template>
        <li class="btn-next"
            v-bind:class="{disabled: modelValue >= totalPage}"
            v-on:click="setPage(modelValue + 1)">
            <g-symbol name="arrow-next" width="12" height="12"/>
        </li>
        <!--
        <li class="btn-next"
            v-bind:class="{disabled: modelValue >= totalPage}"
            v-on:click="setPage(totalPage)">
            <g-symbol name="arrow-last" width="12" height="12"/>
        </li>
        -->
    </ul>
</template>

<style lang="scss" scoped>
.g-pagination {
    gap: 3px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    li {
        cursor: pointer;
        width: 30px;
        height: 30px;
        line-height: 22px;
        padding: 3px 0;
        text-align: center;
        color: var(--main-text-color, $main-text-color);
        fill: var(--main-text-color, $main-text-color);
        border: 1px solid var(--separator-color, $separator-color);
        border-radius: 3px;

        &:hover {
            color: var(--title-text-color, $title-text-color);
            transform: scale(1.1);
        }

        &.active {
            font-weight: 600;
            color: white;
            pointer-events: none;
            border-color: transparent;
            background-color: var(--secondary, $secondary);
        }
    }
}

.btn-prev,
.btn-next {
    font-size: 0;
    margin: 0 2px;

    &.disabled {
        opacity: 0.4;
        pointer-events: none;
    }

    & > .g-symbol {
        margin-top: 5px;
    }
}

@media (max-width: 980px) {
    .g-pagination {
        flex-wrap: wrap;
    }
}
</style>