import { useCountryResources } from '@country';

export default (filters) => {
    const country = useCountryResources('Country');
    if (!country.isLoaded && !country.isLoading) {
        country.setIncludes([]);
        country.sort('is_favorite', 'desc');

        country.load(filters);
    }

    return country;
};
