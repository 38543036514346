<template>
    <div class="filters">
        <g-filterset v-model="filters.roles"
                     v-bind:label="$t('parts.roles', 'Roles')"
                     value-field="value"
                     v-bind:expanded="true"
                     v-bind:filterset="filterset.roles"
                     v-on:change="filtrate"/>

        <g-filterset v-model="filters.teams"
                     v-bind:label="$t('parts.teams', 'Teams')"
                     value-field="id"
                     v-bind:expanded="true"
                     v-bind:filterset="filterset.teams"
                     v-on:change="filtrate"/>

        <g-filterset v-model="filters.status"
                     v-bind:label="$t('settings.statuses', 'Statuses')"
                     value-field="value"
                     v-bind:expanded="true"
                     v-bind:filterset="filterset.status"
                     v-on:change="filtrate"/>
    </div>
</template>

<script>

import { reactive } from 'vue';
import { useUserActions } from '@user';
import { useProfileActions } from '@profile';

const getUserResource = useUserActions('getUserResource');
const getProfileResource = useProfileActions('getProfileResource');

export default {
    name: 'UserFilters',

    setup() {
        const profile = getProfileResource();
        if (!profile.state.user) {
            profile.state.user = {
                filters: {},
                sortBy: 'users.id',
                sortDir: 'desc',
                //perPage: 25,
            };
        }

        const users = getUserResource();
        const filters = reactive({
            roles: [],
            teams: [],
            statuses: []
        });

        Object.assign(filters, profile.state.user.filters);

        users.setFilters(filters);

        function filtrate() {
            users.setFilters(filters);
            users.setPage(1);
            users.load();

            profile.state.user.filters = filters;
            profile.saveState();
        }

        return {
            filters,
            filterset: users.filterset,
            filtrate,
        };
    }
};
</script>

<style lang="scss" scoped>
.filters {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    max-height: 80vh;
    overflow: auto;
    transition: max-height 0.3s;
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-thumb-color, $scrollbar-thumb-color) transparent;

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 1ex;
        background-color: var(--scrollbar-thumb-color, $scrollbar-thumb-color);
    }
}
</style>