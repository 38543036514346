<script setup>

import { watch, defineEmits, defineProps, reactive, computed } from "vue";
import { useProfileActions } from '@profile';
import { useLeadActions } from '@lead';

const props = defineProps(['payload']);
const emit = defineEmits(['change']);

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const getLeadResource = useLeadActions('getLeadResource');
const lead = getLeadResource();

const payload = reactive({});
const specifics = reactive({});

watch(props.payload, () => {
    if (!Object.keys(props.payload).length) {
        Object.keys(payload).forEach(key => delete payload[key]);
        Object.keys(specifics).forEach(key => delete specifics[key]);
    }
});

function setValue(key, value) {
    if (lead.model[key] === value) {
        delete payload[key];
    } else {
        payload[key] = value;
    }

    emit('change', payload);
}

function setSpecific(key, value) {
    if (lead.model?.specifics[key] === value) {
        delete specifics[key];
    } else {
        specifics[key] = value;
    }
    payload.specifics = specifics;
    emit('change', payload);
}

const gender = computed({
    get: () => payload.gender || lead.model.gender,
    set: (value) => {
        setValue('gender', value);
        return true;
    }
});

const maritalStatus = computed({
    get: () => payload.marital_status || lead.model.marital_status,
    set: (value) => {
        setValue('marital_status', value);
        return true;
    }
});

const timezone = computed({
    get: () => payload.timezone || lead.model.timezone,
    set: (value) => {
        setValue('timezone', value);
        return true;
    }
});

const region = computed({
    get: () => specifics.region || lead.model?.specifics?.region,
    set: (value) => {
        setSpecific('region', value);
        return true;
    }
});

const city = computed({
    get: () => specifics.city || lead.model?.specifics?.city,
    set: (value) => {
        setSpecific('city', value);
        return true;
    }
});

const address = computed({
    get: () => specifics.address || lead.model?.specifics?.address,
    set: (value) => {
        setSpecific('address', value);
        return true;
    }
});

const postcode = computed({
    get: () => specifics.postcode || lead.model?.specifics?.postcode,
    set: (value) => {
        setSpecific('postcode', value);
        return true;
    }
});

const birthday = computed({
    get: () => specifics.birthday || lead.model?.specifics?.birthday,
    set: (value) => {
        setSpecific('birthday', value);
        return true;
    }
});

const age = computed({
    get: () => specifics.age || lead.model?.specifics?.age,
    set: (value) => {
        setSpecific('age', value);
        return true;
    }
});

const jobPosition = computed({
    get: () => specifics.job_position || lead.model?.specifics?.job_position,
    set: (value) => {
        setSpecific('job_position', value);
        return true;
    }
});
</script>

<template>
    <fieldset>
        <legend v-bind:class="{ changed: !!payload.gender }">
            {{ $t('lead.gender', 'Gender') }}
        </legend>
        <g-select v-model="gender"
                  v-bind:backlight="!!payload.gender"
                  v-bind:options="[{value: 'Male'}, {value: 'Female'}, {value: 'Other'}]"
                  option-value="value"
                  option-text="value"
                  v-bind:readonly="profile.cant('Lead DetailsEdit')"/>
    </fieldset>

    <fieldset>
        <legend v-bind:class="{ changed: !!payload.marital_status }">
            {{ $t('lead.marital_status', 'Marital status') }}
        </legend>
        <g-select v-model="maritalStatus"
                  v-bind:backlight="!!payload.marital_status"
                  v-bind:options="[{value: 'Unmarried'}, {value: 'Married'}, {value: 'Divorced'}, {value: 'Widowed'}, {value: 'Legally Separated'}]"
                  option-value="value"
                  option-text="value"
                  v-bind:readonly="profile.cant('Lead DetailsEdit')"/>
    </fieldset>

    <fieldset>
        <legend v-bind:class="{ changed: !!specifics.region }">
            {{ $t('lead.region', 'Region') }}
        </legend>
        <g-input v-model="region" v-bind:backlight="!!specifics.region" v-bind:readonly="profile.cant('Lead DetailsEdit')">
            <g-symbol v-if="profile.cant('Lead DetailsEdit')" name="lock" width="18" height="18"/>
        </g-input>
    </fieldset>

    <fieldset>
        <legend v-bind:class="{ changed: !!specifics.city }">
            {{ $t('lead.city', 'City') }}
        </legend>
        <g-input v-model="city" v-bind:backlight="!!specifics.city" v-bind:readonly="profile.cant('Lead DetailsEdit')">
            <g-symbol v-if="profile.cant('Lead DetailsEdit')" name="lock" width="18" height="18"/>
        </g-input>
    </fieldset>

    <fieldset>
        <legend v-bind:class="{ changed: !!specifics.address }">
            {{ $t('lead.address', 'Address') }}
        </legend>
        <g-input v-model="address" v-bind:backlight="!!specifics.address" v-bind:readonly="profile.cant('Lead DetailsEdit')">
            <g-symbol v-if="profile.cant('Lead DetailsEdit')" name="lock" width="18" height="18"/>
        </g-input>
    </fieldset>

    <fieldset>
        <legend v-bind:class="{ changed: !!specifics.postcode }">
            {{ $t('lead.postcode', 'Postcode') }}
        </legend>
        <g-input v-model="postcode" v-bind:backlight="!!specifics.postcode" v-bind:readonly="profile.cant('Lead DetailsEdit')">
            <g-symbol v-if="profile.cant('Lead DetailsEdit')" name="lock" width="18" height="18"/>
        </g-input>
    </fieldset>

    <fieldset>
        <legend v-bind:class="{ changed: !!payload.timezone }">
            {{ $t('lead.timezone', 'Timezone') }}
        </legend>
        <g-input v-model="timezone" v-bind:backlight="!!payload.timezone" v-bind:readonly="profile.cant('Lead DetailsEdit')">
            <g-symbol v-if="profile.cant('Lead DetailsEdit')" name="lock" width="18" height="18"/>
        </g-input>
    </fieldset>

    <fieldset>
        <legend v-bind:class="{ changed: !!specifics.birthday }">
            {{ $t('lead.birthday', 'Birthday') }}
        </legend>
        <g-input v-model="birthday" v-bind:backlight="!!specifics.birthday" type="date" v-bind:readonly="profile.cant('Lead DetailsEdit')">
            <g-symbol v-if="profile.cant('Lead DetailsEdit')" name="lock" width="18" height="18"/>
        </g-input>
    </fieldset>

    <fieldset>
        <legend v-bind:class="{ changed: !!specifics.age }">
            {{ $t('lead.age', 'Age') }}
        </legend>
        <g-input v-model="age" v-bind:backlight="!!specifics.age" v-bind:readonly="profile.cant('Lead DetailsEdit')">
            <g-symbol v-if="profile.cant('Lead DetailsEdit')" name="lock" width="18" height="18"/>
        </g-input>
    </fieldset>

    <fieldset>
        <legend v-bind:class="{ changed: !!specifics.job_position }">
            {{ $t('lead.job_position', 'Job position') }}
        </legend>
        <g-select v-model="jobPosition"
                  v-bind:backlight="!!specifics.job_position"
                  v-bind:options="[{value: 'Worker'}, {value: 'Employee'}, {value: 'Middle Manager'}, {value: 'Top-Manager'}, {value: 'Self-Employed'}]"
                  option-value="value"
                  option-text="value"
                  v-bind:readonly="profile.cant('Lead DetailsEdit')"/>
    </fieldset>
</template>

<style scoped lang="scss">
legend.changed {
    color: var(--seconsary, $secondary);
}
</style>