<script setup>
import { defineEmits, defineProps } from 'vue';
import { PopUpManager as PopUp } from '@/Ship';

const props = defineProps(['payload']);
const emit = defineEmits(['performed', 'close']);

function closePopup() {
    emit('close');
}

function confirm() {
    emit('performed');
}

const payload = { icon: 'delete', text: 'remove', fill: 'danger' }
Object.assign(payload, PopUp.payload.payload)

</script>

<template>
    <div class="remove-position-confirm">
        <div class="body"></div>
        <div class="footer">
            <g-button icon-color="danger" v-on:click="closePopup">
                <g-symbol name="close" width="20" height="20"/>
                {{ $t('base.cancel') }}
            </g-button>

            <g-button :class="payload.fill" v-on:click="confirm">
                <g-symbol :name="payload.icon" width="20" height="20"/>
                {{ $t(`base.${[payload.text]}`) }}
            </g-button>
        </div>
    </div>
</template>

<style scoped lang="scss">
.remove-position-confirm {
    width: 360px;
    max-width: 100%;
}

.footer {
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-top: 32px;

    & > .g-button {
        padding: 0 20px;
        gap: 10px;
        display: flex;
        align-items: center;

        &:first-child {
            border: 1px solid var(--main-text-color, $main-text-color);
            background-color: transparent;
            color: var(--main-text-color, $main-text-color);

            svg {
                fill: var(--main-text-color, $main-text-color);
            }
        }
    }

    .danger {
        border: 1px solid var(--danger, $danger);
        color: var(--title-colo, $title-color);
        background-color: var(--danger, $danger);

        svg {
            fill: var(--title-colo, $title-color);
        }
    }

    .success {
        border: 1px solid var(--success, $success);
        color: var(--title-colo, $title-color);
        background-color: var(--success, $success);

        svg {
            fill: var(--title-colo, $title-color);
        }
    }
}
</style>