import { useTeamResources } from '@team';

export default (reload = false) => {
    const team = useTeamResources('Team');
    if (reload || (!team.isLoaded && !team.isLoading)) {
        team.setIncludes(['teamlead', 'users.roles']);
        team.sort('id', 'desc');
        team.load();
    }

    return team;
};
