<script setup>



</script>

<template>
    <button class="g-link-button">
        <slot/>
    </button>
</template>

<style lang="scss" scoped>
button {
    border: none;
    padding: 0;
    font-weight: 600;
    gap: 8px;
    display: inline-flex;
    align-items: center;
    background-color: transparent;
    color: var(--secondary, $secondary);
    fill: var(--secondary, $secondary);

    &:hover {
        color: var(--secondary-hover, $secondary-hover);
        fill: var(--secondary-hover, $secondary-hover);
    }

    &:disabled {
        pointer-events: none;
        font-weight: 200;
        color: var(--disabled-color, $disabled-color);
        fill: var(--disabled-color, $disabled-color);
    }
}
</style>