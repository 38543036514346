import { STACK, ApiClient, STATE } from '@/Ship';
import router from '@/Ship/router';

export default function (id) {
    return STACK.push(() => {
        return ApiClient.get(`impersonate/${id}`).then(res => {
            sessionStorage.setItem('real_token', sessionStorage.getItem('access_token'));
            sessionStorage.setItem('real_expires_in', sessionStorage.getItem('expires_in'));
            sessionStorage.access_token = res.data.token;
            sessionStorage.expires_in = Date.now() + +new Date(res.data.expires_at) * 1000;
            sessionStorage.setItem('impersonate', id);

            location.reload();
            STATE.IS_LOGIN = true;
        }).then(
            router.push({ name: 'Welcome', params: router.currentRoute.value.params })
        );
    });
};