<script setup>

import { computed } from 'vue';
import { useProfileActions } from '@profile';

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const isShowSidebar = computed({
    get: () => profile.state?.env?.isShowSidebar || false,
    set: value => {
        if (!profile.state.env) {
            profile.state.env = {};
        }

        profile.state.env.isShowSidebar = value;
        profile.saveState();
    }
});

</script>

<template>
    <aside class="leftbar" v-bind:class="{ show: isShowSidebar }">

        <div class="mainbar custom-scrollbar" v-if="$slots.default">
            <nav class="container">
                <slot/>
            </nav>
        </div>

        <nav class="linkbar">
            <div class="bar">
                <g-hamburger v-model:modelValue="isShowSidebar"/>
            </div>

            <div class="bar">
                <router-link v-if="profile.can('Lead View')"
                             class="link"
                             v-bind:class="{ active: ($route.name === 'Leads' || $route.name === 'Lead') }"
                             v-bind:to="{ name: 'Leads', params: $route.params }">
                    <g-symbol name="customers" width="22" height="22"/>
                </router-link>

                <router-link v-if="profile.can('Campaign View')"
                             class="link"
                             v-bind:class="{ active: ($route.name === 'Campaigns' || $route.name === 'Campaign') }"
                             v-bind:to="{ name: 'Campaigns', params: $route.params }">
                    <g-symbol name="offers" width="22" height="22"/>
                </router-link>

                <router-link v-if="profile.can('Finances View')"
                             class="link"
                             v-bind:class="{ active: $route.name === 'Finances' }"
                             v-bind:to="{ name: 'Finances', params: $route.params }">
                    <g-symbol name="dollar" width="25" height="25"/>
                </router-link>

                <router-link v-if="profile.can('User View')"
                             class="link"
                             v-bind:class="{ active: ($route.name === 'Users' || $route.name === 'User') }"
                             v-bind:to="{ name: 'Users', params: $route.params }">
                    <g-symbol name="users" width="20" height="20"/>
                </router-link>
            </div>
            <div class="bar">
                <router-link v-if="profile.can('Settings View')"
                             class="link"
                             v-bind:class="{ active: $route.name === 'Settings' }"
                             v-bind:to="{ name: 'Settings', params: $route.params }">
                    <g-symbol name="settings" width="26" height="26"/>
                </router-link>
            </div>
        </nav>
    </aside>
</template>

<style lang="scss" scoped>
.leftbar {
    z-index: 5;
    overflow: visible;
    box-shadow: 10px 0 18px -8px #00000055;
    color: var(--sidebar-text-color, $sidebar-text-color);
    transition: width 0.3s;

    & > .linkbar {
        position: absolute;
        z-index: 3;
        top: 50%;
        transform: translateY(-50%);
        width: 60px;
        padding: 20px;
        border-radius: 12px;
        background-color: var(--secondary, $secondary);
        box-shadow: -5px 0 18px -8px #00000077;
        gap: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        transition: right 0.3s;

        .link {
            display: block;
            width: 42px;
            height: 42px;
            margin: 4px 0;
            text-align: center;
            fill: #97edff;

            & > .g-symbol {
                width: 100%;
                height: 100%;
                padding: 10px 0;
            }

            &:hover {
                fill: var(--title-text-color, $title-text-color);

                & > .g-symbol {
                    transform: scale(1.2);
                }
            }

            &.active {
                box-shadow: 0 0 10px #00b0b5;
                fill: var(--title-text-color, $title-text-color);
                background-color: #1d212f;
                border-radius: 50%;
            }
        }
    }

    & > .mainbar {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 100%;
        border-radius: 12px;
        background-color: var(--env-bg, $env-bg);
        border: 3px solid var(--secondary, $secondary);
        transition: padding 0.3s;

        & > .container {
            height: auto;
            padding: 20px 10px 0 10px;
            width: calc(var(--sedebar-width, $sidebar-width) - 10px);
        }
    }

    &.show {
        width: var(--sedebar-width, $sidebar-width);

        & > .linkbar {
            right: -35px;
        }
    }

    &:not(.show) {
        width: 0;

        & > .linkbar {
            right: -55px;
        }
    }
}

@media (max-width: 1439px) {
    .leftbar {
        bottom: 0;

        &:hover {
            width: var(--sedebar-width, $sidebar-width);
        }
    }
}

@media (max-width: 984px) {
    .leftbar {
        left: 0;
        bottom: 0;
        z-index: 3;
        position: fixed;
        transition: width 0.3s;
        box-shadow: 10px 0 18px -8px #00000055;
        top: var(--bar-height, $bar-height) + 100px;
        width: var(--sedebar-width, $sidebar-width);
        height: calc(100vh - var(--bar-height, $bar-height));

        &:not(.show) {
            width: 0;
        }
    }
}

</style>