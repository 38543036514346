<template>
    <div class="g-combobox">
        <label class="field" v-bind:class="{ filled: !!selected, focus: isFocus, disabled }">
            <input v-bind="$attrs"
                   v-bind:value="selected"
                   v-on:input="inputEvent"
                   v-on:focus="showList"
                   v-on:blur="hideList"
                   placeholder=" ">

            <g-symbol v-if="disabled" class="arrow" name="lock" width="20" height="20"/>
            <g-symbol v-else-if="selected && selected !== initValue"
                      class="close-btn"
                      name="close"
                      width="20"
                      height="20"
                      v-on:click="reset"/>
            <g-symbol v-else class="arrow" name="arrow-down" width="20" height="20"/>
            <slot/>
            <span ref="datalist"
                 class="datalist"
                 v-bind:class="{ 'to-top': toTop }">
                <label
                    v-for="(value, idx) in filteredOptions"
                    v-bind:key="`i-${idx}`"
                    v-bind:class="{ active: value === selected }">
                    <input type="radio" v-model="selected" v-bind:value="value" v-on:change="selectEvent" hidden/>
                    {{ value }}
                </label>
            </span>
        </label>
    </div>
</template>

<script>
import {
    ref,
    computed,
    onMounted,
} from 'vue';

export default {
    name: 'GCombobox',
    props: {
        modelValue: {
            type: String,
        },
        index: {
            type: Number,
            default: 0,
        },
        options: {
            type: Array,
            default: () => [],
        },
        label: {
            type: String,
        },
        hideLabel: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        initial: String,
    },
    emits: ['update:modelValue', 'update:index'],

    setup(props, { emit }) {

        const isFocus = ref(false);
        const toTop = ref(false);
        const datalist = ref(null);
        const selectedIndex = ref(null);
        const selected = ref(props.modelValue || '');
        const initValue = props.modelValue || '';

        onMounted(() => {
            selectedIndex.value = props.index;
        });

        const showList = () => {
            isFocus.value = true;
            const { y } = datalist.value.getBoundingClientRect();
            toTop.value = (y + 300) > screen.availHeight;
        };

        const hideList = () => {
            setTimeout(() => {
                isFocus.value = false;
            }, 200);
        };

        const filteredOptions = computed(() => {
            const inputValue = selected.value.toLowerCase();
            return props.options.filter(option => option.toLowerCase().includes(inputValue));
        });

        const inputEvent = event => {
            selected.value = event.target.value;
            emit('update:modelValue', selected.value);
        };

        const selectEvent = () => {
            emit('update:modelValue', selected.value);
        };

        const reset = () => {
            selected.value = initValue;
            emit('update:modelValue', initValue);
        };

        return {
            isFocus,
            toTop,
            datalist,
            selectedIndex,
            selected,
            initValue,
            showList,
            hideList,
            filteredOptions,
            inputEvent,
            selectEvent,
            reset,
        };
    },
};
</script>

<style lang="scss" scoped>
.field {
    position: relative;
    padding: 0 10px 0 0;
    display: flex;
    white-space: nowrap;
    align-items: center;
    box-sizing: border-box;
    height: var(--input-height, $input-height);
    border: 1px solid var(--input-border, $input-border);
    border-radius: var(--input-rounding, $input-rounding);

    input {
        flex-grow: 1;
        width: 100%;
        height: 100%;
        border: none;
        padding-left: 10px;
        box-sizing: border-box;
        background-color: transparent;
        color: inherit;
        font-size: var(--input-font-size, $input-font-size);
        font-family: var(--font-primary, $font-primary);

        &::placeholder {
            color: transparent;
        }

        &:focus {
            outline: none;
        }

        &:focus ~ .arrow {
            transform: rotate(180deg);
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            background: transparent;
            transition: background-color 5000s ease-in-out 0s;
            -webkit-text-fill-color: var(--main-text-color, $main-text-color);
        }
    }

    & > .arrow,
    & > .close-btn {
        flex-shrink: 0;
        transition: transform 0.2s;
        fill: var(--secondary, $secondary);
    }

    & > .close-btn {
        cursor: pointer;
    }

    & > .datalist {
        z-index: 3;
        left: -1px;
        max-height: 300px;
        min-width: calc(100% + 2px);
        position: absolute;
        overflow: auto;
        box-sizing: border-box;
        background-color: var(--secondary, $secondary);
        border-radius: var(--dropdown-rounding, $dropdown-rounding);
        border: 1px solid var(--dropdown-list-border, $dropdown-list-border);
        box-shadow: 0 0 12px -5px #000000bb;
        scrollbar-color: var(--scrollbar-thumb-color, $scrollbar-thumb-color) transparent;
        scrollbar-width: thin;
        transition:
            opacity 0.5s 0.1s,
            max-height 0.3s;

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-thumb {
            -webkit-border-radius: 1ex;
            background-color: var(--scrollbar-thumb-color, $scrollbar-thumb-color);
        }

        &.to-top {
            bottom: 100%;
            margin-bottom: 8px;
        }

        &:not(.to-top) {
            top: 100%;
            margin-top: 2px;
        }

        label {
            display: block;
            cursor: pointer;
            padding: 5px 15px;
            font-weight: 400;
            font-size: 14px;
            text-align: left;
            white-space: nowrap;
            color: var(--main-text-color, $main-text-color);

            &:first-child {
                padding-top: 10px;
            }

            &:last-child {
                padding-bottom: 10px;
            }

            &.active {
                pointer-events: none;
                color: var(--selected-color, $selected-color);
            }

            &:not(.active):hover {
                color: var(--title-text-color, $title-text-color);
            }
        }
    }

    &:not(.focus) {
        & > .datalist {
            opacity: 0;
            max-height: 0;
            border-width: 0;
            overflow: hidden;
            transition:
                opacity 0.2s 0.1s,
                max-height 0.3s,
                border-width 0s 0.3s;
        }
    }

    &.disabled {
        opacity: 0.7;
        pointer-events: none;

        legend {
            color: var(--disabled-color, $disabled-color);
        }

        input {
            color: var(--disabled-color, $disabled-color);
        }
    }
}
</style>
