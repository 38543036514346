<script setup>

import { reactive, computed } from 'vue';
import { useUserActions } from '@user';
import { useProfileActions } from '@profile';

const getUserResource = useUserActions('getUserResource');
const getProfileResource = useProfileActions('getProfileResource');

const profile = getProfileResource();
if (!profile.state.user) {
    profile.state.user = {
        filters: {},
        sortBy: 'users.id',
        sortDir: 'desc',
        expanded: [],
    };
} else if (!profile.state.user.expanded) {
    profile.state.user.expanded = [];
}

const users = getUserResource();
const filters = reactive({});

Object.assign(filters, profile.state.user.filters);

users.setFilters(filters);

const isChanged = computed(() => {
    return !_.isEqual(filters, users.filters);
});

function filtrate() {
    Object.keys(filters).forEach(key => {
        if (!Object.keys(filters[key]).length) {
            delete filters[key];
        }
    });
    users.setFilters(filters);
    users.setPage(1);
    users.load();

    profile.state.user.filters = filters;
    profile.saveState();
}

function getFilterValueByKey(filter, key) {
    if (users.filterset[filter]) {
        const filterset = users.filterset[filter].find(item => item.id === Number(key) || item.value === key);
        return filterset ? filterset.key || filterset.value : key;
    }
    return key;
}

function dropFilter(filter, value) {
    delete filters[filter][value];
    if (!Object.keys(filters[filter]).length) {
        delete filters[filter];
    }
    users.dropFilterValue(filter, value);
    users.setPage(1);
    users.load();

    profile.state.user.filters = users.filters;
    profile.saveState();
}

function resetAllFilters() {
    Object.keys(users.filters).forEach(filter => {
        delete filters[filter];
        Object.keys(users.filters[filter]).forEach(key => {
            users.dropFilterValue(filter, key);
        })
    });

    users.setFilters(users.filters);
    users.setPage(1);
    users.load(null, {}, 'Reset Filters');

    profile.state.user.filters = {};
    profile.saveState();
}

function isExpanded(section) {
    return computed({
        get: () => (profile.state.user.expanded || []).includes(section),
        set: value => {
            value
                ? profile.state.user.expanded.push(section)
                : profile.state.user.expanded.splice(profile.state.user.expanded.indexOf(section), 1);
            profile.saveState();
            return true
        }
    })
}

const rolesExt = isExpanded('roles');
const teamsExt = isExpanded('teams');
const statusExt = isExpanded('status');
const brandsExt = isExpanded('brands');
</script>

<template>

    <g-flex class="filters" gap="4" wrap="wrap" align="end">
        <fieldset class="filter" v-for="(values, key) in users.filters" v-bind:key="key" v-bind:class="key">
            <legend>{{ $t(`filters.${key}`, key) }}:</legend>

            <div class="filter-item" v-for="(value, i) in Object.keys(values)" v-bind:key="`${key}-${i}`">
                <div class="value">{{ getFilterValueByKey(key, value) }}</div>
                <g-symbol name="close" width="14" height="14" v-on:click="dropFilter(key, value)"/>
            </div>
        </fieldset>


        <fieldset v-if="Object.keys(users.filters).length > 0" class="filter reset-all">
            <div class="filter-item"
                 v-on:click="resetAllFilters">
                <div class="value">Reset All</div>
                <g-symbol name="close" width="14" height="14"/>
            </div>
        </fieldset>
    </g-flex>

    <div>
        <g-filterset v-model="filters.brands"
                     v-model:expanded="brandsExt"
                     v-bind:label="$t('parts.brands', 'Brands')"
                     value-field="id"
                     v-bind:filterset="users.filterset.brands"/>

        <g-filterset v-model="filters.roles"
                    v-model:expanded="rolesExt"
                    v-bind:label="$t('parts.roles', 'Roles')"
                    value-field="key"
                    v-bind:filterset="users.filterset.roles"/>

        <g-filterset v-model="filters.teams"
                    v-model:expanded="teamsExt"
                    v-bind:label="$t('parts.teams', 'Teams')"
                    value-field="id"
                    v-bind:filterset="users.filterset.teams"/>

        <g-filterset v-model="filters.status"
                    v-model:expanded="statusExt"
                    v-bind:label="$t('settings.statuses', 'Statuses')"
                    value-field="key"
                    v-bind:filterset="users.filterset.status"/>

        <transition>
            <g-flex v-if="isChanged" class="btn-bar" gap="5">
                <g-button class="reset-btn" v-on:click="resetFilters">Reset</g-button>
                <g-button class="apply-btn" v-on:click="filtrate">Apply</g-button>
            </g-flex>
        </transition>
    </div>
</template>

<style lang="scss" scoped>
.filters {
    padding: 10px 5px;
}

.filter {
    gap: 4px;
    flex-flow: wrap;
    padding: 4px 0;
    font-size: 0.8rem;
    border-radius: 5px;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    color: var(--sidebar-text-color, $sidebar-text-color);

    & > .filter-item {
        border-radius: 5px;
        padding: 0 4px 1px 8px;
        display: inline-flex;
        align-items: center;
        gap: 2px;
        height: 28px;
        color: white;
        box-shadow: 0 5px 15px -10px #000000aa;

        &:first-child {
            margin-left: 5px;
        }

        & > .g-symbol {
            cursor: pointer;
            fill: var(--btn-primary-color, $btn-primary-color);
        }

        & > .value {
            font-weight: 500;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 56px;
        }
    }

    &.reset-all > .filter-item {
        background-color: black;
    }

    &.roles > .filter-item {
        background-color: #eaa308;
    }

    &.teams > .filter-item {
        background-color: var(--primary-hover, $primary-hover);
    }

    &.status > .filter-item {
        background-color: var(--secondary-hover, $secondary-hover);
    }
}

.g-filterset {
    margin: 20px 0;
}

.btn-bar {
    margin: 0 -30px 0 -25px;
    text-align: right;
    padding: 8px 15px;
    bottom: 0;
    position: sticky;
    border-radius: 9px 9px 0 0;
    background-color: var(--env-bg, $env-bg);
    box-shadow: 0 -5px 15px -5px #00000033;

    & > .apply-btn,
    & > .reset-btn {
        width: 50%;
        flex-grow: 1;
        background-color: transparent;
        border: 1px solid var(--separator-color, $separator-color);
        color: var(--env-text-color, $env-text-color);

        &:hover {
            color: white;
            background-color: var(--primary-hover, $primary-hover);
        }
    }

    & > .reset-btn {
        background-color: transparent;
        border: 1px solid var(--separator-color, $separator-color);
        color: var(--env-text-color, $env-text-color);

        &:hover {
            color: white;
            background-color: var(--primary-hover, $primary-hover);
        }
    }

    & > .apply-btn {
        color: white;
        background-color: var(--primary, $primary);
        border: 1px solid var(--primary, $primary);

        &:hover {
            background-color: var(--primary-hover, $primary-hover);
        }
    }
}

.v-enter-active,
.v-leave-active {
    transition: bottom 0.4s;
}

.v-enter-from,
.v-leave-to {
    bottom: -100px;
}
</style>