<script setup>

import { computed, onMounted, reactive, ref, watch } from 'vue';
import { i18n } from '@/Ship';
import { useArchiveActions, useArchiveComponents } from '@archive';
import { useProfileActions } from '@profile';

const MassRestore = useArchiveComponents('MassRestore');

const getArchiveResource = useArchiveActions('getArchiveResource');
const archive = getArchiveResource();

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const restoring = useArchiveActions('restoring');

const targets = ref([]);
let lastSelectedIndex = null;

const perPage = ref(profile.state.archive.perPage || 25);

function changePerPage(value) {
    archive.meta.per_page = value;
    archive.setPerPage(value);
    archive.setPage(1);
    archive.load();

    profile.state.archive.perPage = value;
    profile.saveState();
}

function paginate(page) {
    archive.setPage(page);
    archive.load();
}

function reloadArchive() {
    archive.load();
    targets.value = [];
}

function sortBy(field) {
    if (archive.sortBy === field) {
        profile.state.archive.sortDir = archive.sortDir === 'asc'
            ? 'desc'
            : 'asc';
    }
    profile.state.archive.sortBy = field;
    profile.saveState();

    archive.sort(
        profile.state.archive.sortBy,
        profile.state.archive.sortDir
    );
    archive.load();
}

const columns = computed(() => {
    return archive.columns.filter(column => !column.allow);
});

watch(() => archive.state, () => {
    const objectIds = archive.state.map((obj) => obj.id);
    targets.value = targets.value.filter((target) => objectIds.includes(parseInt(target)));
});

onMounted(() => {
    archive.sort(
        profile.state.archive.sortBy,
        profile.state.archive.sortDir
    );
    archive.setPerPage(profile.state.archive.perPage);
    archive.setIncludes(['affiliate', 'sale', 'retention', 'country', 'campaigns']);

    archive.load();
});

function checkAll() {
    if (targets.value.length < archive.state.length) {
        archive.state.forEach(archive => {
            targets.value.push(archive.id.toString());
        });
    } else {
        targets.value = [];
    }
}

const handleCheckboxClick = (event, archive, index) => {
    if (event.shiftKey && lastSelectedIndex !== null) {
        const startIndex = Math.min(lastSelectedIndex, index);
        const endIndex = Math.max(lastSelectedIndex, index);

        const newTargets = archive.state
            .slice(startIndex, endIndex + 1)
            .map(archive => archive.id.toString());

        targets.value = [...new Set([...targets.value, ...newTargets])];
    } else {
        const archiveId = archive.id.toString();
        if (!targets.value.includes(archiveId)) {
            targets.value.push(archiveId);
        } else {
            const targetIndex = targets.value.indexOf(archiveId);
            targets.value.splice(targetIndex, 1);
        }

        lastSelectedIndex = index;
    }
};

const restoreLead = (id) => {
    const index = archive.state.findIndex(item => item.id === id);
    restoring.restore(id).then(() => {
        archive.state.splice(index, 1);
    });
};

const tab = ref('archive');
const tabList = reactive({
    leads: i18n.global.t(`parts.leads`),
    archive: i18n.global.t(`parts.archive`),
});
</script>

<template>
    <div class="archive environment">

        <g-preloader-overlay v-if="archive.isLoading"/>

        <g-tabs v-if="profile.can('Lead ArchiveView')"
                v-model="tab"
                v-bind:tab-list="tabList"
                v-on:click="$router.push({ name: 'Leads', params: { locale: $i18n.locale } })"/>

        <mass-restore v-if="targets.length"
                      v-bind:targets="targets"
                      v-on:updated="reloadArchive"
                      v-on:drop="targets = []"/>

        <div class="index">
            <div class="row header">
                <div class="title cell check-all checkbox">
                    <g-symbol name="check" width="18" height="18" v-on:click="checkAll"/>
                </div>

                <div class="title cell id sortable" v-bind:class="{ [`sorted ${archive.sortDir}`]: archive.sortBy === 'id' }">
                    <g-flex align="center" justify="center" gap="2" v-on:click="sortBy('id')">
                        ID
                        <g-symbol name="arrow-down" width="14" height="14"/>
                    </g-flex>
                </div>

                <div class="title cell phone">
                    <div class="phone">{{ $t('lead.phone', 'Phone') }}</div>
                </div>
                <div v-for="item in columns"
                     v-bind:key="item.key"
                     class="title cell"
                     v-bind:class="[item.key, { sortable: item.sortable, [ `sorted ${archive.sortDir}`]: archive.sortBy === item.key }]">
                    <g-flex v-if="item.sortable"
                            align="center"
                            justify="center"
                            gap="2"
                            v-bind:class="item.key"
                            v-on:click="sortBy(item.key)">
                        {{ $t(`lead.${item.name}`) }}
                        <g-symbol name="arrow-down" width="14" height="14"/>
                    </g-flex>
                    <div v-else v-bind:class="item.key">{{ $t(`lead.${item.name}`) }}</div>
                </div>
                <div class="title cell"></div>
            </div>

            <div class="row"
                 v-for="(archive, index) in archive.state"
                 v-bind:key="`archive-${archive.id}`"
                 v-bind:class="[{checked: targets.includes(archive.id.toString())}, {'open': $route.params.id === archive.id.toString()}]">
                <label class="checkbox cell">
                    <g-checkbox v-model="targets"
                                v-bind:value="archive.id.toString()"
                                v-on:click.stop="handleCheckboxClick($event, archive, index)"
                                class="white"/>
                </label>
                <div class="cell id" v-bind:class="{ [`sorted ${archive.sortDir}`]: archive.sortBy === 'id' }">
                    {{ archive.id }}
                </div>

                <div class="cell phone">
                    <g-copy v-if="profile.can('Lead ContactsView')" class="phone" v-bind:text="archive.phone"/>
                    <template v-else>
                        {{ archive.phone.replace(/^\+?(\d{3})(\d+)(\d{3})$/, (match, p1, p2, p3) => `+${p1}${p2.replace(/./g, '*')}${p3}`) }}
                    </template>
                </div>

                <div v-for="column in columns"
                     class="cell"
                     v-bind:class="[column.key, { [`sorted ${archive.sortDir}`]: archive.sortBy === column.key }]"
                     v-bind:key="column.key">

                    <fieldset v-if="column.key === 'commentDate' && !!archive[column.name]">
                        <legend>{{ archive[column.name].created_at }}</legend>
                        <div>{{ archive[column.name].body }}</div>
                    </fieldset>
                    <template v-else-if="column.key === 'campaigns' && !!archive[column.name]">
                        {{
                            profile.can('Archive RealCampaignView')
                                ? [...new Set(archive[column.name].map(item => item.name))].join(', ')
                                : [...new Set(archive[column.name].map(item => item.alias))].join(', ')
                        }}
                    </template>
                    <template v-else>
                        {{
                            Array.isArray(archive[column.name])
                                ? [...new Set(archive[column.name].map(item => item.name))].join(', ')
                                : archive[column.name]
                        }}
                    </template>
                </div>
                <div class="cell">
                    <button class="restore-btn with-tooltip"
                            v-bind:data-tooltip="$t('lead.restore_lead', 'Restore Lead')"
                            v-on:click="restoreLead(archive.id)">
                        <g-symbol name="restore" width="24" height="24"/>
                    </button>
                </div>
            </div>
            <div class="info-block" v-if="!archive.state.length && !archive.isLoading">{{
                    $t('base.nothing_found')
                }}
            </div>
        </div>

        <g-flex v-if="archive.meta.total > 25" class="pagination" align="stretch" justify="end" gap="2">
            <g-pagination v-model="archive.meta.current_page"
                          v-on:change="paginate"
                          v-bind:per-page="archive.meta.per_page"
                          v-bind:total="archive.meta.total"/>

            <div class="per-page">
                <g-select v-model="perPage"
                          v-bind::label="$t('base.per_page')"
                          v-bind:options="[{value: 25}, {value: 50}, {value: 75}, {value: 100}]"
                          v-on:change="changePerPage"
                          option-value="value"
                          option-text="value"/>
            </div>
        </g-flex>
    </div>
</template>

<style lang="scss" scoped>
.environment {
    margin: 0 6px;
    padding: 8px 20px;
    position: relative;
    border-radius: 12px;
    font-size: 0.9rem;
    border: 3px solid var(--secondary, $secondary);
    background-color: var(--env-bg, $env-bg);
}

.index {
    min-width: 100%;
    padding: 0 10px;
    margin-bottom: 20px;
    color: var(--env-text-color, $env-text-color);
}

.row {
    &:nth-child(even) {
        background-color: #00000011;
    }

    &:not(.header) {
        cursor: pointer;

        &.checked {
            color: black;

            & > .cell {
                background-color: var(--success-hover, $success-hover);
            }

            & > .cell.sortable {
                background-color: var(--success, $success);
            }
        }

        & > .cell {
            border: 0 solid transparent;
            border-width: 1px 0;
        }

        &:hover > .cell,
        &.open > .cell {
            border-color: var(--success, $success);
        }
    }
}

.cell {
    padding: 5px;
    white-space: nowrap;
    position: relative;

    &.title {
        &.sortable {
            .g-symbol {
                cursor: pointer;
                fill: var(--env-text-color, $env-text-color);
            }

            &.sorted {
                font-weight: bold;
                fill: var(--sorted-text-color, $sorted-text-color);

                &.desc {
                    .g-symbol {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    &.sorted {
        color: var(--sorted-text-color, $sorted-text-color);
        background-color: var(--sorted-bg, $sorted-bg);
    }

    &.checkbox {
        font-size: 0;
        min-width: 46px;
        fill: var(--env-text-color, $env-text-color);

        & > .g-checkbox {
            display: inline-block;
        }

        :deep(.g-symbol) {
            z-index: 2;
            opacity: 0.99 !important;
        }

        &:hover {
            fill: var(--secondary-hover, $secondary-hover);
        }
    }

    &.check-all,
    &.checkbox,
    &.id,
    &.phone,
    &.language,
    &.account_level,
    &.created_at,
    &.deleted_at {
        padding: 10px 8px;
        text-align: center;
    }
}

.row:nth-child(-n+6) .cell:not(.title).commentDate:hover > fieldset {
    top: 0;
    bottom: auto;
}

.check-all {
    cursor: pointer;
    fill: var(--main-text-color, $main-text-color);

    &:hover {
        fill: var(--title-color, $title-color);
    }
}

.info-block {
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 18px;
    color: var(--main-text-color, #aabfcf);
}

.g-tabs {
    position: sticky;
    left: 0;
    width: 100%;
    padding: 10px;
    justify-content: flex-end !important;
}

.pagination {
    position: sticky;
    width: 100%;
    z-index: 3;
    left: 0;
    bottom: 5px;

    & > .g-pagination {
        width: min-content;
        padding: 10px 12px;
        border-radius: 10px 0 0 10px;
        box-shadow: 0 5px 20px -5px #000000aa;
        background-color: var(--body-bg, $body-bg);
        border: 1px solid var(--separator-color, $separator-color);
    }

    & > .per-page {
        padding: 6px 0 0 10px;
        border-radius: 0 10px 10px 0;
        background-color: var(--body-bg, $body-bg);
        border: 1px solid var(--separator-color, $separator-color);

        & > .g-select:deep(.field) {
            width: 80px;
            border-color: transparent;
        }
    }
}

@media (min-width: 1025px) {
    .index {
        display: table;

        .row {
            display: table-row;

            & > .cell {
                display: table-cell;
                vertical-align: middle;
            }
        }
    }
}

@media (max-width: 1024px) {
    .index {
        display: table;

        .row {
            display: table-row;

            & > .cell {
                padding: 8px 5px;
                display: table-cell;
                vertical-align: middle;
            }
        }
    }
}

@media (max-width: 984px) {
    .pagination-float-left {
        transform: translate(295px, 0);
    }
}

button,
.restore-btn {
    display: flex;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    fill: var(--env-text-color, $env-text-color);
}

button:hover::after,
.restore-btn:hover::after {
    left: 50%;
    transform: translateX(-50%);
    top: calc(100% + var(--tooltip-arrow-size, $tooltip-arrow-size));
}

button:hover::before,
.restore-btn:hover::before {
    transform: translateX(-50%);
    left: 50%;
    top: calc(100% - var(--tooltip-arrow-size, $tooltip-arrow-size));
}

.restore-btn:hover {
    fill: var(--success-hover, $success-hover);
}

.restore-btn:hover::after {
    background-color: var(--success, $success);
}

.restore-btn:hover::before {
    border-bottom-color: var(--success, $success);
}


</style>