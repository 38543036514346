import { useFinanceResources } from '@finance';

export default (filters = {}, reload = false, referer = null) => {
    const deposit = useFinanceResources('Deposit');

    if (reload || (!deposit.isLoaded && !deposit.isLoading)) {

        deposit.setIncludes(['user']);
        deposit.setFilters(filters)
        deposit.sort('id', 'desc');

        deposit.load(null, {}, referer);
    }

    return deposit;
};

