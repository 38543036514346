<script setup>

import { computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { PopUpManager as PopUp } from '@/Ship';
import { useProfileActions } from '@profile';
import { useLeadActions, useLeadComponents, useLeadResources } from '@lead';


const route = useRoute();
const router = useRouter();

const filters = useLeadResources('FilterState');

const DateFilter = useLeadComponents('DateFilter');
const {
    getFilterset,
    getLeadResource
} = useLeadActions();
const leads = getLeadResource();

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

if (!profile.state.lead) {
    profile.state.lead = {
        columns: [],
        filters: {},
        sortBy: 'id',
        sortDir: 'desc',
        perPage: 25,
        expanded: [],
    };
} else if (!profile.state.lead.expanded) {
    profile.state.lead.expanded = [];
}

Object.assign(filters.state, profile.state.lead.filters);

leads.setFilters(filters.state);

const isChanged = computed(() => {
    return !_.isEqual(filters.state, leads.filters);
});

function filtrate() {
    Object.keys(filters.state).forEach(key => {
        if (!Object.keys(filters.state[key]).length) {
            delete filters.state[key];
        }
    });
    leads.setFilters(filters.state);
    leads.setPage(1);
    leads.load();

    profile.state.lead.filters = filters.state;
    profile.saveState();
}

function resetFilters() {
    Object.keys(filters.state).forEach(key => {
        delete filters.state[key];
    });
    Object.assign(filters.state, leads.filters);
}

function getFilterValueByKey(filter, key) {
    if (leads.filterset[filter]) {

        key = String(key).split(',').pop();
        const filterset = leads.filterset[filter].find(item => item.id === Number(key) || item.value == key);

        let value;
        switch (filter) {
            case 'campaigns':
                value = profile.can('Lead RealCampaignView')
                    ? filterset?.name || key
                    : filterset?.alias || key;
                break;
            default:
                value = filterset
                    ? filterset.key || filterset.value || filterset.name
                    : key || 'Null';
                break;
        }

        return value;
    }
    return key;
}

function dropFilter(filter, value) {
    delete filters.state[filter][value];
    if (!Object.keys(filters.state[filter]).length) {
        delete filters.state[filter];
    }
    leads.dropFilterValue(filter, value);
    leads.setPage(1);
    leads.load(null, {}, 'Drop Filter');

    profile.state.lead.filters = leads.filters;
    profile.saveState();
}

function resetAllFilters() {
    Object.keys(leads.filters).forEach(filter => {
        delete filters.state[filter];
        Object.keys(leads.filters[filter]).forEach(key => {
            leads.dropFilterValue(filter, key);
        })
    });

    Object.assign(profile.state.lead.filters, leads.filters);

    leads.setFilters(leads.filters);
    leads.setPage(1);
    leads.load(null, {}, 'Reset Filters');

    profile.saveState();
}

function find(value) {
    if (route.name === 'Lead') {
        router.push({ name: 'Leads', params: route.params });
    }

    const key = Object.keys(value).pop();

    if ((key === 'id' && value[key].length) || value[key].length > 2) {
        leads.find(value);
    }
}

function isExpanded(section) {
    return computed({
        get: () => (profile.state.lead.expanded || []).includes(section),
        set: value => {
            value
                ? profile.state.lead.expanded.push(section)
                : profile.state.lead.expanded.splice(profile.state.lead.expanded.indexOf(section), 1);

            getFilterset([section]).then(() => {
                profile.saveState();
            });

            return true
        }
    })
}

const brandsExt = isExpanded('brands');
const isTestExt = isExpanded('is_test');
const isFtdExt = isExpanded('is_ftd');
const phoneConfirmedExt = isExpanded('phone_confirmed');
const emailConfirmedExt = isExpanded('email_confirmed');
const conversionStatusExt = isExpanded('conversion_status');
const retentionStatusExt = isExpanded('retention_status');
const kycStatusExt = isExpanded('kyc_status');
const affiliatesExt = isExpanded('affiliates');
const teamsExt = isExpanded('teams');
const salesExt = isExpanded('sales');
const retentionsExt = isExpanded('retentions');
const languageExt = isExpanded('language');
const timezoneExt = isExpanded('timezone');
const accountLevelExt = isExpanded('account_level');
const countriesExt = isExpanded('countries');
const campaignsExt = isExpanded('campaigns');
const genderExt = isExpanded('gender');
const maritalStatusExt = isExpanded('marital_status');
</script>

<template>
    <div class="tools">
        <g-search-input v-bind:label="$t('base.search')" v-on:find="find" v-on:drop="leads.load(null, {}, 'LeadEnv Search')"/>

        <g-flex class="toolbar" gap="15" align="center" justify="between">

            <date-filter/>

            <button v-if="profile.can('Lead Make')" class="tool with-tooltip" v-bind:data-tooltip="$t('lead.create_lead', 'Create lead')">
                <g-symbol name="plus"
                          width="20"
                          height="20"
                          v-on:click="PopUp.open(require('@lead/components/PopUps/MakeLead')).setCaption($t('lead.make_lead'), 'Make Lead')"/>
            </button>

            <button v-if="profile.can('Lead Make')" class="tool with-tooltip" v-bind:data-tooltip="$t('lead.leads_import', 'Leads Import')">
                <g-symbol name="import"
                          width="20"
                          height="20"
                          v-on:click="PopUp.open(require('@lead/components/PopUps/Import.vue')).setCaption($t('lead.leads_import'), 'Leads Import')"/>
            </button>

            <button v-if="profile.can('Lead CheckDuplicates')" class="tool with-tooltip" v-bind:data-tooltip="$t('lead.check_base', 'Check Base')">
                <g-symbol name="check"
                          width="20"
                          height="20"
                          v-on:click="PopUp.open(require('@lead/components/PopUps/CheckDuplicates.vue')).setCaption($t('lead.leads_check'), 'Check Leads')"/>
            </button>

            <button class="tool with-tooltip" :data-tooltip="$t('lead.columns_view', 'Columns View')">
                <g-symbol name="settings"
                          width="24"
                          height="24"
                          v-on:click="PopUp.open(require('@lead/components/PopUps/ColumnViews')).setCaption($t('lead.columns_view'), 'Columns View')"/>
            </button>
        </g-flex>
    </div>

    <g-flex class="filters" align="end" gap="4" wrap="wrap">
        <fieldset v-for="(values, key) in leads.filters"
                 class="filter"
                 v-bind:class="key"
                 v-bind:key="key">
            <legend>{{ $t(`filters.${key}`, key) }}:</legend>

            <div class="filter-item" v-for="(value, i) in Object.keys(values)" v-bind:key="`${key}-${i}`">
                <div class="value">{{ getFilterValueByKey(key, value) }}</div>
                <g-symbol name="close" width="14" height="14" v-on:click="dropFilter(key, value)"/>
            </div>
        </fieldset>


        <fieldset v-if="Object.keys(leads.filters).length > 0" class="filter reset-all">
            <div class="filter-item"
                 v-on:click="resetAllFilters">
                <div class="value">Reset All</div>
                <g-symbol name="close" width="14" height="14"/>
            </div>
        </fieldset>
    </g-flex>

    <div>
        <!-- BRANDS -->
        <g-filterset v-if="Object.keys(leads.filterset?.brands || {}).length > 1"
                    v-model="filters.brands"
                    v-model:expanded="brandsExt"
                    v-bind:label="$t('filters.brands', 'Brands')"
                    value-field="id"
                    v-bind:filterset="leads.filterset.brands"/>

        <!-- FTD -->
        <g-filterset v-model="filters.is_ftd"
                    v-model:expanded="isFtdExt"
                    v-bind:label="$t('filters.is_ftd', 'Is FTD')"
                    value-field="value"
                    v-bind:filterset="leads.filterset.is_ftd"
                    is-select-all/>

        <!-- CONVERSION STATUS -->
        <g-filterset v-model="filters.conversion_status"
                    v-model:expanded="conversionStatusExt"
                    v-bind:label="$t('filters.conversion_status', 'Conversion status')"
                    value-field="key"
                    v-bind:filterset="leads.filterset.conversion_status"
                    is-select-all
                    is-search/>

        <!-- RETENTION STATUS -->
        <g-filterset v-model="filters.retention_status"
                    v-model:expanded="retentionStatusExt"
                    v-bind:label="$t('filters.retention_status', 'Retention status')"
                    value-field="key"
                    v-bind:filterset="leads.filterset.retention_status"
                    is-select-all
                    is-search/>

        <!-- TEAM -->
        <g-filterset v-model="filters.teams"
                    v-model:expanded="teamsExt"
                    v-bind:label="$t('filters.teams', 'Teams')"
                    value-field="id"
                    v-bind:filterset="leads.filterset.teams"
                    is-select-all/>

        <!-- AFFILIATE -->
        <g-filterset v-model="filters.affiliates"
                    v-model:expanded="affiliatesExt"
                    v-bind:label="$t('filters.affiliates', 'Affiliates')"
                    value-field="id"
                    v-bind:filterset="leads.filterset.affiliates"
                    is-select-all
                    is-search/>

        <!-- SALE -->
        <g-filterset v-model="filters.sales"
                    v-model:expanded="salesExt"
                    v-bind:label="$t('filters.sales', 'Sale agents')"
                    value-field="id"
                    v-bind:filterset="leads.filterset.sales"
                    is-select-all
                    is-search/>

        <!-- RETENTION -->
        <g-filterset v-model="filters.retentions"
                    v-model:expanded="retentionsExt"
                    v-bind:label="$t('filters.retentions', 'Retention agents')"
                    value-field="id"
                    v-bind:filterset="leads.filterset.retentions"
                    is-select-all
                    is-search/>

        <!-- LANGUAGE -->
        <g-filterset v-model="filters.language"
                    v-model:expanded="languageExt"
                    v-bind:label="$t('filters.languages', 'Languages')"
                    value-field="key"
                    v-bind:filterset="leads.filterset.language"
                    is-select-all/>

        <!-- TIMEZONE -->
        <g-filterset v-model="filters.timezone"
                    v-model:expanded="timezoneExt"
                    v-bind:label="$t('filters.timezone', 'Timezone')"
                    value-field="key"
                    v-bind:filterset="leads.filterset.timezone"
                    is-select-all
                    is-search/>

        <!-- ACCOUNT LEVEL -->
        <g-filterset v-model="filters.account_level"
                    v-model:expanded="accountLevelExt"
                    v-bind:label="$t('filters.level', 'Account level')"
                    value-field="key"
                    v-bind:filterset="leads.filterset.account_level"
                    is-select-all/>

        <!-- COUNTRY -->
        <g-filterset v-model="filters.countries"
                    v-model:expanded="countriesExt"
                    v-bind:label="$t('filters.countries', 'Countries')"
                    value-field="code"
                    v-bind:filterset="leads.filterset.countries"
                    is-select-all
                    is-search/>

        <!-- CAMPAIGN -->
        <g-filterset v-model="filters.campaigns"
                    v-model:expanded="campaignsExt"
                    v-bind:label="$t('filters.campaigns', 'Campaigns')"
                    value-field="id"
                    v-bind:key-field="profile.can('Lead RealCampaignView') ? 'key' : 'alias'"
                    v-bind:filterset="leads.filterset.campaigns"
                    is-select-all
                    is-search/>

        <!-- GENDER -->
        <g-filterset v-model="filters.gender"
                    v-model:expanded="genderExt"
                    v-bind:label="$t('filters.gender', 'Gender')"
                    value-field="key"
                    v-bind:filterset="leads.filterset.gender"
                    is-select-all/>

        <!-- MARITAL STATUS -->
        <g-filterset v-model="filters.marital_status"
                    v-model:expanded="maritalStatusExt"
                    v-bind:label="$t('filters.marital_status', 'Marital Status')"
                    value-field="key"
                    v-bind:filterset="leads.filterset.marital_status"
                    is-select-all/>

        <!-- KYC STATUS -->
        <g-filterset v-model="filters.kyc_status"
                    v-model:expanded="kycStatusExt"
                    v-bind:label="$t('filters.kyc_status', 'KYC status')"
                    value-field="key"
                    v-bind:filterset="leads.filterset.kyc_status"
                    is-select-all/>

        <!-- PHONE CONFIRMED -->
        <g-filterset v-model="filters.phone_confirmed"
                    v-model:expanded="phoneConfirmedExt"
                    v-bind:label="$t('filters.phone_confirmed', 'Phone confirmed')"
                    value-field="value"
                    v-bind:filterset="leads.filterset.phone_confirmed"
                    is-select-all/>

        <!-- EMAIL CONFIRMED -->
        <g-filterset v-model="filters.email_confirmed"
                    v-model:expanded="emailConfirmedExt"
                    v-bind:label="$t('filters.email_confirmed', 'Email confirmed')"
                    value-field="value"
                    v-bind:filterset="leads.filterset.email_confirmed"
                    is-select-all/>

        <!-- Is TEST -->
        <g-filterset v-model="filters.is_test"
                    v-model:expanded="isTestExt"
                    v-bind:label="$t('filters.is_test', 'Is Test')"
                    value-field="value"
                    v-bind:filterset="leads.filterset.is_test"
                    is-select-all/>
        <transition>
            <g-flex v-if="isChanged" class="btn-bar" gap="5">
                <g-button class="reset-btn" v-on:click="resetFilters">Reset</g-button>
                <g-button class="apply-btn" v-on:click="filtrate">Apply</g-button>
            </g-flex>
        </transition>
    </div>
</template>

<style lang="scss" scoped>
.tools {
    z-index: 3;
    top: 0;
    position: sticky;
    margin: -20px -15px 0 -10px;
    padding: 6px 10px;
    border-radius: 9px;
    background-color: var(--secondary, $secondary);
    box-shadow: 0 5px 15px -4px #000000aa;
}

.toolbar {
    padding: 10px 0;

    .g-search-input {
        background-color: #1d212f;
    }

    .tool {
        font-size: 0;
        cursor: pointer;
        position: relative;
        background-color: transparent;
        border-radius: var(--btn-rounding, $btn-rounding);
        fill: var(--title-text-color, $title-text-color);

        &:hover {
            transform: scale(1.2);

            &::after {
                white-space: nowrap;
                transform: translateX(-50%);
                top: calc(100% + var(--tooltip-arrow-size, $tooltip-arrow-size));
                left: 50%;
            }

            &::before {
                transform: translateX(-50%);
                left: 50%;
                top: calc(100% - var(--tooltip-arrow-size, $tooltip-arrow-size));
                border-bottom-color: var(--tooltip-bg, $tooltip-bg);
            }
        }

        &:first-child:hover::after {
            transform: translateX(-24%);
        }

        &:last-child:hover::after {
            transform: translateX(-78%);
        }
    }
}

.g-search-input {
    color: var(--title-text-color, $title-text-color);
}

.filters {
    padding: 10px 5px;
}

.filter {
    gap: 4px;
    flex-flow: wrap;
    padding: 4px 0;
    font-size: 0.8rem;
    border-radius: 5px;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    color: var(--env-text-color, $env-text-color);

    & > .filter-item {
        gap: 2px;
        display: inline-flex;
        align-items: center;
        height: 28px;
        color: white;
        border-radius: 5px;
        padding: 0 4px 1px 8px;
        box-shadow: 0 5px 15px -10px #000000aa;

        &:first-child {
            margin-left: 5px;
        }

        & > .g-symbol {
            cursor: pointer;
            fill: var(--btn-primary-color, $btn-primary-color);
        }

        & > .value {
            font-weight: 500;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 56px;
        }
    }

    &.reset-all > .filter-item {
        cursor: pointer;
        background-color: black;
    }

    &.is_ftd > .filter-item {
        background-color: #eaa308;
    }

    &.created_at > .filter-item {
        background-color: var(--primary-hover, $primary-hover);

        & > .value {
            max-width: min-content;
        }
    }

    &.ftd_date > .filter-item {
        background-color: var(--secondary-hover, $secondary-hover);
    }

    &.conversion_status > .filter-item {
        background-color: #22a585;
    }

    &.retention_status > .filter-item {
        background-color: #b010df;
    }

    &.sales > .filter-item {
        background-color: #ee8833;
    }

    &.retentions > .filter-item {
        background-color: #12a565;
    }

    &.conversion_status > .filter-item,
    &.retention_status > .filter-item,
    &.sales > .filter-item,
    &.retentions > .filter-item {
        & > .value {
            max-width: 80px;
        }
    }

    &:not(.is_ftd, .created_at, .ftd_date, .conversion_status, .retention_status, .sales, .retentions, .reset-all) {
        & > .filter-item {
            background-color: var(--danger-hover, $danger-hover);
        }
    }
}

.g-filterset {
    margin: 20px 0;
}

.btn-bar {
    z-index: 3;
    margin: 0 -30px 0 -25px;
    text-align: right;
    padding: 8px 15px;
    bottom: 0;
    position: sticky;
    border-radius: 9px 9px 0 0;
    background-color: var(--env-bg, $env-bg);
    box-shadow: 0 -5px 15px -5px #00000033;

    & > .apply-btn,
    & > .reset-btn {
        width: 50%;
        flex-grow: 1;
        background-color: transparent;
        border: 1px solid var(--separator-color, $separator-color);
        color: var(--env-text-color, $env-text-color);

        &:hover {
            color: white;
            background-color: var(--primary-hover, $primary-hover);
        }
    }

    & > .reset-btn {
        background-color: transparent;
        border: 1px solid var(--separator-color, $separator-color);
        color: var(--env-text-color, $env-text-color);

        &:hover {
            color: white;
            background-color: var(--primary-hover, $primary-hover);
        }
    }

    & > .apply-btn {
        color: white;
        background-color: var(--primary, $primary);
        border: 1px solid var(--primary, $primary);

        &:hover {
            background-color: var(--primary-hover, $primary-hover);
        }
    }
}

.v-enter-active,
.v-leave-active {
    transition: bottom 0.4s;
}

.v-enter-from,
.v-leave-to {
    bottom: -100px;
}
</style>