import { useShipResources } from '@/Ship';

const { BaseResource } = useShipResources();

export default class Activity extends BaseResource {

    static #instance = null;

    endpoint = 'customer-activity';
    referer = 'Activity Resource';

    section = 'Leads';
    container = 'Activity';

    static getInstance() {
        if (!Activity.#instance) {
            Activity.#instance = new Activity();
        }
        return Activity.#instance;
    }
}
