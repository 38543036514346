<template>
    <div class="cover">
        <div class="container">
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'cover'
};
</script>

<style lang="scss" scoped>
.cover {
    height: 100vh;
    display: flex;
    justify-content: end;
    background-color: var(--body-bg, $body-bg);
}

.container {
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--main-text-color, $main-text-color);
    background-color: var(--secondary, $secondary);
    clip-path: polygon(30% 0, 100% 0, 100% 100%, 0 100%);
}
</style>