<script setup>

import { defineProps } from 'vue';
import { useProfileActions } from '@profile';

defineProps({campaigns: {type: Array}});

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

</script>

<template>
    <div v-for="campaign in campaigns" v-bind:key="`campaign-${campaign.id}`" class="card">
        <g-caption v-if="profile.can('Lead CampaignView')" class="campaign" size="5">{{ profile.can('Lead RealCampaignView') ? campaign.name : campaign.alias }}</g-caption>

        <div v-if="profile.can('Lead SourceView')" class="source">
            <span class="title">{{ $t('campaign.source') }}</span>
            <div class="value">{{ campaign.source }}</div>
        </div>
        <div v-if="profile.can('Lead TrafficTypeView')" class="traffic-type">
            <span class="title">{{ $t('campaign.traffic_type') }}</span>
            <div class="value">{{ campaign.traffic_type }}</div>
        </div>
        <div class="date">
            <span class="title">{{ $t('campaign.entries_at') }}</span>
            <div class="value">{{ campaign.pivot.created_at }}</div>
        </div>
        <div v-if="profile.can('Lead OfferView')" class="offer">
            <span class="title">{{ $t('campaign.offer') }}</span>
            <div class="value">{{ campaign.offer.name }}</div>
        </div>
        <div v-if="profile.can('Lead AffiliateView')" class="affiliate">
            <span class="title">{{ $t('campaign.affiliate') }}</span>
            <div class="value">{{ campaign.affiliate.name }}</div>
        </div>
        <div v-if="profile.can('Lead BuyerView')" class="buyer">
            <span class="title">{{ $t('campaign.buyer') }}</span>
            <div class="value">{{ campaign.buyer.name }}</div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.card {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 6px 0;
    padding: 15px;
    position: relative;
    border-radius: var(--bar-rounding, $bar-rounding);
    background-color: var(--secondary, $secondary);
    box-shadow: 0 5px 15px -5px #00000055;

    .title {
        font-size: 0.9rem;
        color: var(--main-text-color, $main-text-color);
    }

    .value {
        white-space: nowrap;
        color: var(--title-color, $title-color);
    }

    .campaign {
        grid-column: 1/4;
        color: var(--success-hover, $success-hover);
    }
}
</style>