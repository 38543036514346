<script setup>

import { ref, defineEmits, defineProps } from 'vue';
import { useShipActions } from '@/Ship';
import { useLeadActions } from '@lead';

const props = defineProps(['payload']);
const emit = defineEmits(['performed']);

const getLeadResource = useLeadActions('getLeadResource');
const lead = getLeadResource();

const onlyNumberInput = useShipActions('onlyNumberInput');

const reason = ref('');
const balance = ref(props.payload.balance);

function apply() {
    lead.save(null, {
        reason: reason.value,
        balance: balance.value
    }).then(() => {
        emit('performed', balance.value);
    });
}
</script>

<template>
    <form class="correct-balance" v-on:submit.prevent="apply">

        <fieldset>
            <legend>New balance</legend>
            <g-input v-model="balance" v-on:keypress="onlyNumberInput($event)"/>
        </fieldset>

        <g-textarea v-model="reason" rows="3" label="Reason"/>

        <g-flex class="btn-bar" justify="center" gap="8">
            <g-button class="cancel-btn" type="button" v-on:click="$emit('close')">{{ $t('base.cancel', 'Cancel') }}</g-button>
            <g-button class="save-btn" type="submit" v-bind:disabled="!balance || !reason">{{ $t('base.apply', 'Apply') }}</g-button>
        </g-flex>
    </form>
</template>

<style lang="scss" scoped>
.correct-balance {
    width: 280px;
}

.g-textarea {
    width: 100%;
    margin-top: 10px;
}

.btn-bar {
    padding-top: 10px;
}

.g-button {
    min-width: 100px;
}
</style>