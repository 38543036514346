<script setup>

import { ref, defineEmits, defineProps } from 'vue';
import { i18n } from '@/Ship';
import { useFinanceActions } from '@finance';
import { useLeadActions } from '@lead';

const props = defineProps(['payload']);
const emit = defineEmits(['performed']);

const getLeadResource = useLeadActions('getLeadResource');
const lead = getLeadResource();

const getDepositResource = useFinanceActions('getDepositResource');
const deposit = getDepositResource();

const index = deposit.state.findIndex(deposit => deposit.id === props.payload.id);

const refreshBalance = ref(deposit.state[index].status === 'processed');

function removeDeposit() {
    deposit.delete(props.payload.id, refreshBalance.value ? {data: { refresh_balance: true }} : null).then(() => {
        if (refreshBalance.value) {
            lead.model.balance = (Number(lead.model.balance) - Number(deposit.state[index].amount)).toFixed(2);
        }
        deposit.state.splice(index, 1);
        emit('performed');
    });

}
</script>

<template>
    <div class="message">
        <g-caption align="center" size="4">{{ i18n.global.t('finances.remove_confirm') }}!</g-caption>
        <g-flex justify="center">
            <g-checkbox v-model:checked="refreshBalance"
                        v-bind:disabled="deposit.state[index].status !== 'processed'">{{ i18n.global.t('finances.refresh_balance', 'Refresh balance') }}</g-checkbox>
        </g-flex>
    </div>
    <g-flex class="btn-bar" justify="center" gap="5">
        <g-button class="cancel-btn" v-on:click="$emit('performed')">{{ $t('base.cancel', 'Cancel') }}</g-button>
        <g-button class="apply-btn" v-on:click="removeDeposit">{{ $t('base.remove', 'Remove') }}</g-button>
    </g-flex>
</template>

<style scoped lang="scss">
.message {
    max-width: 320px;
}

.g-caption {
    margin-bottom: 15px;
}

.btn-bar {
    padding-top: 25px;
}

.g-button {
    color: white;
    width: 120px;

    &.cancel-btn {
        background-color: transparent;
        border: 1px solid var(--separator-color, $separator-color);
    }

    &.apply-btn {
        background-color: var(--danger, $danger);

        &:hover {
            background-color: var(--danger-hover, $danger-hover);
        }
    }
}
</style>