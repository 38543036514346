import { useArchiveResources } from '@archive';

export default (reload = false) => {
    const archive = useArchiveResources('Archive');

    if (reload) {
        if (!archive.isLoading) {
            archive.perPage = 25;
            // archive.setIncludes(['affiliate', 'sale', 'retention', 'country', 'campaigns']);
            // lead.load();
        }
    }

    return archive;
};
