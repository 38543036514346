
import { ApiClient, STACK } from '@/Ship';
import { useLeadResources } from "@lead";

export default (sections) => {

    const leads = useLeadResources('Lead');

    const params = leads.buildParams();
    params.filter = sections.join(',');

    return STACK.push(() => ApiClient.get('leads/filters', { params })).then(response => {
        sections.forEach(section => {
            leads.filterset[section] = response.data[section];
        });
    });
}