

export default (position, tradingFee, prices = {}) => {

	const marketPrice = prices[position.pair_symbol] || position.entry_price;

	const entryPrice = Number(position.entry_price);
	const marketCurrency = (position.pair.market === 'forex' || position.pair.market === 'commodities') ? 'USD' : 'USDT';

	let counterPrice = 1;
	if (position.pair.counter !== marketCurrency) {
		if (position.pair.base === marketCurrency) {
			counterPrice = 1 / marketPrice;
		} else {
			counterPrice = prices[`${position.pair.counter}${marketCurrency}`];
			if (!counterPrice) {
				counterPrice = prices[`${marketCurrency}${position.pair.counter}`];
				if (counterPrice) {
					counterPrice = 1 / counterPrice;
				}
			}
		}
	}

	const swap = ((position.usdt_amount * position.swap) / 100) * (counterPrice || 1);

	const fee = position.usdt_amount * tradingFee / 100;

	return position.direction === 'Long'
		? ((marketPrice - entryPrice) * counterPrice * Number(position.value) - swap - fee).toFixed(2)
		: ((entryPrice - marketPrice) * counterPrice * Number(position.value) - swap - fee).toFixed(2);
};