import { useFinanceResources } from '@finance';

export default (filters, reload = false, referer = null) => {
    const withdraw = useFinanceResources('Withdraw');

    if (reload || (!withdraw.isLoaded && !withdraw.isLoading)) {

        withdraw.setIncludes(['user']);
        withdraw.setFilters(filters)
        withdraw.sort('id', 'desc');

        withdraw.load(null, {}, referer);
    }

    return withdraw;
};
