<script setup>

import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useUserActions } from '@user';
import { useProfileActions } from '@profile';
import { PopUpManager as PopUp } from "@/Ship";

const getUserResource = useUserActions('getUserResource');
const getProfileResource = useProfileActions('getProfileResource');

const users = getUserResource();
const profile = getProfileResource();

const router = useRouter();

function openUser(id) {
    users.index = users.state.findIndex(item => item.id === id);
    router.push({ name: 'User', params: { ...router.currentRoute.value.params, id } });
}

function sortBy(field) {
    if (users.sortBy === field) {
        profile.state.user.sortDir = users.sortDir === 'asc'
            ? 'desc'
            : 'asc';
    }
    profile.state.user.sortBy = field;
    profile.saveState();

    users.sort(
        profile.state.user.sortBy,
        profile.state.user.sortDir
    );

    users.load();
}

users.sort(
    profile.state.user.sortBy,
    profile.state.user.sortDir
);

users.load(null, {}, 'Users Environment');

if (router.currentRoute.value.params.id) {
    users.onload.push(() => {
        openUser(Number(router.currentRoute.value.params.id));
    });
}


</script>

<template>
    <div class="users environment">

        <g-preloader-overlay v-if="users.isLoading"/>

        <g-flex class="toolbar" gap="5" align="center" justify="end">
            <g-button v-if="profile.can('User Make')" class="tool" v-on:click="PopUp.open(require('@user/components/PopUps/MakeUser')).setCaption($t('user.make_user', 'Make User'))">
                <g-symbol name="customer" width="26" height="26"/>
                <g-symbol name="plus" width="18" height="18"/>
            </g-button>
        </g-flex>

        <div class="index">
            <div class="row header">
                <!-- ID -->
                <div class="id cell title sortable"
                     v-bind:class="{ [`sorted ${users.sortDir}`]:  users.sortBy === 'id' }"
                     v-on:click="sortBy('id')">
                    <g-flex align="center" gap="5" inline>
                        ID <g-symbol name="arrow-down" width="14" height="14" />
                    </g-flex>
                </div>

                <!-- Name -->
                <div class="name cell title sortable"
                     v-bind:class="{[`sorted ${users.sortDir}`]:  users.sortBy === 'name' }"
                     v-on:click="sortBy('name')">
                    <g-flex align="center" gap="5" inline>
                        {{ $t('user.name', 'Name') }}
                        <g-symbol name="arrow-down" width="14" height="14"/>
                    </g-flex>
                </div>

                <!-- Email -->
                <div class="email cell title sortable"
                     v-bind:class="{[`sorted ${users.sortDir}`]:  users.sortBy === 'email' }"
                     v-on:click="sortBy('email')">
                    <g-flex align="center" gap="5" inline>
                        Email <g-symbol name="arrow-down" width="14" height="14" />
                    </g-flex>
                </div>

                <!-- Roles -->
                <div class="roles cell title">{{ $t('user.roles', 'Roles') }}</div>

                <!-- Teams -->
                <div class="teams cell title">{{ $t('user.teams', 'Teams') }}</div>

                <!-- Teamleads -->
                <div class="teamleads cell title">{{ $t('user.teamleads', 'Teamleads') }}</div>

                <!-- Status -->
                <div class="status cell title sortable"
                     v-bind:class="{[`sorted ${users.sortDir}`]:  users.sortBy === 'status' }"
                     v-on:click="sortBy('status')">
                    <g-flex align="center" gap="5" inline>
                        {{ $t('user.status', 'Status') }}
                        <g-symbol name="arrow-down" width="14" height="14" />
                    </g-flex>
                </div>

                <!-- Created at -->
                <div class="date cell title sortable"
                     v-bind:class="{[`sorted ${users.sortDir}`]:  users.sortBy === 'created_at' }"
                     v-on:click="sortBy('created_at')">
                    <g-flex align="center" gap="5" inline>
                        {{ $t('user.created_at', 'Created at') }}
                        <g-symbol name="arrow-down" width="14" height="14" />
                    </g-flex>
                </div>
            </div>

            <div class="row"
                 v-for="user in users.state"
                 v-bind:key="`user-${user.id}`"
                 v-on:click="openUser(user.id)"
                 v-bind:class="[{'open': $route.params.id === user.id.toString()}, user.status.toLowerCase()]">

                <!-- ID -->
                <div class="id cell" v-bind:class="{ sorted: users.sortBy === 'id' }">
                    {{ user.id }}
                </div>

                <!-- Name -->
                <div class="name cell" v-bind:class="{ sorted: users.sortBy === 'name' }">
                    {{ user.name }}
                </div>

                <!-- Email -->
                <div class="email cell" v-bind:class="{ sorted: users.sortBy === 'email' }">{{ user.email }}</div>

                <!-- Roles -->
                <div class="role cell">{{ user.roles.map(role => role.name).join(', ') }}</div>

                <!-- Teams -->
                <div class="team cell">
                    <div>{{ user.teams.map(team => team.name).join(', ') }}</div>
                </div>

                <!-- Teamleads -->
                <div class="teamlead cell">
                    {{ user.teamleads.map(teamlead => teamlead).join(', ') }}
                </div>

                <!-- Status -->
                <div class="status cell" v-bind:class="{ sorted: users.sortBy === 'status' }">
                    {{ user.status }}
                </div>

                <!-- Created at -->
                <div class="date cell" v-bind:class="{ sorted: users.sortBy === 'created_at' }">
                    {{ user.created_at }}
                </div>
            </div>
            <div class="nothing-found" v-if="!users.state.length && !users.isLoading">
                {{ $t('base.nothing_found', 'Nothing found') }}
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.environment {
    height: 100%;
    margin: 0 6px;
    padding: 8px 20px;
    position: relative;
    border-radius: 12px;
    font-size: 0.9rem;
    border: 3px solid var(--secondary, $secondary);
    background-color: var(--env-bg, $env-bg);
    color: var(--env-text-color, $env-text-color);
}

.nothing-found {
    font-size: 1.5rem;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
}

.toolbar {
    padding: 12px 20px;

    .tool {
        cursor: pointer;
        position: relative;
        width: 42px;
        fill: #eeeeee;
        background-color: var(--primary, $primary);

        & > .g-symbol:last-child {
            fill: black;
            padding: 4px;
            right: 4px;
            bottom: 4px;
            border-radius: 50%;
            position: absolute;
            background-color: #ffffffdd;
        }

        &:hover {
            fill: white;
            background-color: var(--primary-hover, $primary-hover);

            & > .g-symbol:last-child {
                fill: white;
                background-color: black;
            }
        }
    }
}

.index {
    position: relative;
    min-width: 100%;
    padding: 20px;
}

.preloader-wrapper {
    height: 100vh !important;
}

.row {
    fill: var(--main-text-color, $main-text-color);

    &:nth-child(even) {
        background-color: #00000011;
    }

    &:not(.header) {
        cursor: pointer;

        & > .cell {
            border: 0 solid transparent;
            border-width: 1px 0;
        }

        &:hover > .cell,
        &.open > .cell {
            border-color: var(--success, $success);
        }
    }

    &.suspended {
        opacity: 0.7;
        color: var(--warning, $warning) !important;
    }

    &.deleted {
        opacity: 0.3;
    }
}

.cell {
    &.title {
        &.sortable {
            .g-symbol {
                cursor: pointer;
                fill: var(--env-text-color, $env-text-color);
            }

            &.sorted {
                font-weight: bold;
                fill: var(--sorted-text-color, $sorted-text-color);

                &.desc {
                    .g-symbol {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    &.sorted {
        color: var(--sorted-text-color, $sorted-text-color);
        background-color: var(--sorted-bg, $sorted-bg);
    }

    &.id,
    &.date,
    &.status {
        white-space: nowrap;
        text-align: center;
    }
}

@media (min-width: 1025px) {
    .index {
        display: table;

        .row {
            display: table-row;

            & > .cell {
                padding: 10px 8px;
                display: table-cell;
                vertical-align: middle;
            }
        }
    }
}

@media (max-width: 1024px) {
    .index {
        display: table;

        .row {
            display: table-row;

            & > .cell {
                padding: 8px 5px;
                display: table-cell;
                vertical-align: middle;
            }
        }
    }
}
</style>
