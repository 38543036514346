<script setup>

import { useAssetActions } from '@asset';
import { computed, reactive, defineEmits } from 'vue';

const emit = defineEmits(['performed', 'close']);

const getPairResource = useAssetActions('getPairResource');
const getAssetResource = useAssetActions('getAssetResource');

const pair = getPairResource();
const asset = getAssetResource();

const payload = reactive({
    base: '',
    counter: '',
    market: '',
});

const assets = computed(() => asset.state);
const pairs = computed(() => pair.state);

const marketList = [{ market: 'crypto' }, { market: 'commodities' }, { market: 'stocks' }];

const filteredAssets = computed(() => {
    const createdPairs = pairs.value.map(pair => pair.symbol);

    return assets.value.filter(asset => {
        const canCreatePair = counterByMarket(payload.market).some(counter => counter.counter === asset.counter);
        return !createdPairs.includes(asset.symbol) || !canCreatePair;
    });
});

const isMakeDisabled = computed(() => {
    return (
        !payload.base ||
        !payload.counter ||
        !payload.market
    );
});

const counterByMarket = (value) => {
    const createdCounters = pairs.value
        .filter(pair => pair.base === payload.base)
        .map(pair => pair.counter);

    switch (value) {
        case 'crypto':
            return [{ counter: 'USDT' }, { counter: 'ETH' }, { counter: 'BTC' }].filter(item => {
                return !createdCounters.includes(item.counter) && item.counter !== payload.base;
            });
        case 'commodities':
            return [{ counter: 'USDT' }].filter(item => {
                return !createdCounters.includes(item.counter) && item.counter !== payload.base;
            });
        case 'stocks':
            return [{ counter: 'USDT' }].filter(item => {
                return !createdCounters.includes(item.counter) && item.counter !== payload.base;
            });
        default:
            return [{ counter: 'USDT' }, { counter: 'ETH' }, { counter: 'BTC' }].filter(item => {
                return !createdCounters.includes(item.counter) && item.counter !== payload.base;
            });
    }
};

function makePair() {
    payload.symbol = `${payload.base.toUpperCase()}${payload.counter}`;
    pair.create(payload);
    pair.load();
    emit('performed');
}
</script>

<template>
    <form class="make-pairs" v-on:submit.prevent="makePair">
        <fieldset>
            <legend>{{ $t('assets.market', 'Market') }}</legend>
            <g-select v-model="payload.market"
                      v-bind:options="marketList"
                      option-text="market"
                      text-transform="capitalize"
                      option-value="market"/>
        </fieldset>

        <fieldset>
            <legend>{{ $t('assets.base', 'Base') }}</legend>
            <g-select v-model="payload.base"
                      v-bind:options="filteredAssets"
                      option-text="symbol"
                      option-value="symbol"
                      v-bind:disabled="!payload.market"/>
        </fieldset>

        <fieldset>
            <legend>{{ $t('assets.counter', 'Counter') }}</legend>
            <g-select v-model="payload.counter"
                      v-bind:options="counterByMarket(payload.market)"
                      option-text="counter"
                      option-value="counter"
                      v-bind:disabled="!payload.market || !counterByMarket(payload.market).length"/>
        </fieldset>

        <g-flex class="btn-bar" gap="5" justify="center">
            <g-button class="cancel-btn" type="button" v-on:click="$emit('close')">{{ $t('base.cancel', 'Cancel') }}</g-button>
            <g-button class="save-btn" type="submit" v-bind:disabled="isMakeDisabled">{{ $t('base.make', 'Make') }}</g-button>
        </g-flex>
    </form>
</template>


<style scoped lang="scss">
.make-pairs {
    width: 320px;
}

fieldset {
    margin: 5px 0;

    & > .g-input,
    & > .g-select:deep(.field) {
        background-color: var(--body-bg, $body-bg);
    }
}

.btn-bar {
    margin-top: 30px;
}

.g-button {
    min-width: 100px;
    padding: 0 20px;
}
</style>