<script setup>

import { ref, defineEmits } from 'vue';
import { useAuthComponents } from '@auth';
import { useLeadActions } from '@lead';

const getLeadResource = useLeadActions('getLeadResource');
const lead = getLeadResource();

const {
	PasswordInput,
} = useAuthComponents();

const level = ref('Silver');
const password = ref(null);

const emit = defineEmits(['performed']);

function activate() {
	lead.save(null, {
		activate: {
			account_level: level.value,
			password: password.value
		}
	}).then(({customer_id}) => {
		emit('performed');
	});
}

</script>

<template>
    <form v-on:submit.prevent="activate">
		<g-select v-model="level"
		          v-bind:label="$t('customer.account_level', 'Account Level')"
		          v-bind:options="[{value: 'Silver'}, {value: 'Gold'}, {value: 'Platinum'}, {value: 'VIP'}, {value: 'Dragon'}]"
		          option-text="value"
		          option-value="value"/>
		<password-input v-model="password" label="Password"/>
		<g-button type="submit">{{ $t('base.activate') }}</g-button>
    </form>
</template>

<style scoped lang="scss">
form {
    text-align: center;
}

.g-button {
    color: white;
    fill: white;
    margin-top: 10px;
    padding: 0 20px;
    font-weight: 500;
	background-color: var(--success, $success);

    &:hover {
      background-color: var(--success-hover, $success-hover);
    }
}
</style>