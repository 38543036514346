<template>
    <div class="card">
        <g-preloader-overlay v-if="leads.isLoading"/>

        <g-flex class="lead-bar" align="center" justify="between" gap="8">

            <!--
            <router-link class="close-btn" v-bind:to="{ name: 'Leads', params: $route.params }">
                <g-symbol name="close" width="24" height="24"/>
            </router-link>
            -->

            <b class="lead-id">ID: {{ leads.model.id }}</b>
            <span v-if="profile.can('Lead BalanceView')" class="balance">
                {{ $t('lead.balance') }}: <b>{{ leads.model.balance }}</b> usdt
            </span>
            <span v-if="leads.model.is_test === 'Yes'" class="is-test">{{ $t('lead.test_lead', 'Test Lead') }}</span>

            <div class="separator"></div>

            <!--<save-indicator/>-->

            <g-button type="button"
                      class="online-indicator with-tooltip"
                      v-bind:class="{active: !leads.model.customer.online_at}"
                      v-bind:data-tooltip="!leads.model.customer.online_at ? $t('lead.lead_online') : $t('lead.lead_offline')">
                <g-symbol name="online" width="20" height="20"/>
            </g-button>
            <!--
            <g-button type="button"
                      class="blocked-indicator with-tooltip"
                      v-bind:class="{active: leads.model.customer.is_locked === 'Yes'}"
                      v-bind:data-tooltip="leads.model.customer.is_locked === 'Yes' ? $t('lead.client_is_blocked') : $t('lead.client_active')">
                <g-symbol name="lock" width="20" height="20"/>
            </g-button>
            -->

            <g-button v-if="leads.model.customer_id && profile.can('Lead Impersonate')"
                      class="impersonate-btn with-tooltip"
                      type="button"
                      v-on:click="impersonate(leads.model.customer_id)"
                      v-bind:data-tooltip="$t('lead.impersonate', 'Impersonate')">
                <g-symbol name="eye-open" width="20" height="20"/>
            </g-button>
            <g-button v-if="profile.can('Lead Archive')"
                      class="archive-btn with-tooltip"
                      type="button"
                      v-on:click="leadArchiving"
                      v-bind:data-tooltip="$t('base.archive')">
                <g-symbol name="archive" width="18" height="18"/>
            </g-button>
            <g-button v-if="profile.can('Lead Remove')"
                      class="remove-btn with-tooltip"
                      type="button"
                      v-on:click="removeLead"
                      v-bind:data-tooltip="$t('base.remove')">
                <g-symbol name="delete" width="20" height="20"/>
            </g-button>

        </g-flex>
        <aside>
            <g-flex class="tab-bar" direction="column" justify="center" align="center" gap="20">
                <div>
                    <g-hamburger v-model="hamburger" v-on:change="$router.push({ name: 'Leads', params: $route.params })"/>
                </div>
                <div>
                    <button class="tab-btn with-tooltip"
                            v-bind:class="{active: tab === 'Profile'}"
                            v-bind:data-tooltip="$t('lead.profile', 'Profile')"
                            v-on:click="openTab('Profile')">
                        <g-symbol name="profile" width="20" height="20"/>
                    </button>
                    <button v-if="profile.can('Comment View')" class="tab-btn with-tooltip"
                            v-bind:class="{active: tab === 'Comments'}"
                            v-bind:data-tooltip="$t('lead.comments', 'Comments')"
                            v-on:click="openTab('Comments')">
                        <g-symbol name="comments" width="22" height="22"/>
                    </button>
                    <button v-if="profile.can('Lead ActivityView')" class="tab-btn with-tooltip"
                            v-bind:class="{active: tab === 'Activity'}"
                            v-bind:data-tooltip="$t('lead.activity', 'Customer activity')"
                            v-on:click="openTab('Activity')">
                        <g-symbol name="online" width="22" height="22"/>
                    </button>
                    <button v-if="profile.can('Timeline View')" class="tab-btn with-tooltip"
                            v-bind:class="{active: tab === 'Timeline'}"
                            v-bind:data-tooltip="$t('lead.timeline', 'Timeline')"
                            v-on:click="openTab('Timeline')">
                        <g-symbol name="timeline" width="22" height="22"/>
                    </button>
                    <button v-if="profile.can('Deposit View') || profile.can('Withdraw View')"
                            class="tab-btn with-tooltip"
                            v-bind:class="{active: tab === 'Finances'}"
                            v-bind:data-tooltip="$t('lead.finances', 'Finances')"
                            v-on:click="openTab('Finances')">
                        <g-symbol name="dollar" width="22" height="22"/>
                    </button>
                    <button v-if="profile.can('Position View') || profile.can('PositionHistory View')"
                            class="tab-btn with-tooltip"
                            v-bind:class="{active: tab === 'Trading'}"
                            v-bind:data-tooltip="$t('lead.trading', 'Trading')"
                            v-on:click="openTab('Trading')">
                        <g-symbol name="chart" width="20" height="20"/>
                    </button>
                </div>
            </g-flex>
            <div class="lead custom-scrollbar" v-if="Number.isInteger(leads.index) && leads.index > -1">
                <component v-bind:is="tab" v-bind:key="leads.index"/>
            </div>
        </aside>
    </div>
</template>

<script>

import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { i18n, PopUpManager as PopUp, STATE } from '@/Ship';
import { useLeadActions, useLeadComponents } from '@lead';
import { useProfileActions } from '@profile';
import { useEnvComponents } from '@overlay';
import Comments from '@comment';
import Timeline from '@timeline';
import Activity from '@activity';
//import Finances from '@finance';
import Trading from '@trading';

const {
    archiving,
    getLeadResource
} = useLeadActions();

const getProfileResource = useProfileActions('getProfileResource');

const impersonate = useLeadActions('impersonate');

const {
    Profile,
    Finances,
} = useLeadComponents();

export default {
    name: 'Lead',
    computed: {
        STATE() {
            return STATE
        }
    },
    components: {
        Comments,
        Timeline,
        Activity,
        Trading,
        Profile,
        Finances,
        SaveIndicator: useEnvComponents('SaveIndicator')
    },

    setup() {
        const hamburger = true;
        const leads = getLeadResource();
        const profile = getProfileResource();
        const router = useRouter();
        const route = useRoute();
        const tab = ref(profile.state.lead.tab || 'Profile');

        function openTab(tabName) {
            tab.value = tabName;
            profile.state.lead.tab = tabName;
            profile.saveState();
        }

        function leadArchiving() {
            PopUp
            .setCaption(i18n.global.t('lead.remove_lead', 'Remove Lead'))
            .setMessage(`<p class="message"><b>${i18n.global.t('lead.archive_confirm', { id: leads.model.id }, 'The Customer with ID:{id} will be archived')}!</b></p></p>`)
            .setAction(() => {
                archiving.archive(leads.model.id).then(() => {
                    leads.state.splice(leads.index, 1);
                    leads.index = null;
                    router.push({ name: 'Leads', params: route.params });
                });

                PopUp.close();
            })
            .open(require('@/Ship/components/PopUps/Confirm'));
        }

        function removeLead() {
            PopUp
            .setCaption(i18n.global.t('lead.remove_lead', 'Remove Customer'))
            .setMessage(`<p class="message"><b>${i18n.global.t('lead.remove_confirm', { id: leads.model.id }, 'The Customer with ID:{id} will be permanently deleted')}!</b></p>`)
            .setAction(() => {
                leads.delete(leads.model.id).then(() => {
                    leads.state.splice(leads.index, 1);
                    leads.index = null;
                    router.push({ name: 'Leads', params: route.params });
                });

                PopUp.close();
            })
            .open(require('@/Ship/components/PopUps/Confirm'));
        }

        return {
            tab,
            leads,
            PopUp,
            profile,
            impersonate,
            hamburger,
            openTab,
            leadArchiving,
            removeLead,
        };
    }
};
</script>

<style lang="scss" scoped>
.card {
    position: relative;
}

.lead-bar {
    padding: 0 20px;
    height: var(--bar-height, $bar-height);
    color: var(--env-text-color, $env-text-color);

    .balance {
        gap: 4px;
        display: inline-flex;
        align-items: center;
        margin-left: 10px;
        padding-left: 10px;
        border-left: 1px solid var(--separator-color, $separator-color);
    }

    .g-button {
        width: 35px;
        height: 35px;
        color: white;
        margin: 0 2px;
        font-size: 0;
        background-color: transparent;
        fill: var(--env-text-color, $env-text-color);
        //border: 1px solid var(--main-text-color, $main-text-color);
        position: relative;

        &.online-indicator {
            border: none;
            border-radius: 50%;

            &::after {
                left: 50%;
                width: 100px;
            }

            &.active {
                //fill: var(--danger-hover, $danger-hover);
                fill: white;
                background-color: var(--danger, $danger);
                //border: 1px solid var(--danger-hover, $danger-hover);

                &:hover {
                    &::after {
                        background-color: var(--danger, $danger);
                    }

                    &::before {
                        border-bottom-color: var(--danger, $danger);
                    }
                }
            }

            &:not(.active) {
                &:hover {
                    &::after {
                        background-color: var(--success, $success);
                    }

                    &::before {
                        border-bottom-color: var(--success, $success);
                    }
                }
            }
        }

        &.impersonate-btn:hover {
            fill: var(--btn-primary-color, $btn-primary-color);
            border-color: var(--primary, $primary);
            background-color: var(--primary, $primary);

            &::after {
                background-color: var(--primary, $primary);
            }

            &::before {
                border-bottom-color: var(--primary, $primary);
            }
        }

        &.activate-btn:hover {
            fill: var(--title-color, $title-color);
            border-color: var(--success, $success);
            background-color: var(--success, $success);

            &::after {
                background-color: var(--success, $success);
            }

            &::before {
                border-bottom-color: var(--success, $success);
            }
        }

        &.archive-btn:hover,
        &.remove-btn:hover {
            fill: var(--btn-primary-color, $btn-primary-color);
            border-color: var(--danger, $danger);
            background-color: var(--danger, $danger);

            &::after {
                background-color: var(--danger, $danger);
            }

            &::before {
                border-bottom-color: var(--danger, $danger);
            }
        }

        &::after {
            left: 50%;
            transform: translateX(-50%);
            top: calc(100% + var(--tooltip-arrow-size, $tooltip-arrow-size));
        }

        &::before {
            left: 50%;
            transform: translateX(-50%);
            top: calc(100% - var(--tooltip-arrow-size, $tooltip-arrow-size));
        }
    }

    & > .separator {
        flex-grow: 1;
    }
}

.is-test {
    color: white;
    padding: 2px 6px;
    font-size: 0.8rem;
    border-radius: 9px;
    background-color: var(--danger, $danger);
}

.lead {
    flex-grow: 1;
    padding: 0 5px;
    overflow: auto;
    height: 100%;
}

aside {
    height: calc(100% - var(--bar-height, $bar-height));
}

.tab-bar {
    position: absolute;
    z-index: 5;
    top: 50%;
    left: -25px;
    transform: translateY(-50%);
    border-radius: 12px;
    width: 60px;
    padding: 20px;
    background-color: var(--secondary, $secondary);
    box-shadow: 0 0 15px -5px #000000aa;

    .tab-btn {
        position: relative;
        padding: 10px 0;
        fill: #97edff;
        width: 42px;
        height: 42px;

        &:hover {
            fill: var(--title-text-color, $title-text-color);

            & > .g-symbol {
                transform: scale(1.2);
            }
        }

        &.active {
            box-shadow: 0 0 10px #00b0b5;
            fill: var(--title-text-color, $title-text-color);
            background-color: #1d212f;
            border-radius: 50%;
        }

        &.online {
            fill: var(--title-text-color, $title-text-color);
            background-color: var(--success, $success);

            &:hover,
            &.active {
                background-color: var(--success-hover, $success-hover);
            }
        }

        &::after {
            top: 50%;
            transform: translateY(-50%);
            left: calc(100% + var(--tooltip-arrow-size, $tooltip-arrow-size));
        }

        &::before {
            top: 50%;
            transform: translateY(-50%);
            left: calc(100% - var(--tooltip-arrow-size, $tooltip-arrow-size));
            border-right-color: var(--tooltip-bg, $tooltip-bg);
        }
    }
}

.online-indicator {
    width: 20px;
    height: 20px;
    display: inline-block;
    background-color: var(--success-hover, $success-hover);
}
</style>