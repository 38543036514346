<script setup>
import { useLeadActions } from '@lead';
import { useFinanceActions } from '@finance';
import { i18n, PopUpManager as PopUp } from '@/Ship';

const getLeadResource = useLeadActions('getLeadResource');
const lead = getLeadResource();

const statuses = [
    { slug: 'new', name: 'New' },
    { slug: 'suspend', name: 'Suspend' },
    { slug: 'processed', name: 'Processed' },
    { slug: 'refund', name: 'Refund' }
];

const getDepositResource = useFinanceActions('getDepositResource');
const deposits = getDepositResource(
	{lead_id: [lead.model.id]},
	true,
	'LeadDeposits Component'
);


function changeStatus(index, value) {
    const previousValue = deposits.state[index].status;
    deposits.save(deposits.state[index].id, {
        status: value
    }).then(() => {
        if (value === 'processed') {
            lead.model.balance = Number(lead.model.balance) + Number(deposits.state[index].amount);
        } else if (previousValue === 'processed') {
            lead.model.balance -= Number(deposits.state[index].amount);
        }
    });
}

let timeout = null;
function changeAmount(index) {

    clearTimeout(timeout);
    timeout = setTimeout(() => {
        deposits.save(deposits.state[index].id, {
            amount: deposits.state[index].amount
        }).then(() => {

        });
    }, 2000);
}

function removeDeposit(id) {
    PopUp.open(require('@finance/components/PopUps/RemoveDeposit'))
        .setCaption(i18n.global.t('finances.remove_replenishment', 'Remove replenishment'))
        .setPayload({payload: {id}});
}

</script>

<template>
    <div class="deposits">

        <g-preloader-overlay v-if="deposits.isLoading"/>

        <div v-for="(deposit, i) in deposits.state"
             v-bind:key="`deposit-${deposit.id}`"
             class="card"
             v-bind:class="[deposit.type]"
             v-bind:data-type="deposit.type || 'Deposit'">

            <!-- type -->
            <div class="type">
                <div class="title"></div>
                <span v-if="deposit.is_ftd === 'Yes'" class="value">
                    <span class="test">{{ deposit.is_test === 'Yes' ? 'Test' : '' }}</span>
                    {{ deposit.type === 'deposit' ? 'FTD' : $t(`finances.${deposit.type}`) }}
                </span>
                <span v-else class="value">
                    <span class="test">{{ deposit.is_test === 'Yes' ? 'Test' : '' }}</span>
                    {{ deposit.type === 'deposit' ? $t('finances.replenishment') : $t(`finances.${deposit.type.toLowerCase()}`) }}
                </span>
            </div>
            <!--/ TYPE -->

            <!-- ADDRESS -->
            <div class="address">
                <div class="title">From Address</div>
                <g-copy v-if="deposit.address" class="value" v-bind:text="deposit.address"></g-copy>
            </div>
            <!--/ ADDRESS -->

            <div class="actions">
                <g-button class="remove-btn with-tooltip"
                          v-bind:data-tooltip="$t('finances.remove_replenishment', 'Remove replenishment')"
                          v-on:click="removeDeposit(deposit.id)">
                    <g-symbol name="delete" width="18" height="18"/>
                </g-button>
            </div>

            <!-- USDT AMOUNT -->
            <div class="usdt_amount">
                <div class="title">USDT Amount:</div>
                <g-input v-model="deposit.amount" v-on:input="changeAmount(i)"/>
                <!--
                <span class="value">
                    {{ Number(deposit.amount).toFixed(2) }} <small class="currency usdt">USDT</small>
                </span>
                -->
            </div>
            <!--/ USDT AMOUNT -->

            <!-- STATUS -->
            <div class="status" v-bind:class="deposit.status">
                <div class="title">Status:</div>
                <g-select v-model="deposit.status"
                          option-text="name"
                          option-value="slug"
                          v-bind:options="statuses"
                          v-on:select="changeStatus(i, $event)"
                          v-bind::disabled="deposit.status === 'refund'"/>
            </div>
            <!--/ STATUS -->

            <div v-if="deposit.user" class="recipient">
                <div class="title">Recipient:</div>
                <span class="value">
					{{ deposit.user.name }}
				</span>
            </div>
            <div v-else></div>

            <!-- METHOD -->
            <div class="method">
                <div class="title">{{ $t('finances.method', 'Method') }}:</div>
                <span class="value">{{ deposit.method }}</span>
            </div>
            <!--/ METHOD -->

            <!-- AMOUNT -->
            <div v-if="(deposit.rate) !== 1" class="amount">
                <div class="title">Amount:</div>
                <span class="value">
					{{ Number((deposit.amount / deposit.rate).toFixed(5)) }}
					<small class="currency" v-bind:class="deposit.currency.toLowerCase()">{{ deposit.currency }}</small>

                    <small v-if="deposit.rate !== 1" class="rate"> ✕ {{ deposit.rate }}</small>
				</span>
            </div>
            <!--/ AMOUNT -->

            <!-- DATE -->
            <div class="date">
                <div class="title">Created at:</div>
                <span class="value">{{ deposit.created_at }}</span>
            </div>
            <!--/ DATE -->

            <!-- SUB METHOD -->
            <div class="sub_method">
                <div class="title">{{
                        deposit.method === 'Crypto' ? 'Network' : $t('finances.sub_method', 'SubMethod')
                    }}:
                </div>
                <span class="value">{{ deposit.sub_method }}</span>
            </div>
            <!--/ SUB METHOD -->
        </div>
    </div>
</template>

<style scoped lang="scss">
.deposits {
    padding: 0 40px 0 50px;

    & > .card {
        display: grid;
        grid-gap: 10px;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1.4fr 0.6fr;
        margin: 6px 0;
        padding: 18px;
        position: relative;
        color: var(--title-color, $title-color);
        border-radius: var(--bar-rounding, $bar-rounding);
        background-color: var(--primary, $primary);
        box-shadow: var(--bar-shadow, $bar-shadow);
        border: 2px solid transparent;

        .title {
            font-size: 0.9rem;
            color: var(--main-text-color, $main-text-color);
        }

        .value {
            white-space: nowrap;
        }

        .type {
            grid-column: 1/2;
            font-size: 16px;
            text-transform: capitalize;
        }

        .status > .g-select {
            background-color: var(--primary-hover, $primary-hover);

            &:deep(.datalist) {
                background-color: $primary-hover;
            }

            &.new > .g-select:deep(fieldset) {
                border-color: var(--primary-hover, $primary-hover);

                & > legend {
                    color: var(--primary-hover, $primary-hover);
                }
            }

            &.suspend > .g-select:deep(fieldset) {
                border-color: var(--warning-hover, $warning-hover);

                & > legend {
                    color: var(--warning-hover, $warning-hover);
                }
            }

            &.processed > .g-select:deep(fieldset) {
                border-color: var(--success-hover, $success-hover);

                & > legend {
                    color: var(--success-hover, $success-hover);
                }
            }

            &.refund > .g-select:deep(fieldset) {
                border-color: var(--separator-color, $separator-color);

                & > legend {
                    color: var(--disabled-color, $disabled-color);
                }
            }
        }

        .usdt_amount > .g-input {
            width: 100%;
            background-color: var(--primary-hover, $primary-hover);
        }

        .amount {
            grid-column: 1/3;
        }

        .method > .title,
        .recipient > .title {
            margin-bottom: 4px;
        }

        .address {
            grid-column: 2/4;

            & > .g-copy:deep(.g-symbol) {
                fill: var(--warning-hover, $warning-hover);
            }
        }

        .actions {
            grid-column: 4/5;
            text-align: right;

            & > .g-button {
                top: -16px;
                right: -5px;
                cursor: pointer;
                position: relative;
                background-color: transparent;
                fill: var(--main-text-color, $main-text-color);

                &:hover {
                    fill: var(--title-text-color, $title-text-color);

                    &::after {
                        transform: translateX(-50%);
                        top: calc(100% + var(--tooltip-arrow-size, $tooltip-arrow-size));
                        background-color: var(--danger, $danger);
                        left: 50%;
                    }

                    &::before {
                        transform: translateX(-50%);
                        left: 50%;
                        top: calc(100% - var(--tooltip-arrow-size, $tooltip-arrow-size));
                        border-bottom-color: var(--danger, $danger);
                    }
                }
            }
        }

        .date {
            grid-column: 3/4;
        }

        &.bonus {
            & > .type {
                color: var(--success-hover, $success-hover);
            }
        }

        &.credit {
            & > .type {
                color: var(--warning, $warning);
            }
        }

        &.fake {
            & > .type {
                color: var(--danger-hover, $danger-hover);
            }
        }
    }
}

.test {
    color: var(--main-text-color, $disabled-color);
}

.rate,
.currency {
    font-size: 0.8rem;
}

.btc {
    color: $btc;
    font-weight: 700;
}

.usdt {
    color: $usdt;
    font-weight: 700;
}

.eur {
    color: $eur;
    font-weight: 700;
}

.eth {
    color: $eth;
    font-weight: 700;
}
</style>