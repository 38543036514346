<script setup>

import { computed, watch, reactive, ref, defineEmits } from 'vue';
import { useRouter } from "vue-router";
import { verifyField, i18n } from '@/Ship';
import { useCampaignActions } from '@campaign';
import { useUserActions } from '@user';
import { useAuthComponents } from '@auth';
import { useCountryActions } from '@country';
import { useLeadActions } from '@lead';

const emit = defineEmits(['performed', 'close']);

const PasswordInput = useAuthComponents('PasswordInput');

const getCampaignResource = useCampaignActions('getCampaignResource');
const campaigns = getCampaignResource();
if (!campaigns.isLoaded) {
    campaigns.load();
}

const getLeadResource = useLeadActions('getLeadResource');
const lead = getLeadResource();

const getCountryResource = useCountryActions('getCountryResource');
const countries = getCountryResource();

const getSales = useUserActions('getSales');
const sales = ref([]);

const router = useRouter();

getSales().then(response => {
    sales.value = response.data;
});

const payload = reactive({
    first_name: null,
    last_name: null,
    email: null,
    phone: '',
    country_code: '',
    password: null,
    campaign_id: null,
    sale_id: null,
    is_test: 'Yes'
});

const isAllow = ref(false);
const isComplex = ref(false);
const errors = reactive({
    first_name: false,
    last_name: false,
    email: false,
    phone: false,
    password: false,
});

const isTestDisplay = computed({
    get() {
        return payload.is_test === 'Yes';
    },
    set(value) {
        return payload.is_test = value ? 'Yes' : 'No';
    }
});

const firstName = computed({
    get: () => payload.first_name,
    set: value => {
        payload.first_name = value;
        errors.first_name = verifyField(value, ['require', 'minLength:2']);
        return true;
    }
});

const lastName = computed({
    get: () => payload.last_name,
    set: value => {
        payload.last_name = value;
        errors.last_name = verifyField(value, ['require', 'minLength:2']);
        return true;
    }
});

const email = computed({
    get: () => payload.email,
    set: value => {
        payload.email = value;
        errors.email = verifyField(value, ['require', 'email']);
        return true;
    }
});

const phone = computed({
    get: () => payload.phone,
    set: value => {
        payload.phone = value;
        errors.phone = verifyField(value, ['require', 'phone']);
        return true;
    }
});

const password = computed({
    get: () => payload.password,
    set: value => {
        payload.password = value;
        errors.password = isComplex.value ? null : i18n.global.t('validator.password');
        return true;
    }
});

watch([errors, () => payload.password], () => {
    isAllow.value = true;
    for (const key in errors) {
        if (errors[key] !== null) {
            isAllow.value = false;
            break;
        }
    }
});

function make() {
    lead.create(payload).then(response => {
        emit('performed');
        lead.index = lead.state.findIndex(item => item.id === response.id);
        router.push({name: 'Lead', params: {...router.currentRoute.value.params, id: response.id}});
    }).catch(error => {
        if (error.response && error.response.data && error.response.data.description) {
            const errorDescriptions = error.response.data.description;

            for (const key in errorDescriptions) {
                if (errors[key] !== undefined) {
                    errors[key] = errorDescriptions[key][0];
                }
            }
        }
    });
}
</script>

<template>
    <form class="make-lead" v-on:submit.prevent="make">

        <div class="is-test">
            <g-checkbox v-model:checked="isTestDisplay">
                {{ $t('lead.is_test', 'Is test') }}
            </g-checkbox>
        </div>

        <div class="separator"></div>

        <fieldset>
            <legend>{{ $t('lead.campaign', 'Campaign') }}</legend>
            <g-select v-model="payload.campaign_id"
                      v-bind:options="campaigns.state"
                      option-text="name"
                      option-value="id"/>
        </fieldset>

        <fieldset>
            <legend>{{ $t('lead.sale', 'Sale agent') }}</legend>
            <g-select v-model="payload.sale_id"
                      v-bind:options="sales"
                      option-text="name"
                      option-value="id"/>
        </fieldset>

        <fieldset>
            <legend>{{ $t('lead.first_name', 'First name') }}</legend>
            <g-input v-model="firstName" v-bind:error="errors.first_name"/>
        </fieldset>

        <fieldset>
            <legend>{{ $t('lead.last_name', 'Last name') }}</legend>
            <g-input v-model="lastName" v-bind:error="errors.last_name"/>
        </fieldset>

        <fieldset class="phone">
            <legend>{{ $t('lead.country', 'Country') }}</legend>
            <g-phone-input v-model="phone"
                           v-model:country="payload.country_code"
                           v-bind:countries="countries.state"
                           v-bind:error="errors.phone"/>
        </fieldset>

        <fieldset>
            <legend>{{ $t('lead.email', 'Email') }}</legend>
            <g-input v-model="email" type="email" v-bind:error="errors.email"/>
        </fieldset>

        <fieldset>
            <legend>{{ $t('auth.password', 'Password') }}</legend>
            <password-input v-model="password"
                            v-model:complex="isComplex"
                            v-bind:error="errors.password"/>
        </fieldset>

        <g-button class="cancel-btn" type="button" v-on:click="$emit('close')">{{
                $t('base.cancel', 'Cancel')
            }}
        </g-button>
        <g-button class="save-btn" v-bind:disabled="!isAllow" type="submit">{{ $t('base.make', 'Make') }}</g-button>
    </form>
</template>

<style lang="scss" scoped>
.make-lead {
    gap: 10px;
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 400px;
}

fieldset,
.g-button {
    width: 48%;

    &.phone {
        width: 100%;
    }
}

.is-test {
    width: 48%;
    padding: 0 5px;
    height: var(--input-height, $input-height);
}

.g-input,
.g-select,
.password-input:deep(.g-input) {
    background-color: #1d212f;
}

.g-phone-input {
    gap: 10px;
    justify-content: space-between;
    width: 100%;
    padding: 0 4px;

    &:deep(.g-input) {
        width: 49%;
        background-color: #1d212f;
    }

    &:deep(.g-select) {
        width: 49%;
        margin-right: 0 !important;
        max-width: 100% !important;
        background-color: #1d212f;
    }
}

.g-button {
    padding: 0 30px;
}
</style>