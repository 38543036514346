import { useCustomerResources } from '@customer';

export default () => {
    const customer = useCustomerResources('Customer');
    if (!customer.isLoaded && !customer.isLoading) {
        customer.perPage = 25;
        customer.setIncludes(['lead']);
        //customer.load();
    }

    return customer;
};
