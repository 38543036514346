<template>
    <form class="g-search-input" v-on:submit.prevent="find">
        <fieldset>

            <legend>{{ $t('base.find', 'Find') }}</legend>

            <div class="field filled" v-bind:class="{focus: isFocus}">
                <input v-model="field"
                       class="by-field"
                       v-on:focus="showList"
                       v-on:blur="hideList"
                       readonly>
                <g-symbol class="arrow" name="arrow-down" width="20" height="20" v-on:click="showList"/>

                <div ref="datalist"
                     class="datalist"
                     v-bind:class="{ 'to-top': toTop }">
                    <label v-for="(option, i) in ['ID', 'Phone', 'Email', 'Name']"
                           v-bind:key="`search-${i}`"
                           v-bind:class="{active: option === field}">
                        <input name="search-field"
                               v-model="field"
                               type="radio"
                               v-bind:value="option"
                               v-on:change="selectField(option)"
                               hidden>
                        {{ option }}
                    </label>
                </div>


                <input ref="search"
                       v-model="searchString"
                       type="search"
                       v-on:input="determineField"
                       v-on:keypress="$event.keyCode === 13 && find()">


                <g-symbol v-if="searchString" class="clear-btn" name="close" width="20" height="20" v-on:click="reset"/>
                <g-symbol class="find-btn"
                          name="search"
                          width="20"
                          height="20"
                          v-on:click="find"/>
            </div>
        </fieldset>
    </form>
</template>

<script>

import { ref } from 'vue';

export default {
    name: 'GSearchInput',
    inheritAttrs: false,
    emits: ['find', 'drop'],
    props: {
        label: {
            type: String,
        }
    },
    setup(props, { emit }) {
        const find = () => {
            emit('find', { [field.value.toLowerCase()]: searchString.value });
        };

        const reset = () => {
            searchString.value = '';
            emit('drop');
        };

        const search = ref();
        const datalist = ref(null);
        const isFocus = ref(false);
        const toTop = ref(false);
        const field = ref('ID');
        const searchString = ref('');

        const showList = () => {
            isFocus.value = true;
            const { y } = datalist.value.getBoundingClientRect();
            toTop.value = (y + 300) > screen.availHeight;
        };
        const hideList = () => {
            setTimeout(() => {
                isFocus.value = false;
            }, 200);
        };

        function selectField(value) {
            field.value = value;
            search.value.focus();
            hideList();
        }

        function determineField(event) {
            if (/^\+\d/.test(event.target.value)) {
                field.value = 'Phone';
            } else if (/^\d+$/.test(event.target.value)) {
                field.value = 'ID';
            } else if (/^\S+@/.test(event.target.value)) {
                field.value = 'Email';
            } else {
                field.value = 'Name';
            }
        }

        return {
            search,
            toTop,
            isFocus,
            datalist,
            field,
            searchString,
            showList,
            hideList,
            reset,
            find,
            selectField,
            determineField,
        };
    }
};
</script>

<style lang="scss" scoped>
legend {
    padding-left: 5px;
}

.field {
    position: relative;
    padding: 0 10px;
    display: flex;
    white-space: nowrap;
    align-items: center;
    color: var(--main-text-color, $main-text-color);
    border: 1px solid var(--input-border, $input-border);
    border-radius: 5px;
    height: var(--input-height, $input-height);
    background-color: #1d212f;

    input {
        flex-grow: 1;
        flex-shrink: 1;
        width: 100%;
        height: 100%;
        border: none;
        padding-left: 10px;
        box-sizing: border-box;
        background-color: transparent;
        color: var(--title-color, $title-color);
        font-size: var(--input-font-size, $input-font-size);
        font-family: var(--font-primary, $font-primary);

        &.by-field {
            width: 60px;
            cursor: default;
        }

        &:focus {
            outline: none;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            background: transparent;
            transition: background-color 5000s ease-in-out 0s;
            -webkit-text-fill-color: var(--main-text-color, $main-text-color);
        }
    }

    input[type='search']::-webkit-search-decoration,
    input[type='search']::-webkit-search-cancel-button,
    input[type='search']::-webkit-search-results-button,
    input[type='search']::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }

    & > .g-symbol {
        cursor: pointer;
        flex-shrink: 0;
        margin-left: 4px;
        fill: var(--main-text-color, $main-text-color);

        &:hover {
            fill: var(--title-color, $title-color);
        }
    }

    & > .arrow {
        transition: transform 0.2s;
        fill: var(--main-text-color, $main-text-color);
    }

    & > .datalist {
        z-index: 2;
        left: -1px;
        max-height: 300px;
        width: 100px;
        position: absolute;
        overflow: auto;
        box-sizing: border-box;
        background-color: var(--input-active-bg, $input-active-bg);
        border-radius: var(--dropdown-rounding, $dropdown-rounding);
        border: 1px solid var(--dropdown-list-border, $dropdown-list-border);
        box-shadow: 0 0 12px -5px #000000bb;
        scrollbar-color: var(--scrollbar-thumb-color, $scrollbar-thumb-color) transparent;
        scrollbar-width: thin;
        transition:
            opacity 0.5s 0.1s,
            max-height 0.3s;

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-thumb {
            -webkit-border-radius: 1ex;
            background-color: var(--scrollbar-thumb-color, $scrollbar-thumb-color);
        }

        &.to-top {
            bottom: 100%;
            margin-bottom: 8px;
        }

        &:not(.to-top) {
            top: 100%;
            margin-top: 2px;
        }

        label {
            display: block;
            cursor: pointer;
            font-size: 14px;
            font-weight: 400;
            padding: 5px 15px;
            color: var(--label-color, $main-text-color);

            &:first-child {
                padding-top: 10px;
            }

            &:last-child {
                padding-bottom: 10px;
            }

            &.active {
                pointer-events: none;
                color: var(--selected-color, $selected-color);
            }

            &:not(.active):hover {
                color: var(--select-hover-color, $select-hover-color);
            }
        }
    }

    &:not(.focus) {
        & > .datalist {
            opacity: 0;
            max-height: 0;
            border-width: 0;
            overflow: hidden;
            transition:
                opacity 0.2s 0.1s,
                max-height 0.3s,
                border-width 0s 0.3s;
        }
    }
}
</style>