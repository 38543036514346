<script setup>
import { ref, defineEmits } from "vue";
import { useLeadActions } from '@lead';
import { useAuthComponents } from '@auth';

const emit = defineEmits(['close'])

const getLeadResource = useLeadActions('getLeadResource');
const lead = getLeadResource();

const PasswordInput = useAuthComponents('PasswordInput');

const isComplex = ref(false);
const password = ref('');

const apply = () => {
    lead.save(null, {
        password: password.value
    });
    emit('close');
};

</script>

<template>
    <form class="password-popup" v-on:submit.prevent="apply">

        <fieldset>
            <legend>{{ $t('profile.new_password', 'New Password') }}</legend>
            <password-input v-model="password" v-model:complex="isComplex"/>
        </fieldset>

        <g-flex class="btn-bar" justify="end" gap="6">
            <g-button class="cancel-btn" type="button" v-on:click="$emit('performed')">
                {{ $t('base.cancel', 'Cancel') }}
            </g-button>
            <g-button class="save-btn"
                      type="submit"
                      v-bind:disabled="!isComplex">
                {{ $t('base.apply', 'Apply') }}
            </g-button>
        </g-flex>
    </form>
</template>

<style scoped lang="scss">
.password-popup {
    width: 300px;
}

.password-input:deep(.g-input) {
    background-color: #1d212f;
}

.btn-bar {
    padding-top: 20px;
}

.g-button {
    padding: 0 20px;
    min-width: 80px;
}
</style>