<script setup>

import { ref } from "vue";
import { useLeadActions } from '@lead';
import { useFinanceActions } from '@finance';

const getLeadResource = useLeadActions('getLeadResource');
const lead = getLeadResource();

const getWalletResource = useFinanceActions('getWalletResource');
const wallets = getWalletResource({lead_id: [lead.model.id]}, true);

const address = ref();
const currency = ref('BTC');
const currencies = ref([
	{n: 'Bitcoin', v: 'BTC'},
	{n: 'Ethereum', v: 'ETH'},
	{n: 'Tron', v: 'TRX'},
	{n: 'Tether (ERC-20)', v: 'ERC-20'},
	{n: 'Tether (TRC-20)', v: 'TRC-20'},
]);

function addWallet() {
	const params = {
		lead_id: lead.model.id,
		currency: currency.value,
		network: currency.value,
		address: address.value,
		is_active: 1,
	};

	if (currency.value === 'ERC-20') {
		params.currency = 'USDT';
		params.network = 'ETH';
	} else if (currency.value === 'TRC-20') {
		params.currency = 'USDT';
		params.network = 'TRX';
	}

	wallets.create(params);
}

function disactivate(index) {
	wallets.state[index].is_active = !wallets.state[index].is_active;
	wallets.save(wallets.state[index].id, {
		is_active: wallets.state[index].is_active
	});
}

function remove(index) {
	wallets.delete(wallets.state[index].id).then(() => {
		wallets.state.splice(index, 1);
	});
}

</script>

<template>
	<div class="wallets">
		<table>
			<tbody>
			<tr v-for="(wallet, i) in wallets.state" v-bind:key="`wallet-${i}`" v-bind:class="{active: wallet.is_active}">
				<td class="cell">
					<g-flex align="center" gap="5">
						<g-symbol-currency v-bind:name="wallet.currency.toLowerCase()" width="20" height="20"/>
						<span v-if="wallet.currency === 'USDT'">
							{{ wallet.currency }}
							<template v-if="wallet.network === 'Tron'">(TRC-20)</template>
							<template v-else-if="wallet.network === 'Ethereum'">(ERC-20)</template>
						</span>
						<span v-else>{{ wallet.network }}</span>
					</g-flex>
				</td>
				<td class="cell address">
					<g-copy v-bind:text="wallet.address"/>
				</td>
				<td class="cell actions">
					<g-symbol v-bind:name="wallet.is_active ? 'check' : 'close'"
					          class="is-active"
					          width="16"
					          height="16"
					          v-on:click="disactivate(i)"/>
					<g-symbol name="delete"
					          class="delete"
					          width="16"
					          height="16"
					          v-on:click="remove(i)"/>
				</td>
			</tr>
			</tbody>
		</table>

		<g-flex class="controls" align="end" gap="10">
            <fieldset class="currency">
                <legend>Currency</legend>
                <g-select v-model="currency"
                          v-bind:options="currencies"
                          option-text="n"
                          option-value="v"/>
            </fieldset>

            <fieldset class="address">
                <legend>Address</legend>
                <g-input v-model="address" v-on:keypress.enter="addWallet"/>
            </fieldset>

			<g-button class="add-btn" v-on:click="addWallet">
				<g-symbol name="import" width="22" height="22"/>
			</g-button>
		</g-flex>
	</div>
</template>

<style scoped lang="scss">
.wallets {
    padding: 20px 50px 20px 60px;
	background-color: var(--env-bg, $env-bg);
    //color: var(--env-text-color, $env-text-color);
}

table {
	width: 100%;
	font-size: 0.9rem;
	white-space: nowrap;
    color: white;
    overflow: hidden;
    background-color: var(--primary, $primary);
    border-radius: var(--bar-rounding, $bar-rounding);
}

tr {
	&:nth-child(odd) {
        background-color: var(--primary-hover, $primary-hover);
	}

	.g-symbol {
		margin: 0 4px;

		&.delete {
			fill: var(--env-text-color, $env-text-color);

			&:hover {
				fill: var(--env-title-color, $env-title-color);
			}
		}
	}

	&.active > .actions > .is-active {
		fill: var(--success, $success);

		&:hover {
			fill: var(--success-hover, $success-hover);
		}
	}

	&:not(.active) > .actions > .is-active {
		fill: var(--danger, $danger);

		&:hover {
			fill: var(--danger-hover, $danger-hover);
		}
	}
}

td {
	padding: 10px 8px;

	&.address {
		width: 100%;
	}

	&.actions {
		text-align: center;

		& > .g-symbol {
			cursor: pointer;
		}
	}
}

.controls {
	margin-top: 15px;
    color: var(--env-text-color, $env-text-color);

	.address {
		flex-grow: 1;
	}

	.currency {
		width: 160px;

        & > .g-select {
            color: var(--env-text-color, $env-text-color);
        }
	}

	.add-btn {
		color: white;
		padding: 0 15px;
		background-color: var(--save-btn-bg, $save-btn-bg);

		svg {
			fill: white;
		}
	}
}

</style>