<template>
    <div class="leads environment">

        <g-preloader-overlay v-if="leads.isLoading"/>

        <g-tabs v-if="profile.can('Lead ArchiveView')"
                v-model="tab"
                v-bind:tab-list="tabList"
                v-on:click="$router.push({ name: 'Archive', params: { locale: $i18n.locale } })"/>

        <transition>
            <mass-update v-if="targets.length"
                         v-bind:targets="targets"
                         v-on:updated="reloadLeads"
                         v-on:drop="targets = []"/>
        </transition>

        <div class="index">

            <div class="row header">
                <div class="title cell checkbox check-all">
                    <g-symbol name="check" width="18" height="18" v-on:click="checkAll"/>
                </div>

                <div class="title cell id sortable" v-bind:class="{ [`sorted ${leads.sortDir}`]: leads.sortBy === 'id'}">
                    <g-flex align="center" justify="center" gap="2" v-on:click="sortBy('id')">
                        ID
                        <g-symbol name="arrow-down" width="14" height="14"/>
                    </g-flex>
                </div>

                <div class="title cell">
                    <div class="phone">{{ $t('lead.phone', 'Phone') }}</div>
                </div>

                <div v-for="item in columns"
                     v-bind:key="item.key"
                     class="title cell"
                     v-bind:class="[item.key, { sortable: item.sortable, [`sorted ${leads.sortDir}`]: leads.sortBy === item.key }]">
                    <g-flex v-if="item.sortable"
                            align="center"
                            justify="center"
                            gap="2"
                            v-bind:class="item.key"
                            v-on:click="sortBy(item.key)">
                        {{ $t(`lead.${item.name}`, item.name) }}
                        <g-symbol name="arrow-down" width="14" height="14"/>
                    </g-flex>
                    <div v-else v-bind:class="item.key">{{ $t(`lead.${item.name}`) }}</div>
                </div>
            </div>

            <div class="row"
                 v-for="(lead, index) in leads.state"
                 v-bind:key="`lead-${lead.id}`"
                 v-on:click="openLead(lead.id)"
                 v-bind:class="[{checked: targets.includes(lead.id.toString())}, {'open': $route.params.id === lead.id.toString()}]"
                 v-show="!lead.mixed">
                <label class="cell checkbox">
                    <g-flex v-if="profile.can('Lead LabelView')" class="bookmark" direction="column">
                        <span v-if="!!lead.label" v-for="bookmark in lead.label.split(',')"
                              v-bind:key="`${bookmark}-${lead.id}`"
                              v-bind:class="bookmark"></span>
                    </g-flex>
                    <g-checkbox v-model="targets"
                                v-bind:value="lead.id.toString()"
                                v-on:click.stop="handleCheckboxClick($event, lead, index)"
                                class="white"/>
                </label>

                <div class="cell id" v-bind:class="{ [`sorted ${leads.sortDir}`]: leads.sortBy === 'id' }">
                    {{ lead.id }}
                </div>

                <div class="cell">
                    <g-copy v-if="profile.can('Lead ContactsView')" class="phone" v-bind:text="lead.phone"/>
                    <template v-else>
                        {{ lead.phone.replace(/^\+?(\d{3})(\d+)(\d{3})$/, (match, p1, p2, p3) => `+${p1}${p2.replace(/./g, '*')}${p3}`) }}
                    </template>
                </div>

                <div v-for="column in columns"
                     v-bind:key="column.key"
                     class="cell"
                     v-bind:class="[column.key, { [`sorted ${leads.sortDir}`]: leads.sortBy === column.key}]">

                    <fieldset v-if="column.key === 'commentDate' && !!lead[column.name]">
                        <legend>{{ lead[column.name].created_at }}</legend>
                        <div>{{ lead[column.name].body }}</div>
                    </fieldset>
                    <template v-else-if="column.key === 'campaigns' && !!lead[column.name]">
                        {{
                            profile.can('Lead RealCampaignView')
                                ? [...new Set(lead[column.name].map(item => item.name))].join(', ')
                                : [...new Set(lead[column.name].map(item => item.alias))].join(', ')
                        }}
                    </template>
                    <template v-else>
                        {{
                            Array.isArray(lead[column.name])
                                ? [...new Set(lead[column.name].map(item => item.name))].join(', ')
                                : lead[column.name]
                        }}
                    </template>

                </div>
            </div>
            <div class="nothing-found" v-if="!leads.state.length && !leads.isLoading">{{
                    $t('base.nothing_found', 'Nothing found')
                }}
            </div>
        </div>

        <g-flex v-if="leads.meta.total > 25" class="pagination" align="stretch" justify="end" gap="2">
            <g-pagination v-model="leads.meta.current_page"
                          v-on:change="paginate"
                          v-bind:per-page="leads.meta.per_page"
                          v-bind:total="leads.meta.total"/>

            <div class="per-page">
                <g-select v-model="perPage"
                          v-bind::label="$t('base.per_page')"
                          v-bind:options="[{value: 25}, {value: 50}, {value: 75}, {value: 100}]"
                          v-on:change="changePerPage"
                          option-value="value"
                          option-text="value"/>
            </div>
        </g-flex>
    </div>
</template>

<script>

import {computed, ref, onMounted, watch, onUnmounted, reactive} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useLeadActions, useLeadComponents } from '@lead';
import { useProfileActions } from '@profile';

import {i18n, STATE} from '@/Ship';

const {
    MassUpdate,
    DateFilter
} = useLeadComponents();

const {
    getFilterset,
    getLeadResource
} = useLeadActions();
const getProfileResource = useProfileActions('getProfileResource');

export default {
    name: 'Environment',
    computed: {
        STATE() {
            return STATE;
        }
    },
    components: {
        DateFilter,
        MassUpdate,
    },

    setup() {
        const router = useRouter();
        const route = useRoute();

        const profile = getProfileResource();
        const leads = getLeadResource();

        const targets = ref([]);
        let lastSelectedIndex = null;

        const perPage = ref(profile.state.lead.perPage || 25);

        function openLead(id) {
            //leads.index = leads.state.findIndex(item => item.id === id);
            //if (leads.index > -1) {
                leads.show(id, { include: 'campaigns,customer' }).then(() => {
                    leads.index = leads.state.findIndex(item => item.id === id);
                });
                router.push({ name: 'Lead', params: { ...route.params, id } });
            //} else {
            //    leads.index = null;
            //    router.push({ name: 'Leads', params: route.params });
            //}
        }

        function changePerPage(event) {
            leads.meta.per_page = perPage.value;
            leads.setPerPage(perPage.value);
            leads.setPage(1);
            leads.load(null, {}, 'LeadEnv PerPage');

            profile.state.lead.perPage = perPage.value;
            profile.saveState();
        }

        function reloadLeads() {
            leads.load(null, {}, 'LeadEnv Refresh');
            targets.value = [];
        }

        function sortBy(field) {
            if (leads.sortBy === field) {
                profile.state.lead.sortDir = leads.sortDir === 'asc'
                    ? 'desc'
                    : 'asc';
            }
            profile.state.lead.sortBy = field;
            profile.saveState();

            leads.sort(
                profile.state.lead.sortBy,
                profile.state.lead.sortDir
            );
            leads.load(null, {}, 'LeadEnv Resort');
        }

        const userState = computed(() => {
            return profile.state.lead || {};
        });

        const columns = computed(() => {
            const columns = leads.columns.filter(column => !column.allow || profile.permissions.includes(column.allow));

            return profile.state.lead.columns.length
                ? columns.filter(column => userState.value.columns.includes(column.name))
                : columns;
        });

        watch(() => leads.state, () => {
            const objectIds = leads.state.map((obj) => obj.id);
            targets.value = targets.value.filter((target) => objectIds.includes(parseInt(target)));
        });

        onMounted(() => {
            document.addEventListener('keydown', handleKeyPress);
            leads.sort(
                profile.state.lead.sortBy,
                profile.state.lead.sortDir
            );
            leads.setPerPage(profile.state.lead.perPage);
            leads.setFilters(profile.state.lead.filters);
            leads.setIncludes(['brand', 'affiliate', 'sale', 'retention', 'country', 'campaigns', 'activity']);

            leads.onload.push(() => {
                if (profile.state.lead.expanded.length) {
                    getFilterset(profile.state.lead.expanded);
                }
            });

            if (route.params.id) {
                leads.onload.push(() => {
                    openLead(Number(route.params.id));
                });
            }

            reloadLeads();
        });

        onUnmounted(() => {
            document.removeEventListener('keydown', handleKeyPress);
        });

        function handleKeyPress(event) {
            if (event.key === 'ArrowUp') {
                navigateLead(-1);
            } else if (event.key === 'ArrowDown') {
                navigateLead(1);
            }
        }

        function navigateLead(direction) {
            const currentIndex = leads.state.findIndex(lead => lead.id.toString() === route.params.id);

            if (currentIndex !== -1) {
                const newIndex = (currentIndex + direction + leads.state.length) % leads.state.length;
                const newLeadId = leads.state[newIndex].id;

                openLead(newLeadId);
            }
        }

        function checkAll() {
            if (targets.value.length < leads.state.length) {
                leads.state.forEach(lead => {
                    targets.value.push(lead.id.toString());
                });
            } else {
                targets.value = [];
            }
        }

        const handleCheckboxClick = (event, lead, index) => {
            if (event.shiftKey && lastSelectedIndex !== null) {
                const startIndex = Math.min(lastSelectedIndex, index);
                const endIndex = Math.max(lastSelectedIndex, index);

                const newTargets = leads.state
                .slice(startIndex, endIndex + 1)
                .map(lead => lead.id.toString());

                targets.value = [...new Set([...targets.value, ...newTargets])];
            } else {
                const leadId = lead.id.toString();
                if (!targets.value.includes(leadId)) {
                    targets.value.push(leadId);
                } else {
                    const targetIndex = targets.value.indexOf(leadId);
                    targets.value.splice(targetIndex, 1);
                }

                lastSelectedIndex = index;
            }
        };

        function paginate(page) {
            leads.setPage(page);
            leads.load(null, {}, 'LeadEnv Paginate');
        }

        const tab = ref('leads');
        const tabList = reactive({
            leads: i18n.global.t(`parts.leads`),
            archive: i18n.global.t(`parts.archive`),
        });

        return {
            targets,
            leads,
            columns,
            profile,
            perPage,
            tab,
            tabList,
            openLead,
            changePerPage,
            reloadLeads,
            sortBy,
            checkAll,
            handleCheckboxClick,
            paginate
        };
    }
};
</script>

<style lang="scss" scoped>
.environment {
    margin: 0 6px;
    padding: 8px 20px;
    position: relative;
    border-radius: 12px;
    font-size: 0.9rem;
    border: 3px solid var(--secondary, $secondary);
    background-color: var(--env-bg, $env-bg);
}

.index {
    min-width: 100%;
    padding: 0 10px;
    margin-bottom: 20px;
    color: var(--env-text-color, $env-text-color);
}

.row {
    &:nth-child(even) {
        background-color: #00000011;
    }

    &:not(.header) {
        cursor: pointer;

        &.checked {
            & > .cell {
                color: black;
                background-color: var(--success-hover, $success-hover);
            }

            &.open > .cell {
                background-color: var(--success, $success);
            }

            & > .cell.sortable {
                background-color: var(--success, $success);
            }
        }

        &:hover > .cell {
            color: white;
            background-color: var(--secondary-hover, $secondary-hover);
        }

        &.open > .cell {
            color: white;
            background-color: var(--secondary, $secondary);
        }
    }
}

.cell {
    padding: 5px;
    white-space: nowrap;
    position: relative;

    &.title {
        &.sortable {
            .g-symbol {
                cursor: pointer;
                fill: var(--env-text-color, $env-text-color);
            }

            &.sorted {
                font-weight: bold;
                fill: var(--sorted-text-color, $sorted-text-color);

                &.desc {
                    .g-symbol {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    &.sorted {
        color: var(--sorted-text-color, $sorted-text-color);
        background-color: var(--sorted-bg, $sorted-bg);
    }

    &.checkbox {
        font-size: 0;
        position: relative;
        text-align: right;
        fill: var(--env-text-color, $env-text-color);

        & > .g-checkbox {
            &:deep(.g-symbol) {
                z-index: 2;
            }

            &:deep(input):not(:checked) + .g-symbol {
                opacity: 0.8;
                background-color: var(--body-bg, $body-bg);
            }
        }

        &:hover {
            fill: var(--secondary-hover, $secondary-hover);
        }
    }

    &.check-all,
    &.id,
    &.is_ftd,
    &.is_test,
    &.email_confirmed,
    &.phone_confirmed,
    &.phone,
    &.language,
    &.created_at {
        padding: 10px 8px;
        text-align: center;
    }

    &:not(.title) {
        &.commentDate {
            width: 250px;
            height: 48px;
            padding: 2px 0 0;

            & > fieldset {
                padding: 0;
                pointer-events: none;

                & > legend {
                    margin-left: 6px;
                    padding: 0 2px;
                    font-size: 0.9rem;
                    color: var(--secondary-hover, $secondary-hover);
                }

                & > div {
                    width: 250px;
                    padding: 0 8px 4px 8px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            &:hover > fieldset {
                position: absolute;
                z-index: 2;
                bottom: 0;
                padding-top: 18px;
                font-size: 1rem;
                border: none;
                border-radius: 5px;
                background-color: var(--secondary, $secondary);

                & > legend {
                    top: 16px;
                    position: relative;
                    color: var(--tooltip-text-color, $tooltip-text-color);
                }

                & > div {
                    color: white;
                    white-space: normal;
                }
            }
        }
    }
}

.row:nth-child(-n+6) .cell:not(.title).commentDate:hover > fieldset {
    top: 0;
    bottom: auto;
}

.check-all {
    cursor: pointer;
    fill: var(--main-text-color, $main-text-color);

    &:hover {
        fill: var(--title-color, $title-color);
    }
}

.nothing-found {
    font-size: 1.5rem;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
}

.bookmark {
    position: absolute;
    z-index: 2;
    top: 8%;
    left: 0;
    height: 86%;
    overflow: hidden;
    border-radius: 5px;
    width: 100%;

    & > span {
        flex-grow: 1;
        height: 100%;

        &.silver {
            background-color: #e0e5ec;
        }

        &.gold {
            background-color: #ffef40;
        }

        &.green {
            background-color: #52e595;
        }

        &.cyan {
            background-color: #50f5ff;
        }

        &.red {
            background-color: #e04354;
        }

        &.purple {
            background-color: #d030ff;
        }
    }
}

.g-tabs {
    position: sticky;
    left: 0;
    width: 100%;
    padding: 10px;
    justify-content: flex-end !important;
}

.pagination {
    position: sticky;
    width: 100%;
    z-index: 3;
    left: 0;
    bottom: 5px;

    & > .g-pagination {
        width: min-content;
        padding: 10px 12px;
        border-radius: 10px 0 0 10px;
        box-shadow: 0 5px 20px -5px #000000aa;
        background-color: var(--body-bg, $body-bg);
        border: 1px solid var(--separator-color, $separator-color);
    }

    & > .per-page {
        padding: 6px 0 0 10px;
        border-radius: 0 10px 10px 0;
        background-color: var(--body-bg, $body-bg);
        border: 1px solid var(--separator-color, $separator-color);

        & > .g-select:deep(.field) {
            width: 80px;
            border-color: transparent;
        }
    }
}

@media (min-width: 1025px) {
    .index {
        display: table;

        .row {
            display: table-row;

            & > .cell {
                display: table-cell;
                vertical-align: middle;
            }
        }
    }
}

@media (max-width: 1024px) {
    .index {
        display: table;

        .row {
            display: table-row;

            & > .cell {
                padding: 8px 5px;
                display: table-cell;
                vertical-align: middle;
            }
        }
    }
}

@media (max-width: 984px) {
    .pagination-float-left {
        transform: translate(295px, 0);
    }
}

.v-enter-active,
.v-leave-active {
    overflow: hidden;
    transition: max-height 0.5s;
}

.v-enter-from,
.v-leave-to {
    max-height: 0;
    overflow: hidden;
}
</style>