<template>
  <div class="info">
    <form class="profile-form" @submit.prevent>
      <g-field
        type="text"
        label="Name"
        v-model="profile.name"
        @keyup="verifyName"
        :error="nameError"
      />

      <g-field
        type="email"
        label="Email"
        v-model="profile.email"
        @keyup="verifyEmail"
        :errors="emailError"
      />

      <legend>Password</legend>
      <password-input
        v-model="profile.password"
        v-bind:complex="isComplex"
        label="Password"
      />

      <g-field label="Roles" v-model="roles" readonly>
        <g-symbol name="lock" width="18" height="18" />
      </g-field>

      <g-field label="Teams" v-model="teams" readonly>
        <g-symbol name="lock" width="18" height="18" />
      </g-field>

      <div class="footer">
        <g-button class="g-button save-btn" @click="updateProfile">
          {{ $t("base.save") }}
        </g-button>
      </div>
    </form>
  </div>
</template>

<script>
import { computed, ref, onMounted, reactive } from "vue";
import {
  STACK,
  ApiClient,
  STATE,
  PopUpManager as PopUp,
  verifyField,
} from "@/Ship";
import { useProfileResource, useProfileActions } from "@profile";
import { useProfileComponents } from "@profile";
import { useAuthComponents } from "@auth";

export default {
  name: "Info",
  components: {
    PasswordInput: useAuthComponents("PasswordInput"),
    Documents: useProfileComponents("Documents"),
  },

  setup: function () {
    const profile = useProfileResource("Profile");

    let timeout = null;
    const oldPassword = ref("");
    const showOldPassword = ref(false);
    const isComplex = ref(false);
    const nameError = ref("");
    const emailError = ref("");
    const passwordError = ref("");
    const countries = ref([]);
    const verifyName = () => {
      return verifyField(profile.name, ["require", "minLength:2"], nameError);
    };

    const teams = computed(() => {
      return profile.teams
        .map(function (team) {
          return " " + team["name"];
        })
        .toString();
    });

    const roles = computed(() => {
      return profile.roles
        .map(function (role) {
          return " " + role["name"];
        })
        .toString();
    });

    const verifyEmail = () => {
      return verifyField(profile.email, ["require", "email"], emailError);
    };

    const verifyPassword = () => {
      return verifyField(
        profile.password,
        ["require", "minLength:8"],
        passwordError
      );
    };

    const isAllFieldVerified = computed(() => {
      return verifyName() && verifyEmail() && verifyPassword();
    });

    const updateProfile = () => {
      if (profile.isValidationPassed() && isAllFieldVerified) {
        profile.save();
        profile.clearEntity();
      }
    };

    const isShowTooltip = ref([]);

    onMounted(() => {
      STACK.push(() => ApiClient.get("/countries")).then((data) => {
        countries.value = data;
      });
    });

    return {
      countries,
      showOldPassword,
      oldPassword,
      teams,
      roles,
      PopUp,
      profile,
      isShowTooltip,
      updateProfile,
      isComplex,
      verifyName,
      verifyEmail,
      verifyPassword,
      isAllFieldVerified,
      nameError,
      emailError,
    };
  },
};
</script>

<style lang="scss" scoped>
.info {
  display: grid;
  grid-template-columns: 500px auto;
  gap: 20px;
}

.profile-form {
  .g-field,
  .password-input,
  .g-phone-input {
    padding-top: 6px;
    margin-top: 8px;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;
  }
}

.password-set {
  margin-top: 25px;
  border-top: 1px solid var(--field-border, $field-border);
}

.confirm-btn {
  padding: 0 20px;
  background-color: var(--primary, $primary);
}
</style>
