<template>
    <div class="lang-container" v-bind:class="{active: show}">
        <router-link v-for="code in locales"
                     v-bind:key="code"
                     class="lang"
                     v-bind:class="{active: code === locale}"
                     v-bind:to="{ params:{...$route.params, locale: code} }">
            {{ code }}
        </router-link>
    </div>
</template>

<script>

import { useI18n } from 'vue-i18n';
import {
    ref,
    watch,
} from 'vue';
import { useRouter, useRoute } from 'vue-router';

export default {
    name: 'GLangSelect',
    emits: ['selectedLang'],
    props: {
        langList: {
            required: true,
            type: Array,
        }
    },
    setup () {
        const {
            locale,
            fallbackLocale: locales
        } = useI18n();

        const language = ref(locale.value);
        const show = ref(false);
        const toTop = ref(false);
        const datalist = ref(null);
        const route = useRoute();
        const router = useRouter();

        const showList = () => {
            const { y } = datalist.value.getBoundingClientRect();
            toTop.value = (y + 200) > screen.availHeight;
            show.value = true;
        };
        const hideList = () => {
            show.value = false;
        };

        watch(language, () => {
            setTimeout(() => {
                router.push({
                    name: route.name,
                    params: {
                        locale: language.value
                    }
                });
            }, 200);
        });

        return {
            show,
            toTop,
            datalist,
            language,
            locale,
            locales,
            showList,
            hideList,
        };
    }
};
</script>

<style lang="scss" scoped>
.lang-container {
    white-space: nowrap;

    & > .lang {
        display: inline-block;
        vertical-align: top;
        padding: 3px 8px;
        font-size: 0.9rem;
        text-transform: uppercase;
        color: var(--topbar-text-color, white);
        border: 1px solid var(--env-bg, $env-bg);

        &:first-child {
            border-radius: 6px 0 0 6px;
            border-right-width: 0;
        }

        &:last-child {
            border-radius: 0 6px 6px 0;
            border-left-width: 0;
        }

        &.active {
            font-weight: bold;
            background-color: var(--primary, $primary);
            border: 1px solid var(--primary, $primary);
        }

        &:not(.active) {
            font-weight: bold;
            color: var(--main-text-color, $main-text-color);
            background-color: transparent;
            border: 1px solid var(--separator-color, $separator-color);
        }
    }
}
</style>
