import { useAssetResources } from '@asset';

export default () => {
    const pair = useAssetResources('Pair');
    if (!pair.isLoaded && !pair.isLoading) {
        //pair.setIncludes([]);
        pair.load();
    }

    return pair;
};
