import { useNotificationResources } from '@notification';

export default () => {
    const notification = useNotificationResources('Notification');
    if (!notification.isLoaded && !notification.isLoading) {
        //notification.setIncludes([]);
        notification.load();
    }

    return notification;
};
