<script setup>
import { reactive, ref, defineEmits } from 'vue';
import * as XLSX from 'xlsx';
import { useLeadActions } from '@lead';

const leadCheck = useLeadActions('leadCheck');

const emit = defineEmits(['performed']);

const selectedFile = ref(null);
const isDragOver = ref(false);
const fileInputRef = ref(null);

const payload = reactive({
    excelData: null,
});

const handleFileChange = () => {
    selectedFile.value = fileInputRef.value.files[0];
    const validExtensions = ['.xlsx', '.xls', '.csv'];
    const fileExtension = selectedFile.value.name.substring(
        selectedFile.value.name.lastIndexOf('.')
    ).toLowerCase();
    if (!validExtensions.includes(fileExtension)) {
        alert('Please select a valid .xlsx or .xls file.');
        selectedFile.value = null;
    }
};

const handleDragOver = (event) => {
    event.preventDefault();
    isDragOver.value = true;
};

const handleDragLeave = () => {
    isDragOver.value = false;
};

const handleDrop = (event) => {
    event.preventDefault();
    isDragOver.value = false;
    selectedFile.value = event.dataTransfer.files[0];
};

const isLoaded = ref(false);
const isLoading = ref(false);
const results = ref([]);
const uploadFile = () => {
    if (!selectedFile.value) {
        alert('Please select a file.');
        return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheet = workbook.Sheets[workbook.SheetNames[0]];
        payload.excelData = [];

        XLSX.utils.sheet_to_json(sheet, { header: 1 }).forEach(row => {
            const email = row[0];
            const rowData = { email };
            payload.excelData.push(rowData);
        });

        isLoading.value = true;
        isLoaded.value = false;

        results.value = [];

        sendLeads(payload, () => {
            isLoading.value = false;
            isLoaded.value = true;
        }).then(() => {
            downloadFile();
        }).then(() => {
            emit('performed');
        });
    };

    function sendLeads(payload, finallyAction) {
        const emailsArray = payload.excelData.map(obj => obj.email);
        return leadCheck({ excelData: emailsArray }).then(response => {
            results.value.unshift(response);
            selectedFile.value = null;
        }).catch(error => {
            results.value.unshift({
                error: error.message
            });
        }).finally(finallyAction);
    }

    reader.readAsBinaryString(selectedFile.value);
};

const downloadFile = () => {
    const wb = XLSX.utils.book_new();
    const duplicateLeadsData = results.value[0].duplicate_leads.map(email => [email]);
    const wsData = [];

    duplicateLeadsData.forEach(emailRow => {
        wsData.push([emailRow[0]]);
    });

    const ws = XLSX.utils.aoa_to_sheet(wsData);
    XLSX.utils.book_append_sheet(wb, ws, 'Duplicates');

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wbout], { type: 'application/octet-stream' });

    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Duplicates.xlsx');

    document.body.appendChild(link);
    link.click();

    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
};


</script>

<template>
    <form class="leads-import" v-on:submit.prevent="uploadFile">
        <label v-if="!isLoaded && !isLoading"
               class="dropzone"
               v-bind:class="{ 'dropzone-over': isDragOver }"
               v-on:dragover="handleDragOver"
               v-on:dragleave="handleDragLeave"
               v-on:drop="handleDrop">

            <input type="file" ref="fileInputRef" v-on:change="handleFileChange" hidden/>

            <template v-if="!selectedFile">
                <g-symbol class="icon" name="import" width="56" height="56" v-on:change="handleFileChange"/>
                [csv, xlsx, xls]
            </template>

            <g-caption v-else size="3" class="filename">{{ selectedFile.name }}</g-caption>
        </label>

        <g-flex justify="end" gap="5">
            <g-button class="cancel-btn" v-on:click="$emit('performed')" v-bind:disabled="isLoading">
                {{ $t('base.cancel', 'Cancel') }}
            </g-button>
            <g-button class="save-btn" type="submit" v-bind:disabled="isLoading">
                {{ $t('base.upload', 'Upload') }}
            </g-button>
        </g-flex>
    </form>
</template>

<style scoped lang="scss">
.leads-import {
    width: 480px;

    & > .results,
    & > .dropzone {
        margin: 20px 0;
        padding: 20px;
        width: 100%;
        height: 280px;
        position: relative;
        border-radius: 5px;
        background-color: #1d212f;
    }

    & > .dropzone {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 2px dashed var(--main-text-color, $main-text-color);
        transition: border-color 0.3s;

        &-over {
            border-color: var(--primary-hover, $primary-hover);
        }
    }

    & > .results {
        overflow: auto;
        background-color: var(--field-active-bg, $field-active-bg);
        border: 1px solid var(--separator-color, $separator-color);

        & > .result {
            margin: 15px 0;
            padding: 15px 0;
            border-bottom: 1px solid var(--separator-color, $separator-color);
        }

        .record {
            margin-top: 20px;

            & > .danger,
            & > .warning {
                margin-top: 2px;
                font-family: $font-title;
                letter-spacing: 0.8px;
            }
        }
    }

    .icon {
        font-size: 20px;
        margin-right: 5px;
        fill: var(--main-text-color, $main-text-color);
    }

    .filename {
        max-width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .g-button {
        min-width: 100px;
    }
}
</style>
