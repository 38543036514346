import { useTradingResources } from '@trading';

export default (filters, reload = false, referer = null) => {
    const history = useTradingResources('PositionHistory');
    if (reload || (!history.isLoaded && !history.isLoading)) {
        history.setFilters(filters);
        history.setIncludes(['pair', 'position']);
        history.load(null, {}, referer);
    }

    return history;
};
