import { usePermissionResources } from '@permission';

export default () => {
    const role = usePermissionResources('Role');
    if (!role.isLoaded && !role.isLoading) {
        //role.setIncludes([]);
        role.load();
    }

    return role;
};
