<script setup>

import { defineEmits, defineProps } from 'vue';
import { useArchiveActions } from '@archive';

const { restoring } = useArchiveActions();

const props = defineProps({
    targets: {
        type: Array,
        default: () => [],
    }
});

const emit = defineEmits(['updated', 'drop']);

function restoreLeads() {
    restoring.massRestore(props.targets).then(() => {
        emit('updated');
    });
}
</script>

<template>
    <form class="mass-update" v-on:submit.prevent="restoreLeads">
        <g-caption size="4">
            Mass Restore <span class="selected-count"> ( Selected leads: {{ targets.length }} )</span>
        </g-caption>

        <g-symbol class="close-btn" name="close" width="20" height="20" v-on:click="$emit('drop')"/>

        <div class="container">
            <g-button type="submit">
                <g-symbol name="restore" width="20" height="20"/>
                {{ $t('base.restore', 'Restore') }}
            </g-button>
        </div>
    </form>
</template>

<style lang="scss" scoped>
.mass-update {
    z-index: 3;
    top: -8px;
    margin: 0 -20px;
    position: sticky;
    border-radius: 0 0 10px 10px;
    background-color: var(--env-bg, $env-bg);
    color: var(--env-text-volor, $env-text-color);
    box-shadow: 0 10px 15px -5px #00000044;
    container-type: inline-size;
    container-name: form;
}

.g-caption {
    padding-left: 40px;
}

.container {
    padding: 10px 40px 20px;
}

.g-button {
    min-width: 128px;
    padding: 0 20px;
    color: black;
    gap: 5px;
    display: flex;
    align-items: center;
    border: 1px solid var(--success, $success);

    &:hover {
        fill: white;
        color: white;
        background-color: var(--success, $success);
        border-color: var(--success, $success);
    }
}

.close-btn {
    top: 10px;
    right: 20px;
    position: absolute;
    cursor: pointer;
    fill: var(--env-text-color, $env-text-color);
}
</style>
