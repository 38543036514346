import { useSettingsResources } from '@settings';

export default (section = null, reload = false) => {
	const settings = useSettingsResources('Settings');

	if (section) {
		if (reload || !(settings.model[section] || []).length) {
			settings.load(section);
		}
	} else if (reload || (!settings.isLoaded && !settings.isLoading)) {
		settings.load();
	}

	return settings;
};
