<script setup>

import { ref, defineEmits, defineProps } from 'vue';

const props = defineProps({
    part: { type: String },
});
const emit = defineEmits(['update:part']);

const tab = ref('Replenishments');

function choosePart() {
    emit('update:part', tab.value);
}
</script>

<template>
    <div class="indexbar">
        <g-flex justify="between">
            <g-tabs v-model="tab" v-on:change="choosePart" v-bind:tab-list="{ Replenishments: $t('finances.replenishments', 'Replenishments'), Withdrawals: $t('finances.withdrawals', 'Withdrawals')}"/>
        </g-flex>
    </div>
</template>

<style scoped lang="scss">

</style>