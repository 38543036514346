import { useFinanceResources } from '@finance';

export default (filters, reload = false) => {
    const wallet = useFinanceResources('Wallet');

    if (reload || (!wallet.isLoaded && !wallet.isLoading)) {

        //wallet.setIncludes();
        wallet.setFilters(filters)
        //wallet.sort('id', 'desc');

        wallet.load();
    }

    return wallet;
};

