<script setup>

import { defineProps, defineEmits } from 'vue';
import { useFinanceResources } from '@finance';
import { useProfileActions } from '@profile';
import { i18n, PopUpManager as PopUp } from '@/Ship';

const props = defineProps(['total']);
const emit = defineEmits(['update:total']);

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const deposits = useFinanceResources('Deposit');
deposits.setIncludes(['user','lead']);
deposits.sort('id', 'desc');
//deposits.setFilters(filters)

deposits.onload.push(() => {
    emit('update:total', {
        count: deposits.state.length,
        sum: Math.round(deposits.state.reduce((a, b) => a + Number(b.amount), 0))
    });
});

deposits.load(null, {}, 'Replenishments Component');

const statuses = [
    { slug: 'new', name: 'New' },
    { slug: 'suspend', name: 'Suspend' },
    { slug: 'processed', name: 'Processed' },
    { slug: 'refund', name: 'Refund' }
];

function changeStatus(index, value) {
    const previousValue = deposits.state[index].status;
    deposits.save(deposits.state[index].id, {
        status: value
    });
}

function removeDeposit(id) {
    PopUp.open(require('@finance/components/PopUps/RemoveDeposit'))
        .setCaption(i18n.global.t('finances.remove_replenishment', 'Remove replenishment'))
        .setPayload({payload: {id}});
}

</script>

<template>
    <div class="deposits">
        <div v-for="(deposit, i) in deposits.state"
             v-bind:key="`deposit-${deposit.id}`"
             class="row deposit"
             v-bind:class="[deposit.type]"
             v-bind:data-type="deposit.type || 'Deposit'">

            <!-- type -->
            <div class="cell type">
                <template v-if="deposit.is_ftd === 'Yes'">
                    <span class="test">{{ deposit.is_test === 'Yes' ? 'Test' : '' }}</span>
                    {{ deposit.type === 'deposit' ? 'FTD' : $t(`finances.${deposit.type}`) }}
                </template>
                <template v-else>
                    <span class="test">{{ deposit.is_test === 'Yes' ? 'Test' : '' }}</span>
                    {{ deposit.type === 'deposit' ? $t('finances.replenishment') : $t(`finances.${deposit.type}`) }}
                </template>
            </div>
            <!--/ TYPE -->

            <!-- LEAD -->
            <div class="cell lead">
                <div class="title">Lead: {{ deposit.lead_id }}</div>
                <router-link v-if="!!deposit.lead" class="lead-link" v-bind:to="{ name: 'Lead', params: {id: deposit.lead_id} }">
                    {{ deposit.lead.first_name }} {{ deposit.lead.last_name }}
                </router-link>
                <span v-else class="danger">Archived lead</span>
            </div>
            <!--/ LEAD -->

            <!-- AMOUNT -->
            <div class="cell amount">
                <template v-if="deposit.currency !== 'USDT'">
                    <div class="title">Amount:</div>
                    {{ Number((deposit.amount / deposit.rate).toFixed(5)) }}
                    <small class="currency" v-bind:class="deposit.currency.toLowerCase()">{{ deposit.currency }}</small>
                    <small v-if="deposit.rate !== 1" class="rate"> ✕ {{ deposit.rate }}</small>
                    =
                </template>
                {{ Number(deposit.amount).toFixed(2) }} <small class="currency usdt">USDT</small>
            </div>
            <!--/ AMOUNT -->

            <!-- ADDRESS -->
            <div class="cell address">
                <div class="title">Address:</div>
                <g-copy v-if="deposit.address" class="value" v-bind:text="deposit.address"></g-copy>
            </div>
            <!--/ ADDRESS -->

            <!-- AGENT -->
            <div class="cell recipient">
                <div class="title">Agent:</div>
                {{ deposit.user?.name || '' }}
            </div>
            <!--/ AGENT -->

            <!-- METHOD -->
            <div class="cell method">
                <div class="title">Method:</div>
                {{ deposit.method }} / {{ deposit.sub_method }}
            </div>
            <!--/ METHOD -->

            <!-- STATUS -->
            <div class="cell status" v-bind:class="deposit.status">
                <g-select v-if="profile.can('Deposit Edit')"
                          v-model="deposit.status"
                          label="Status"
                          option-text="name"
                          option-value="slug"
                          v-bind:options="statuses"
                          v-on:select="changeStatus(i, $event)"
                          v-bind::disabled="deposit.status === 'refund'"/>
                <template v-else>
                    <div class="title">Status:</div>
                    <div class="value">{{ deposit.status }}</div>
                </template>
            </div>
            <!--/ STATUS -->

            <!-- DATE -->
            <div class="cell date">
                <div class="title">Date:</div>
                {{ deposit.created_at }}
            </div>
            <!--/ DATE -->

            <div class="cell actions">
                <!--
                <g-button class="actions remove-btn with-tooltip"
                          v-bind:data-tooltip="$t('finances.is_forwarded', 'Is Forwarded')">
                    <g-symbol name="check" width="18" height="18"/>
                </g-button>
                -->
                <g-button class="actions remove-btn with-tooltip"
                          v-bind:data-tooltip="$t('finances.remove_replenishment', 'Remove replenishment')"
                          v-on:click="removeDeposit(deposit.id)">
                    <g-symbol name="delete" width="18" height="18"/>
                </g-button>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.deposits {
    padding: 18px;
    display: table;
    min-width: 100%;

    & > .row {
        font-size: 0.9rem;
        padding: 4px;
        display: table-row;
        white-space: nowrap;

        &:nth-child(even) {
            background-color: #00000011;
        }

        & > .cell {
            padding: 4px 10px;
            display: table-cell;
            vertical-align: middle;

            &.type {
                font-size: 1.1rem;
                text-transform: capitalize;
            }

            &.address {
                max-width: 180px;
            }

            &.amount {
                white-space: nowrap;
            }

            &.date {
                width: 140px;
            }

            &.status {
                min-width: 120px;

                & > .g-select:deep(label) {
                    border: none;
                }

                & > .g-select:deep(input) {
                    padding-left: 0;
                    font-size: 0.9rem;
                }

                &.new > .value,
                &.new > .g-select {
                    color: var(--secondary, $secondary);
                }

                &.suspend > .value,
                &.suspend > .g-select {
                    color: var(--warning, $warning);
                }

                &.processed > .value,
                &.processed > .g-select {
                    color: var(--success, $success);
                }

                &.refund > .value,
                &.refund > .g-select {
                    color: var(--disabled-color, $disabled-color);
                }
            }

            &.actions {
                width: 30px;
                text-align: center;

                & > button {
                    position: relative;
                    margin: 0 4px;
                    cursor: pointer;
                    background-color: transparent;
                    fill: var(--env-text-color, $env-text-color);

                    &:hover {
                        &::after {
                            white-space: normal;
                            transform: translateX(-50%);
                            top: calc(100% + var(--tooltip-arrow-size, $tooltip-arrow-size));
                            background-color: var(--danger, $danger);
                            left: 50%;
                        }

                        &::before {
                            transform: translateX(-50%);
                            left: 50%;
                            top: calc(100% - var(--tooltip-arrow-size, $tooltip-arrow-size));
                            border-bottom-color: var(--danger, $danger);
                        }
                    }
                }
            }
        }

        &.bonus {
            border-color: var(--success, $success);

            & > .type {
                color: var(--success-hover, $success-hover);
            }
        }

        &.credit {
            border-color: var(--warning, $warning);

            & > .type {
                color: var(--warning, $warning);
            }
        }

        &.fake {
            border-color: var(--danger, $danger);

            & > .type {
                color: var(--danger-hover, $danger-hover);
            }
        }
    }

    .title {
        font-size: 0.8rem;
        color: var(--label-color, $label-color);
    }
}

.test {
    color: var(--disabled-color, $disabled-color);
}

.lead-link {
    color: var(--secondary, $secondary);
    text-decoration: underline;

    &:hover {
        color: var(--secondary-hover, $secondary-hover);
    }
}

.rate,
.currency {
    font-size: 0.8rem;
}

.btc {
    color: $btc;
    font-weight: 700;
}

.usdt {
    color: $usdt;
    font-weight: 700;
}

.eur {
    color: $eur;
    font-weight: 700;
}

.eth {
    color: $eth;
    font-weight: 700;
}

.danger {
    color: var(--danger, $danger);
}
</style>