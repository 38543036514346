<script setup>

import { useSettingsComponents } from '@settings';
import { onMounted } from 'vue';
import { useProfileActions } from '@profile';
import { useRouter, useRoute } from 'vue-router';

const General = useSettingsComponents('General');

const router = useRouter();
const route = useRoute();
const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

onMounted(() => {
    if (!profile.can('Settings GeneralView') && route.path === '/settings'){
        router.push({name: "NoAccess", ...route.params, query: { redirectedFrom: route.name }})
    }
});
</script>

<template>
    <div class="settings environment">
        <div v-if="$route.name === 'Settings'">
            <general/>
        </div>
        <router-view v-else/>
    </div>
</template>

<style lang="scss" scoped>
.settings {
    height: 100%;
    margin: 0 6px;
    padding: 8px 20px;
    position: relative;
    border-radius: 12px;
    font-size: 0.9rem;
    border: 3px solid var(--secondary, $secondary);
    background-color: var(--env-bg, $env-bg);
    color: var(--env-text-color, $env-text-color);
}
</style>