import { useLeadResources } from '@lead';

export default (reload = false) => {
    const lead = useLeadResources('Lead');

    if (reload) {
        if (!lead.isLoading) {
            lead.perPage = 25;
            lead.setIncludes(['brand', 'affiliate', 'sale', 'retention', 'country', 'campaigns']);
            // lead.load();
        }
    }

    return lead;
};
