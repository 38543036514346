import { usePermissionResources } from '@permission';

export default () => {
    const permission = usePermissionResources('Permission');
    if (!permission.isLoaded && !permission.isLoading) {
        //permission.setIncludes([]);
        permission.load();
    }

    return permission;
};
