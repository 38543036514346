<template>
    <div class="wrapper">
        <topbar/>
        <leftbar>
            <sidebar v-if="sidebar"/>
        </leftbar>
        <environment class="custom-scrollbar"/>
        <transition v-if="rightbar" name="rightbar" mode="out-in">
            <router-view class="rightbar"/>
        </transition>
    </div>
</template>

<script>

import { useEnvComponents } from '@overlay';

const {
    Topbar,
    Leftbar,
    AccountMenu,
} = useEnvComponents();

export default {
    name: 'Layout',
    components: {
        Topbar,
        Leftbar,
        AccountMenu
    },
};
</script>

<style lang="scss">
.wrapper {
    min-height: 100vh;
    background-color: var(--body-bg, $body-bg);
    overflow: hidden;
    display: grid;
    grid-gap: 5px;
    grid-template-areas:
            'tbr tbr tbr'
            'lbr env rbr';
    grid-template-rows: var(--bar-height, $bar-height)  calc(100vh - var(--bar-height, $bar-height) - 8px);
    grid-template-columns: min-content 1fr min-content;
}

.leftbar {
    grid-area: lbr;
    position: relative;
    box-shadow: -10px 0 18px -10px #00000050;
    width: var(--sidebar-width, $sidebar-width);
    max-width: 100%;
}

.environment {
    grid-area: env;
}

.rightbar {
    z-index: 4;
    grid-area: rbr;
    position: relative;
    border-radius: 12px;
    background-color: var(--env-bg, $env-bg);
    border: 3px solid var(--secondary, $secondary);
    box-shadow: -5px 0 28px -10px #000000cc;
    width: var(--rightbar-width, $rightbar-width);
    max-width: 100%;

    & > .card {
        height: 100%;
        //overflow: hidden;
        width: var(--rightbar-width, $rightbar-width);
    }
}

.rightbar-enter-active {
    transition: width 0.4s;
}

.rightbar-enter-from,
.rightbar-leave-active {
    width: 0;
    transition: width 0.1s;
}

@media (min-width: 985px) {
    .topbar {
        padding: 0 10px;
    }

    @media (max-width: 1284px) {
        .wrapper {
            grid-template-columns: min-content 1fr min-content;
        }
    }
}

@media (max-width: 984px) {
    .environment {
        height: calc(100vh - var(--bar-height, $bar-height) - 8px);
    }

    .topbar {
        top: 0;
        padding: 0 8px;
        position: sticky;
    }

    .leftbar {
        z-index: 3;
    }

    .rightbar {
        margin-top: 145px;
        position: fixed;
        z-index: 2;
        background-color: var(--env-bg, $env-bg);
        height: calc(100vh - (var(--bar-height, $bar-height) + 148px));
        transition: width 0.3s;
    }
}

@media (max-width: 645px) {
    .rightbar {
        overflow-x: scroll;
        width: 100vw;

        .viewbar {
            left: -36px;

            & > .close-btn {
                border-radius: var(--btn-rounding, $btn-rounding);
                position: relative;
                left: 500px;
                width: 38px;
            }
        }
    }
}

@media (max-width: 644px) {
    .rightbar {
        .viewbar {
            right: 0;
        }
    }
}
</style>
