<script setup>
import { computed, reactive, ref, watch } from 'vue';
import {useShipActions, i18n, PopUpManager as PopUp, AutoSaveStack} from '@/Ship';
import { useProfileActions } from '@profile';
import { useLeadActions } from '@lead';
import { useAssetActions } from '@asset';
import { useSettingsActions } from "@settings";

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const getLeadResource = useLeadActions('getLeadResource');
const lead = getLeadResource();

const onlyNumberInput = useShipActions('onlyNumberInput');

let step = ref(profile.state.lead.multiplier_step || 1);

const getPairResource = useAssetActions('getPairResource');
const pairs = getPairResource();

const getSettingsResource = useSettingsActions('getSettingsResource');
const settings = getSettingsResource();

const fees = reactive({});

const specifics = reactive({
    multipliers: {},
});

if (!lead.model.specifics) {
    lead.model.specifics = {};
}
if (!lead.model.specifics?.multipliers || Array.isArray(lead.model.specifics.multipliers)) {
    lead.model.specifics.multipliers = {};
}

Object.assign(specifics, lead.model.specifics);

const saveStack = AutoSaveStack.getInstance();
saveStack.push(lead);

const multipliers = reactive({});
Object.assign(multipliers, lead.model.specifics.multipliers || {});

watch(() => lead.model.multipliers, () => {
    Object.assign(multipliers, lead.model.multipliers || {});
}, { deep: true });

function setValue(key, value) {
    if (lead.model?.specifics[key] === value) {
        delete fees[key];
    } else {
        fees[key] = value;
    }
}

const cryptoFee = computed({
    get: () => fees.crypto_fee || lead.model.specifics?.crypto_fee || settings.general?.defaultCryptoFee || 0.001,
    set: value => {
        setValue('crypto_fee', value);
        return true;
    }
});

const stockFee = computed({
    get: () => fees.stock_fee || lead.model.specifics?.stock_fee || settings.general?.defaultStockFee || 0.001,
    set: value => {
        setValue('stock_fee', value);
        return true;
    }
});

const commodityFee = computed({
    get: () => fees.commodity_fee || lead.model.specifics?.commodity_fee || settings.general?.defaultCommodityFee || 0.001,
    set: value => {
        setValue('commodity_fee', value);
        return true;
    }
});

function saveFees() {
    lead.save(lead.model.id, {
        specifics: Object.assign(lead.model.specifics, fees)
    }).then(() => {
        resetFees();
    });
}

function resetFees() {
    Object.keys(fees).forEach(key => delete fees[key]);
}

function addMultiplierPopUp() {
    PopUp
        .open(require('@lead/components/PopUps/AddMultiplier'))
        .setCaption(i18n.global.t('lead.add_multiplier', 'Add Multiplier'))
        .setAction((value) => {
            Object.assign(multipliers, value);
            saveMultipliers();
            PopUp.close();
        })
}

function saveMultipliers() {
    lead.save(lead.model.id, {
        specifics: Object.assign(lead.model.specifics, {multipliers})
    });
}

let tm;
function incrementMultiplier(symbol) {
    const value = parseInt(multipliers[symbol]);
    multipliers[symbol] = `${(value + Number(step.value))}%`;
    clearTimeout(tm);
    tm = setTimeout(() => {
        saveMultipliers();
    }, 1500)
}

function decrementMultiplier(symbol) {
    const value = parseInt(multipliers[symbol]);
    multipliers[symbol] = `${(value - Number(step.value))}%`;
    clearTimeout(tm);
    tm = setTimeout(() => {
        saveMultipliers();
    }, 1500)
}

function dropMultiplier(symbol) {
    delete multipliers[symbol];
    //lead.model.multipliers = multipliers;
    saveMultipliers();
}

function saveStep() {
    profile.state.lead.multiplier_step = step.value;
    profile.saveState();
}

function getAsset(symbol) {
    return pairs.state.find(pair => pair.symbol === symbol);
}

</script>

<template>

    <div class="fees">
        <fieldset>
            <legend>{{ $t('lead.crypto_fee', 'Crypto Fee') }}</legend>
            <g-input v-model="cryptoFee"
                     v-on:keypress="onlyNumberInput($event)"
                     v-bind:readonly="profile.cant('Lead FeeEdit')">
                <g-symbol v-if="profile.cant('Lead FeeEdit')" name="lock" width="18" height="18"/>
            </g-input>
        </fieldset>

        <fieldset>
            <legend>{{ $t('lead.stock_fee', 'Stock Fee') }}</legend>
            <g-input v-model="stockFee"
                     v-on:keypress="onlyNumberInput($event)"
                     v-bind:readonly="profile.cant('Lead FeeEdit')">
                <g-symbol v-if="profile.cant('Lead FeeEdit')" name="lock" width="18" height="18"/>
            </g-input>
        </fieldset>

        <fieldset>
            <legend>{{ $t('lead.commodity_fee', 'Commodity Fee') }}</legend>
            <g-input v-model="commodityFee"
                     v-on:keypress="onlyNumberInput($event)"
                     v-bind:readonly="profile.cant('Lead FeeEdit')">
                <g-symbol v-if="profile.cant('Lead FeeEdit')" name="lock" width="18" height="18"/>
            </g-input>
        </fieldset>

        <div class="btn-bar">
            <g-button class="cancel-btn"
                      v-on:click="resetFees"
                      v-bind:disabled="!Object.keys(fees).length">
                {{ $t('base.reset', 'Reset') }}
            </g-button>
            <g-button class="save-btn"
                      v-on:click="saveFees"
                      v-bind:disabled="!Object.keys(fees).length">
                {{ $t('base.save', 'Save') }}
            </g-button>
        </div>
    </div>

    <fieldset class="multipliers">
        <g-flex align="center" justify="between">

            <g-flex align="center">
                <g-caption class="caption" size="4" weight="600">{{ $t('lead.multipliers', 'Multipliers') }}</g-caption>
                <button class="add-btn with-tooltip"
                        v-bind:data-tooltip="$t('lead.add_multiplier', 'Add Multiplier')"
                        v-on:click="addMultiplierPopUp">
                    <g-symbol name="plus" width="16" height="16"/>
                </button>
            </g-flex>

            <label class="step">
                <span>{{ $t('lead.multiplier_step') }}:</span>
                <input v-model="step" v-on:keypress="onlyNumberInput($event)" v-on:change="saveStep"/>
            </label>
        </g-flex>
        <div class="columns">
            <div v-for="(value, symbol) in multipliers" v-bind:key="symbol">
                <g-flex class="multiplier" align="center" gap="8">
                    <g-flex class="asset" align="center" gap="5">
                        <g-symbol-currency v-bind:name="getAsset(symbol)?.base?.toLowerCase()" width="26" height="26"/>
                        <span><b>{{ getAsset(symbol)?.base }}</b>{{ getAsset(symbol)?.counter }}</span>
                    </g-flex>

                    <output>{{ value }}</output>

                    <g-flex class="multiplier-controls" justify="center" wrap="wrap" direction="column" gap="2">
                        <button type="button" class="up" v-on:click="incrementMultiplier(symbol)">
                            <g-symbol name="arrow-down" width="15" height="15"/>
                        </button>
                        <button type="button" class="down" v-on:click="decrementMultiplier(symbol)">
                            <g-symbol name="arrow-down" width="15" height="15"/>
                        </button>
                        <button type="button" class="stop" v-on:click="dropMultiplier(symbol)">
                            <g-symbol name="close" width="18" height="18"/>
                        </button>
                    </g-flex>
                </g-flex>
            </div>
        </div>
    </fieldset>

</template>

<style scoped lang="scss">
.fees {
    padding: 0 50px 0 60px;
    gap: 16px;
    display: flex;
    align-items: end;
    justify-content: space-between;
    color: var(--env-text-color, $env-text-color);
}

.btn-bar {
    white-space: nowrap;

    & > .g-button {
        margin: 0 2px;
        padding: 0 24px;

        &.save-btn {
            background-color: var(--primary, $primary);
        }

        &.cancel-btn {
            background-color: transparent;
            color: var(--env-text-color, $env-text-color);
            border: 1px solid var(--env-text-color, $env-text-color);
        }
    }
}

.multipliers {
    margin-top: 20px;
    padding: 15px 50px 10px 60px;
    color: var(--env-text-color, $env-text-color);

    .step {
        display: inline-flex;
        align-items: center;

        & > input {
            width: 50px;
            height: 32px;
            margin-left: 4px;
            border-radius: 2px;
            color: inherit;
            background-color: transparent;
            border: 1px solid var(--separator-color, $separator-color);
        }
    }

    .caption {
        font-size: 18px;
        font-weight: bold;
        margin-right: 10px;
    }

    .add-btn {
        font-size: 0;
        width: 30px;
        height: 30px;
        position: relative;
        background-color: transparent;
        border-radius: var(--btn-rounding, $btn-rounding);
        fill: var(--secondary, $secondary);
        border: 1px solid var(--separator-color, $separator-color);

        &:hover {
            fill: var(--title-text-color, $title-text-color);
            background-color: var(--secondary, $secondary);
        }

        &::after {
            top: 50%;
            white-space: nowrap;
            transform: translateY(-50%);
            left: calc(100% + var(--tooltip-arrow-size, $tooltip-arrow-size) + 2px);
        }

        &::before {
            top: 50%;
            transform: translateY(-50%);
            left: calc(100% - var(--tooltip-arrow-size, $tooltip-arrow-size) + 2px);
            border-right-color: var(--tooltip-bg, $tooltip-bg);
        }
    }
}

.multiplier {
    width: 100%;
    padding: 8px 0;
    font-size: 18px;
    font-weight: bold;

    & > .asset {
        width: 116px;
        padding: 5px;
        border-radius: 9px;
        font-size: 0.9rem;
        font-weight: normal;
        color: white;
        background-color: black;
    }

    & > output {
        flex-grow: 1;
    }
}

.columns {
    column-count: 2;
    column-gap: 30px;
    column-rule: 1px solid var(--separator-color, $separator-color);

    & > div {
        width: 100%;
        display: inline-block;
    }
}

.multiplier-controls {
    padding: 4px;
    width: 68px;
    margin-left: 8px;
    height: 40px;

    & > button {
        padding: 0;
        font-size: 0;
        border-width: 0;
        background-color: transparent;
        fill: var(--secondary, $secondary);

        &.up {
            width: 21px;
            height: 14px;

            & > .g-symbol {
                transform: rotate(180deg);
            }
        }

        &.down {
            width: 22px;
            height: 14px;
        }

        &.stop {
            width: 32px;
            height: 30px;
            border-radius: 5px;
            border: 1px solid var(--separator-color, $separator-color);
        }

        &:hover {
            fill: black;
        }
    }
}
</style>