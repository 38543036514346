import { useBrandResources } from '@brand';
import {ResourcesManager} from "@/Ship";

export default (reload = false) => {
    const brand = useBrandResources('Brand');
    if (reload || (!brand.isLoaded && !brand.isLoading)) {
        brand.load();
    }

    //ResourcesManager.addResource(brand, 'Brand');

    return brand;
};
