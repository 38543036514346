<script>

import { useLeadComponents } from '@lead';
import { useEnvComponents } from '@overlay';

const {
    Lead,
    Sidebar,
    Environment,
} = useLeadComponents();

export default {
    name: 'Leads',
    extends: useEnvComponents('Layout'),
    components: {
		Environment,
        Sidebar,
        Rightbar: Lead,
    },

    setup() {

        return {
            title: 'leads',
            sidebar: true,
            rightbar: true,
        };
    }
};
</script>
