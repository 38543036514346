<script setup>

import { computed, ref, defineEmits, defineProps } from 'vue';
import { useUserActions } from '@user';
import { useLeadActions } from '@lead';
import { useProfileActions } from '@profile';
import { useSettingsActions } from '@settings';

const getProfileResource = useProfileActions('getProfileResource');

const getSales = useUserActions('getSales');
const getRetentions = useUserActions('getRetentions');

const {
    archiving,
    massUpdate,
    massDelete,
    clearComments,
    getLeadResource,
} = useLeadActions();

const leads = getLeadResource();

 const props = defineProps({
     targets: {
         type: Array,
         default: () => [],
     }
 });

const emit = defineEmits(['updated', 'drop']);


const profile = getProfileResource();

const sale = ref(null);
const retention = ref(null);

const conversionStatus = ref(null);
const retentionStatus = ref(null);

const sales = ref([]);
const retentions = ref([]);

const getSettingsResource = useSettingsActions('getSettingsResource');
const settings = getSettingsResource('statuses');

const statuses = computed(() => settings.statuses || []);

getSales().then(response => {
    sales.value = response.data;
});

const isAllowRitentionAssign = ref(true);
props.targets.forEach(id => {
    id = Number(id);
   const lead = leads.state.find(lead => lead.id === id);
   if (lead.is_ftd === 'No') {
       isAllowRitentionAssign.value = false;
       return;
   }
});

getRetentions().then(response => {
    retentions.value = response.data;
});

function update() {
    const payload = {
        targets: props.targets,
    };
    if (sale.value) {
        payload.sale_id = sale.value;
    }

    if (retention.value) {
        payload.retention_id = retention.value;
    }

    if (conversionStatus.value) {
        payload.conversion_status = conversionStatus.value;
    }

    if (retentionStatus.value) {
        payload.retention_status = retentionStatus.value;
    }

    massUpdate(payload).then(() => {
        emit('updated');
    });
}

function removeLeads() {
    massDelete(props.targets).then(() => {
        emit('updated');
    });
}

function archiveLeads() {
    archiving.massArchive(props.targets).then(() => {
        emit('updated');
    });
}

function removeComments() {
    clearComments(props.targets).then(() => {
        emit('updated');
    });
}
</script>

<template>
    <form class="mass-update" v-on:submit.prevent="update">
        <g-caption size="4">
            Mass Update <span class="selected-count"> ( Selected leads: {{ targets.length }} )</span>
        </g-caption>
        <g-symbol class="close-btn" name="close" width="20" height="20" v-on:click="$emit('drop')"/>

        <div class="container">

            <div class="select-group">
                <fieldset v-if="profile.can('Lead SaleView')" class="sale-agent">
                    <legend>{{ $t('lead.assign_sale', 'Assign sale agent') }}</legend>
                    <g-select v-model="sale"
                              v-bind:options="sales"
                              option-text="name"
                              option-value="id"
                              v-bind:readonly="profile.cant('Lead SaleEdit')"/>
                </fieldset>

                <fieldset v-if="profile.can('Lead ConversionStatusView')" class="conversion-status">
                    <legend>{{ $t('lead.conversion_status', 'Conversion status') }}</legend>
                    <g-select v-model="conversionStatus"
                              v-bind:options="statuses.filter(item=> item.target === 'conversion')"
                              option-text="name"
                              option-value="name"
                              v-bind:readonly="profile.cant('Lead ConversionStatusEdit')"/>
                </fieldset>

                <div></div>

                <fieldset v-if="profile.can('Lead RetentionView')" class="retention-agent">
                    <legend>{{ $t('lead.assign_retention', 'Assign retention agent') }}</legend>
                    <g-select v-model="retention"
                              v-bind:options="retentions"
                              option-text="name"
                              option-value="id"
                              v-bind:readonly="profile.cant('Lead RetentionEdit') || !isAllowRitentionAssign"/>
                </fieldset>

                <fieldset v-if="profile.can('Lead RetentionStatusView')" class="retention-status">
                    <legend>{{ $t('lead.retention_status') }}</legend>
                    <g-select v-model="retentionStatus"
                              v-bind:options="statuses.filter(item=> item.target === 'retention')"
                              option-text="name"
                              option-value="name"
                              v-bind:readonly="profile.cant('Lead RetentionStatusEdit')"/>
                </fieldset>

                <g-button class="apply-btn"
                          type="submit"
                          v-bind:disabled="!sale && !retention && !conversionStatus && !retentionStatus">
                    <g-symbol name="check" width="20" height="20"/>
                    {{ $t('base.apply') }}
                </g-button>
            </div>

            <g-flex class="actions" gap="5">

                <g-button class="clear-comments" type="button" v-on:click="removeComments">
                    <g-symbol name="comments" width="20" height="20"/>
                    {{ $t('base.clear_comments', 'Clear') }}
                </g-button>


                <g-button v-if="profile.can('Lead Archive')"
                          class="archive-btn"
                          type="button"
                          v-on:click="archiveLeads">
                    <g-symbol name="archive" width="20" height="20"/>
                    {{ $t('base.archive', 'Archive') }}
                </g-button>

                <g-button v-if="profile.can('Lead Remove')"
                          class="remove-btn"
                          type="button"
                          v-on:click="removeLeads">
                    <g-symbol name="delete" width="20" height="20"/>
                    {{ $t('base.remove', 'Remove') }}
                </g-button>
            </g-flex>
        </div>
    </form>
</template>

<style lang="scss" scoped>
.mass-update {
    z-index: 3;
    top: -8px;
    margin: 0 -20px;
    position: sticky;
    max-height: 210px;
    border-radius: 0 0 10px 10px;
    color: var(--env-text, $env-text-color);
    background-color: var(--env-bg, $env-bg);
    box-shadow: 0 10px 15px -5px #00000044;
    container-type: inline-size;
    container-name: form;
}

.g-caption {
    padding: 15px 40px 5px;
}

.container {
    padding: 5px 20px 25px 40px;
    margin-top: 10px;
    gap: 5px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.select-group {
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: 180px 140px min-content;
    align-items: end;
}

.g-button {
    min-width: 128px;
    padding: 20px;
    color: black;
    font-weight: bold;
    gap: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.apply-btn {
        margin-left: auto;
        width: 110px;
        flex-shrink: 0;
        fill: white;
        color: white;
        border: 1px solid white;
        background-color: var(--success, $success);

        &:hover {
            background-color: var(--success-hover, $success-hover);
        }
    }

    &.clear-comments {
        width: 110px;
        fill: var(--env-text-color, $env-text-color);
        color: var(--env-text-color, $env-text-color);
        border: 1px solid var(--env-text-color, $env-text-color);
        background-color: transparent;

        &:hover {
            fill: white;
            color: white;
            border-color: var(--danger, $danger);
            background-color: var(--danger, $danger);
        }
    }

    &.archive-btn {
        background-color: transparent;
        fill: var(--env-text-color, $env-text-color);
        color: var(--env-text-color, $env-text-color);
        border: 1px solid var(--env-text-color, $env-text-color);

        &:hover {
            fill: white;
            color: white;
            border-color: var(--danger, $danger);
            background-color: var(--danger, $danger);
        }
    }

    &.remove-btn {
        fill: white;
        color: white;
        border: 1px solid var(--danger, $danger);
        background-color: var(--danger, $danger);

        &:hover {
            background-color: var(--danger-hover, $danger-hover);
        }
    }
}

.g-select {
    z-index: 10;

    &:deep(.g-symbol) {
        fill: var(--secondary, $secondary) !important;
    }
}

.close-btn {
    top: 10px;
    right: 20px;
    position: absolute;
    cursor: pointer;
    fill: var(--env-text-color, $env-text-color);
}

@container form (max-width: 1100px) {
    .actions {
        flex-direction: column;
    }
}

</style>
