<script setup>

import { xor } from 'lodash';
import { computed, ref, defineEmits, defineProps } from 'vue';

const props = defineProps({
    modelValue: {
        type: Array,
        default: () => [],
    },
    checked: {
        type: Boolean,
        default: false,
    },
    value: {
        type: String
    },
    disabled: {
        type: Boolean,
        default: false
    },
    symbol: {
        type: String,
        default: 'check'
    },
    backlight: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(['update:modelValue', 'update:checked','change']);

const inp = ref();

const isChecked = computed(() => {
    return props.modelValue.includes(props.value) || props.checked;
});

const changeEvent = () => {
    emit('update:modelValue', xor(props.modelValue, [props.value]));
    emit('update:checked', inp.value.checked);
    emit('change');
};
</script>

<template>
    <label class="g-checkbox" v-bind:class="{ disabled, backlight}">
        <g-flex align="center" justify="start" gap="6">
            <span class="label"><slot name="before"/></span>
            <input ref="inp"
                   type="checkbox"
                   v-bind:value="value"
                   v-bind:checked="isChecked"
                   v-bind:disabled="disabled"
                   v-on:change.stop="changeEvent"
                   v-bind="$attrs"
                   hidden>
            <g-symbol v-bind:name="symbol" width="18" height="18"/>
            <span class="label"><slot/></span>
        </g-flex>
    </label>
</template>

<style lang="scss" scoped>
.g-checkbox {
    cursor: pointer;
    font-weight: 400;

    &.backlight {
        color: var(--secondary-hover, $secondary-hover);
    }

    .label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    input + .g-symbol {
        flex-shrink: 0;
        transition: background-color 0.2s;
        fill: transparent;
    }

    &:hover input + .g-symbol {
        fill: var(--input-border, $input-border);
    }

    & input:not(:checked) + .g-symbol {
        opacity: 0.6;
        border: 1px solid var(--input-border, $input-border);
        background-color: var(--checkbox-bg, $checkbox-bg);
        border-radius: var(--checkbox-rounding, $checkbox-rounding);
    }

    & input:checked + .g-symbol {
        fill: var(--success, $success);
    }

    & input.black:checked + .g-symbol {
        fill: black;
    }

    & input.white:checked + .g-symbol {
        fill: white;
    }

    & input.primary:checked + .g-symbol {
        fill: var(--primary, $primary);
    }

    & input.danger:checked + .g-symbol {
        fill: var(--danger, $danger);
    }

    & input.warning:checked + .g-symbol {
        fill: var(--warning, $warning);
    }

    &.disabled {
        cursor: default;
        pointer-events: none;
        opacity: 0.4;
        color: var(--disabled-color, $disabled-color);

        & input:checked + .g-symbol {
            fill: white;
            pointer-events: none;
        }
    }
}
</style>
