
import { useShipResources } from '@/Ship';

const { BaseResource } = useShipResources();

export default class Lead extends BaseResource {

    static #instance = null;

    endpoint = 'leads';
    referer = 'Lead Resource';
    archiveEndpoint = `${this.endpoint}/archive`;

    section = 'Leads';
    container = 'Leads';

    static getInstance() {
        if (!Lead.#instance) {
            Lead.#instance = new Lead();
        }

        return Lead.#instance;
    }

    columns = [
        {
            key: 'brand_id',
            name: 'brand',
            sortable: false,
        }, {
            key: 'phone_confirmed',
            name: 'phone_confirmed',
            sortable: true,
        }, {
            key: 'is_autologin',
            name: 'is_autologin',
            sortable: false,
            //allow: 'Lead IsAutologin'
        }, {
            key: 'is_ftd',
            name: 'is_ftd',
            sortable: true,
            allow: 'Lead IsFTDView'
        }, {
            key: 'is_test',
            name: 'is_test',
            sortable: true,
        }, {
            key: 'email',
            name: 'email',
            sortable: false,
            allow: 'Lead ContactsView',
        }, {
            key: 'email_confirmed',
            name: 'email_confirmed',
            sortable: false,
        }, {
            key: 'name',
            name: 'name',
            sortable: false,
        }, {
            key: 'conversion_status',
            name: 'conversion_status',
            sortable: true,
            allow: 'Lead ConversionStatusView'
        }, {
            key: 'retention_status',
            name: 'retention_status',
            sortable: true,
            allow: 'Lead RetentionStatusView'
        }, {
            key: 'kyc_status',
            name: 'kyc_status',
            sortable: true,
            allow: 'Lead KYCStatusView'
        }, {
            key: 'account_level',
            name: 'account_level',
            sortable: true,
            allow: 'Lead AccountLevelView'
        }, {
            key: 'balance',
            name: 'balance',
            sortable: true,
            allow: 'Lead BalanceView'
        }, {
            key: 'language',
            name: 'language',
            sortable: true,
        }, {
            key: 'country_code',
            name: 'country',
            sortable: true,
        }, {
            key: 'timezone',
            name: 'timezone',
            sortable: true,
            allow: 'Lead DetailsView'
        }, {
            key: 'sale_id',
            name: 'sale',
            sortable: true,
            allow: 'Lead SaleView'
        }, {
            key: 'retention_id',
            name: 'retention',
            sortable: true,
            allow: 'Lead RetentionView'
        }, {
            key: 'affiliate_id',
            name: 'affiliate',
            sortable: true,
        }, {
            key: 'campaigns',
            name: 'campaigns',
            sortable: false,
        }, {
            key: 'gender',
            name: 'gender',
            sortable: false,
            allow: 'Lead DetailsView'
        }, {
            key: 'marital_status',
            name: 'marital_status',
            sortable: false,
            allow: 'Lead DetailsView'
        }, {
           key: 'commentDate',
           name: 'lastComment',
           sortable: true,
           allow: 'Comment View'
        }, {
           key: 'created_at',
           name: 'created_at',
           sortable: true,
        },
    ];
}
