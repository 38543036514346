<template>
    <div class="saving">
        <div class="opened">
            <h4 class="caption">Active deposits</h4>
            <div class="titles row">
                <span class="cell">Contract</span>
                <span class="cell">Status</span>
                <span class="cell">Open date</span>
                <span class="cell">closing date</span>
                <span class="cell">Term</span>
                <span class="cell">Interest rate</span>
                <span class="cell">Opening rate</span>
                <span class="cell">Deposit amount</span>
                <span class="cell">Profit</span>
                <span class="cell">Total sum</span>
            </div>
            <div class="row"
                 v-for="(deposit) in activeDeposits"
                 v-bind:key="deposit.number">
                <span class="cell" v-bind:data-field="'Contract'">
                    №{{ deposit.id }}
                </span>
                <span class="cell" v-bind:data-field="'Status'">
                    {{ deposit.status_approve }}
                </span>
                <span class="cell" v-bind:data-field="'Closing date'">
                    {{ formatDate(deposit.created_at) }}
                </span>
                <span class="cell" v-bind:data-field="'Interest rate'">
                    +{{ deposit.percent }}%
                </span>
                <span class="cell" v-bind:data-field="'Total sum'">
                    {{ deposit.sum }}
                       <g-symbol-currency name="btc" width="24" height="24"/>
                </span>
                <span class="cell" v-bind:data-field="'Open date'">
                    {{ formatDate(deposit.created_at) }}
                </span>
                <span class="cell" v-bind:data-field="'Opening rate'">
                    {{ deposit.rate_before }}
                      <g-symbol-currency name="btc" width="24" height="24"/>
                </span>
                <span class="cell" v-bind:data-field="'Term'">
                    {{ getTerm(deposit.period) }}
                </span>
                <span class="cell" v-bind:data-field="'Profit'">
                    {{ monthlyProfit(deposit) }}
                <g-symbol-currency name="btc" width="24" height="24"/>
                </span>
                <span class="cell" v-bind:data-field="'Total sum'">
                    {{ totalAmount(deposit) }}
                       <g-symbol-currency name="btc" width="24" height="24"/>
                </span>
            </div>
        </div>

        <div class="closed">
            <h4 class="caption">Closed deposits</h4>
            <div class="titles row">
                <span class="cell">Contract</span>
                <span class="cell">Status</span>
                <span class="cell">Open date</span>
                <span class="cell">closing date</span>
                <span class="cell">Term</span>
                <span class="cell">Interest rate</span>
                <span class="cell">Opening rate</span>
                <span class="cell">Deposit amount</span>
                <span class="cell">Profit</span>
                <span class="cell">Total sum</span>
            </div>
            <div class="row"
                 v-for="(deposit) in closedDeposits"
                 v-bind:key="deposit.number">
                <span class="cell" v-bind:data-field="'Contract'">
                    №{{ deposit.id }}
                </span>
                <span class="cell" v-bind:data-field="'Status'">
                    {{ deposit.status_approve }}
                </span>
                <span class="cell" v-bind:data-field="'Closing date'">
                    {{ formatDate(deposit.created_at) }}
                </span>
                <span class="cell" v-bind:data-field="'Interest rate'">
                    +{{ deposit.percent }}%
                </span>
                <span class="cell" v-bind:data-field="'Total sum'">
                    {{ deposit.sum }}
                       <g-symbol-currency name="btc" width="24" height="24"/>
                </span>
                <span class="cell" v-bind:data-field="'Open date'">
                    {{ formatDate(deposit.created_at) }}
                </span>
                <span class="cell" v-bind:data-field="'Opening rate'">
                    {{ deposit.rate_before }}
                      <g-symbol-currency name="btc" width="24" height="24"/>
                </span>
                <span class="cell" v-bind:data-field="'Term'">
                    {{ getTerm(deposit.period) }}
                </span>
                <span class="cell" v-bind:data-field="'Profit'">
                    {{ monthlyProfit(deposit) }}
                <g-symbol-currency name="btc" width="24" height="24"/>
                </span>
                <span class="cell" v-bind:data-field="'Total sum'">
                    {{ totalAmount(deposit) }}
                       <g-symbol-currency name="btc" width="24" height="24"/>
                </span>
            </div>
        </div>
    </div>
</template>

<script>

import { computed, reactive, ref } from 'vue';
import { STATE, PopUpManager as PopUp } from '@/Ship';
import { useProfileResources } from '@profile';
import { useProfileComponents } from '@/Containers/Profile/Profile';

export default {
    name: 'Environment',
    components: {
        ...useProfileComponents()
    },
    setup: function () {
        const activeDeposits = reactive([
            {
                id: 1,
                number: 1,
                status_approve: 'Active',
                created_at: '15.02.2021',
                percent: '+2',
                sum: '65,000',
                rate_before: 'd',
                period: '15.02.2021'
            },
            {
                id: 2,
                number: 2,
                status_approve: 'Active',
                created_at: '15.02.2021',
                percent: '+2',
                sum: 65.000,
                rate_before: 5,
                rate_after: 5,
                period: 15,
                unit: 'usdt'
            }
        ]);
        const closedDeposits = reactive([
            {
                id: 1,
                number: 1,
                status_approve: 'Active',
                created_at: '15.02.2021',
                percent: '+2',
                sum: '65,000',
                rate_before: 'd',
                period: 15
            },
            {
                id: 2,
                number: 2,
                status_approve: 'Active',
                created_at: '15.02.2021',
                percent: '+2',
                sum: 65.000,
                rate_before: 5,
                rate_after: 5,
                period: 15,
                unit: 'usdt'
            }
        ]);

        const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

        const formatDate = (value) => {
            return new Date(value).toLocaleDateString('en-US', dateOptions);
        };

        const getTerm = (period) => {
            if (period === 12) {
                return 1 + 'year';
            }
            return period + 'month';
        };
        const monthlyProfit = (deposit) => {
            console.log(deposit.sum, +deposit.percent, deposit.period, 's');
            return (+deposit.sum / 100 * +deposit.percent * deposit.period);
        };
        const totalAmount = (deposit) => {
            // console.log(monthlyProfit(deposit));
            return deposit.sum + +monthlyProfit(deposit);
        };
        const totalAmountUSDT = (deposit) => {
            if (deposit.rate_after) {
                return parseFloat(totalAmount(deposit)) * deposit.rate_after;
            }
        };

        return {
            activeDeposits,
            closedDeposits,
            formatDate,
            getTerm,
            monthlyProfit,
            totalAmount,
            totalAmountUSDT
        };
    }
};
</script>

<style lang="scss" scoped>
.caption {
    font-size: 28px;
    font-weight: normal;
}

.titles {
    border-bottom: 1px solid $separator-color;
}

.row {
    font-size: 12px;
    white-space: nowrap;
    display: grid;

    & > .cell {
        img {
            width: 15px;
            height: 15px;
            margin-left: 2px;
            vertical-align: middle;
        }
    }
}

.opened {
    padding: 0 2vw 2vw 0;

    & > .row > .cell {
        &:nth-child(1) {
            grid-area: crt;
        }

        &:nth-child(2) {
            grid-area: sts;
        }

        &:nth-child(3) {
            grid-area: cls;
        }

        &:nth-child(4) {
            grid-area: ist;
        }

        &:nth-child(5) {
            grid-area: amt;
        }

        &:nth-child(6) {
            grid-area: opn;
        }

        &:nth-child(7) {
            grid-area: ort;
        }

        &:nth-child(8) {
            grid-area: trm;
        }

        &:nth-child(9) {
            grid-area: prf;
        }

        &:nth-child(10) {
            grid-area: sum;
        }
    }
}

.closed {
    padding: 2vw 2vw 2vw 0;

    & > .row > .cell {
        &:nth-child(1) {
            grid-area: crt;
        }

        &:nth-child(2) {
            grid-area: sts;
        }

        &:nth-child(3) {
            grid-area: cls;
        }

        &:nth-child(4) {
            grid-area: ist;
        }

        &:nth-child(5) {
            grid-area: amt;
        }

        &:nth-child(6) {
            grid-area: opn;
        }

        &:nth-child(7) {
            grid-area: ort;
        }

        &:nth-child(8) {
            grid-area: rat;
        }

        &:nth-child(9) {
            grid-area: trm;
        }

        &:nth-child(10) {
            grid-area: prf;
        }

        &:nth-child(11) {
            grid-area: sum;
        }

        &:nth-child(12) {
            grid-area: eld;
        }
    }
}

@media (min-width: 1025px) {
    .saving {
        overflow: auto;
        height: calc(100vh - 120px);
    }
}

@media (max-width: 1584px) {
    .titles {
        display: none;
    }

    .row {
        padding: 1vw;
        border-radius: 3px;
        margin-bottom: 10px;
        background-color: white;
        box-shadow: 0 10px 12px -12px #00000055;

        & > .cell {
            padding: 6px 4px;

            &::before {
                color: var(--title-text-color, $title-text-color);
                font-size: 12px;
                flex-basis: 100%;
                flex-shrink: 0;
                display: block;
                content: attr(data-field);
            }
        }
    }
}

@media (max-width: 1124px) {
    .opened > .row {
        grid-template-areas:
            'crt ist ort'
            'sts trm amt'
            'opn opn prf'
            'cls cls sum';
        grid-template-columns: repeat(3, 1fr);
    }

    .closed > .row {
        grid-gap: 0.8vw;
        grid-template-areas:
            'crt ist amt '
            'sts trm prf '
            'opn ort sum'
            'cls rat eld';
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (min-width: 1125px) and (max-width: 1424px) {
    .opened > .row {
        grid-template-areas:
            'crt opn ort amt'
            'sts cls cls prf'
            'ist trm trm sum';
        grid-template-columns: repeat(4, 1fr);
    }

    .closed > .row {
        grid-gap: 0.8vw;
        grid-template-areas:
            'crt opn amt ort'
            'sts cls prf rat'
            'ist trm sum eld';
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (min-width: 1425px) and (max-width: 1584px) {
    .opened > .row {
        grid-template-areas:
            'crt opn amt ort ist'
            'sts cls sum trm prf ';
        grid-template-columns: repeat(5, 1fr);
    }

    .closed > .row {
        grid-gap: 0.8vw;
        grid-template-areas:
            'crt opn amt ort rat ist'
            'sts cls sum trm prf eld';
        grid-template-columns: 110px 160px repeat(4, 1fr);
    }
}

@media (min-width: 1585px) {
    .row {
        padding: 6px 10px;
        line-height: 32px;
        grid-gap: 1vw;
        grid-template-rows: repeat(auto-fill, 32px);

        & > .cell {
            &:last-child {
                text-align: center;
            }
        }

        &:nth-child(odd) {
            background-color: transparent;
        }
    }

    .opened > .row {
        grid-template-areas: 'crt sts opn cls trm ist ort amt prf sum';
        grid-template-columns: 60px repeat(9, 1fr);
    }

    .closed > .row {
        grid-template-areas: 'crt sts opn cls trm ist ort rat amt prf sum eld';
        grid-template-columns: 60px repeat(11, 1fr);
    }
}
</style>
