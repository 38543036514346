<script setup>
import { computed, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { useSettingsActions, useSettingsComponents } from '@settings';

const getSettingsResource = useSettingsActions('getSettingsResource');

const {
	Statuses,
	AccountLevels,
} = useSettingsComponents();

const settings = getSettingsResource(null, true);

let timeout = null;
const general = computed(() => settings.model['general'] || {});
const trading = computed(() => settings.model['trading'] || {});

const payload = reactive({});

const { fallbackLocale } = useI18n();

const languages = computed(() => fallbackLocale.value.map(code => {
	return {v: code}
}));

function saveGeneral() {
	clearTimeout(timeout);
	timeout = setTimeout(() => {
		settings.save('general', payload).then(() => {
			Object.keys(payload).forEach(key => delete payload[key]);
		});
	}, 2000);
}

function saveTrading() {
	clearTimeout(timeout);
	timeout = setTimeout(() => {
		settings.save('trading', payload).then(() => {
			Object.keys(payload).forEach(key => delete payload[key]);
		});
	}, 2000);
}

const useWhitelist = computed({
	get: () => general.value.useWhitelist || false,
	set: (value) => {
		general.value.useWhitelist = value;
		payload.useWhitelist = value;
		saveGeneral();
		
		return true;
	}
});

const defaultLanguage = computed({
	get: () => general.value.defaultLanguage || '',
	set: value => {
		general.value.defaultLanguage = value;
		payload.defaultLanguage = value;
		saveGeneral();
		
		return true;
	}
});

function tradingComputed(propName, defaultValue = 0.001) {
	return computed({
		get: () => trading.value[propName] || defaultValue,
		set: (value) => {
			trading.value[propName] = value;
			payload[propName] = value;
			saveTrading();
			return true;
		}
	});
}

const cryptoFee = tradingComputed('defaultCryptoFee');
const stockFee = tradingComputed('defaultStockFee');
const commodityFee = tradingComputed('defaultCommodityFee');
const minDealAmount = tradingComputed('minDealAmount', 10);
</script>

<template>
    <div class="settings-wrapper">
        <div class="crm general">
            <g-caption size="4" weight="bold">CRM Settings</g-caption>
            <g-flex gap="10" justify="between">
                <g-flex class="whitelist-switcher" align="center" justify="start" gap="8">
                    <label>Enable whitelist</label>
                    <g-switch v-model:checked="useWhitelist"/>
                </g-flex>

                <g-select v-model="defaultLanguage"
                          class="default-language"
                          label="Default Language"
                          v-bind:options="languages"
                          option-text="v"
                          option-value="v"/>
            </g-flex>
        </div>
        <div class="trading general">
            <g-caption size="4" weight="bold">Trading Settings</g-caption>
            <g-flex gap="10" justify="between">
                <g-flex gap="10">
                    <fieldset>
                        <legend>Default Crypto Fee</legend>
                        <g-input v-model="cryptoFee"/>
                    </fieldset>
                    <fieldset>
                        <legend>Default Stock Fee</legend>
                        <g-input v-model="stockFee"/>
                    </fieldset>
                    <fieldset>
                        <legend>Default Commodity Fee</legend>
                        <g-input v-model="commodityFee"/>
                    </fieldset>
                </g-flex>

                <fieldset>
                    <legend>Min Deal Amount</legend>
                    <g-input v-model="minDealAmount"/>
                </fieldset>
            </g-flex>
        </div>
        <div class="gag"></div>
        <statuses/>
        <account-levels/>
    </div>
</template>

<style scoped lang="scss">
.settings-wrapper {
    padding: 10px;
    display: grid;
    grid-gap: 10px;
    margin: 0 auto;
    max-width: 1024px;
}

.gag {
    grid-area: gag;

    & > .g-field {
        width: 180px;
    }
}

.statuses {
    grid-area: sts;
}

.account-levels {
    grid-area: acl;
}

.general {
    padding: 5px 15px 15px;
    border-radius: var(--bar-rounding, $bar-rounding);
    border: 1px solid var(--separator-color, $separator-color);

    &.crm {
        grid-area: crm;

        .g-select {
            &:deep(.field),
            &:deep(.datalist) {
                color: white;
                border-radius: 5px;
                background-color: var(--primary, $primary);
            }

            &:deep(.arrow) {
                fill: white;
            }
        }
    }

    &.trading {
        grid-area: trd;
    }

    & > .whitelist-switcher {
        white-space: nowrap;
    }

    .g-caption {
        margin-bottom: 10px;
    }

    .g-input,
    .default-theme,
    .default-language {
        width: 150px;
    }
}

@media (min-width: 1385px) {
    .settings-wrapper {
        grid-template-areas:
            'crm crm'
            'trd trd'
            'acl sts';
        grid-template-columns: 480px 450px;
    }
}

@media (max-width: 1080px) {
    .general {
        flex-wrap: wrap;
    }
}

@media (max-width: 1384px) {
    .settings-wrapper {
        grid-template-areas:
            'crm'
            'trd'
            'sts'
            'acl';
        grid-template-columns: 1fr;
    }
}
</style>