<script setup>

import { computed, reactive, defineEmits } from 'vue';
import { useAssetActions } from '@asset';

const emit = defineEmits(['performed', 'close']);

const getAssetResource = useAssetActions('getAssetResource');
const asset = getAssetResource();

const payload = reactive({
    symbol: '',
    name: '',
    industry: '',
    country: '',
});

const assets = computed(() => {
    return asset.state;
});

const isMakeDisabled = computed(() => {
    return (
        !payload.symbol ||
        !payload.name ||
        !payload.industry
    );
});

function makeAsset() {
    asset.create(payload);
    asset.load();
    emit('performed');
}
</script>

<template>
    <form class="make-assets" v-on:submit.prevent="makeAsset">

        <fieldset>
            <legend>{{ $t('assets.country_code', 'Country code') }}</legend>
            <g-input v-model="payload.country"/>
        </fieldset>

        <fieldset>
            <legend>{{ $t('assets.symbol', 'Symbol') }}</legend>
            <g-input v-model="payload.symbol"/>
        </fieldset>

        <fieldset>
            <legend>{{ $t('assets.name', 'Name') }}</legend>
            <g-input v-model="payload.name"/>
        </fieldset>

        <fieldset>
            <legend>{{ $t('assets.industry', 'Industry') }}</legend>
            <g-combobox v-model="payload.industry"
                        v-bind:options="assets.map(val => val.industry)"
                        option-text="name"
                        option-value="industry"
                        autocomplete="nope"/>
        </fieldset>
        <g-flex class="btn-bar" gap="5" justify="center">
            <g-button class="cancel-btn" type="button" v-on:click="$emit('close')">{{ $t('base.cancel', 'Cancel') }}</g-button>
            <g-button class="save-btn" type="submit" v-bind:disabled="isMakeDisabled">{{ $t('base.make', 'Make') }}</g-button>
        </g-flex>
    </form>
</template>

<style scoped lang="scss">
.make-assets {
    width: 320px;
}

fieldset {
    margin: 5px 0;

    & > .g-input,
    & > .g-combobox:deep(.field) {
        background-color: var(--body-bg, $body-bg);
    }
}

.btn-bar {
    margin-top: 30px;
}

.g-button {
    min-width: 100px;
    padding: 0 20px;
}
</style>