
import { STACK, ApiClient, STATE } from '@/Ship';

export default function (id) {
	return STACK.push(() => {
		return ApiClient.delete(`impersonate/${id}`).then(response => {
			sessionStorage.setItem('access_token', sessionStorage.getItem('real_token'));
			sessionStorage.setItem('expires_in', sessionStorage.getItem('real_expires_in'));
			sessionStorage.removeItem('impersonate');
			sessionStorage.removeItem('real_token');
			sessionStorage.removeItem('real_expires_in');
			location.reload();
			//STATE.IS_LOGIN = true;
		});
	});
}