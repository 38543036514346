<script setup>
import { useAssetActions } from '@asset';
import { useProfileActions } from '@profile';
import { PopUpManager as PopUp } from '@/Ship';
import { computed, ref } from 'vue';

const getPairResource = useAssetActions('getPairResource');
const pairs = getPairResource();

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const market = ref();
const marketList = [
    { name: 'crypto' },
    { name: 'commodities' },
    { name: 'stocks' }
];

const counter = ref();
const counterList = [
    { name: 'USDT' },
    { name: 'BTC' },
    { name: 'ETH' }
];

const pairState = computed(() => {
    return pairs.state.filter(pair => {
        return (!market.value || pair.market === market.value) && (!counter.value || pair.counter === counter.value)
    });
});

const inactivated = computed(() => pairs.state.filter(item => !item.is_active).map(item => item.symbol));
const payload = {
    enable: [],
    disable: []
};

const setDisablePairs = (pair) => {

    if (inactivated.value.includes(pair.symbol)) {
        pair.is_active = 1;

        if (payload.enable.includes(pair.symbol)) {
            const index = payload.enable.findIndex((symbol) => symbol === pair.symbol);
            payload.enable.splice(index, 1);
        } else {
            payload.enable.push(pair.symbol);
        }
    } else {
        pair.is_active = 0;

        if (payload.disable.includes(pair.symbol)) {
            const index = payload.disable.findIndex((symbol) => symbol === pair.symbol);
            payload.disable.splice(index, 1);
        } else {
            payload.disable.push(pair.symbol);
        }
    }
};

</script>

<template>
    <fieldset class="pairs" v-bind:disabled="profile.cant('Asset Edit')">

        <g-preloader-overlay v-if="pairs.isLoading"/>

        <g-flex class="toolbar" align="center" justify="between">
            <g-flex gap="5">
                <g-select v-model="market"
                          v-bind:label="$t('trading.market', 'Market')"
                          v-bind:options="marketList"
                          option-text="name"
                          text-transform="capitalize"
                          option-value="name"/>
                <g-select v-if="market === 'crypto'"
                          v-model="counter"
                          v-bind:label="$t('assets.counter', 'Counter asset')"
                          v-bind:options="counterList"
                          v-bind:text-transform="'Capitalize'"
                          option-text="name"
                          option-value="name"/>

                <g-button v-on:click="PopUp.open(require('@asset/components/PopUps/MakeAssets.vue')).setCaption($t('assets.make_asset', 'Create asset'))">
                    {{ $t('assets.create_asset', 'Create asset') }}
                </g-button>

                <g-button v-on:click="PopUp.open(require('@asset/components/PopUps/MakePairs.vue')).setCaption($t('assets.make_pair', 'Create pair'))">
                    {{ $t('assets.create_pair', 'Create pair') }}
                </g-button>
            </g-flex>

            <g-button type="button" class="save-btn" v-on:click="pairs.update(payload)">
                <g-symbol name="save" width="18" height="18"/>
                {{ $t('base.save', 'Save') }}
            </g-button>
        </g-flex>


        <div class="container">
            <g-flex inline align="center" gap="5" class="pair" v-for="pair in pairState" v-bind:key="`pair-${pair.symbol}`">
                <g-checkbox v-model="inactivated"
                            v-bind:value="pair.symbol"
                            v-on:change="setDisablePairs(pair)"
                            v-bind:class="{danger: inactivated.includes(pair.symbol)}"
                            v-bind:symbol="'close'">
                    <b>{{ pair.base }}</b>{{ pair.counter }}
                </g-checkbox>
                <g-symbol name="settings"
                          width="20"
                          height="20"
                          class="edit-btn"
                          v-on:click.stop="PopUp.open(require('@asset/components/PopUps/EditPair.vue')).setCaption($t('assets.edit_pair', 'Edit pair')).setPayload({payload: { symbol: pair.symbol }})"/>
            </g-flex>
        </div>
    </fieldset>
</template>


<style scoped lang="scss">
.pairs {
    container-name: pairs;
    container-type: inline-size;
    min-height: 100%;
    padding: 15px 20px;
    color: var(--env-text-color, $env-text-color);
    border-radius: var(--bar-rounding, $bar-rounding);
    border: 1px solid var(--separator-color, $separator-color);

    &:disabled {
        .g-checkbox {
            opacity: 0.5;
        }
    }

    &:deep(.preloader-wrapper) {
        height: calc(100% + 26px) !important;
        top: -26px !important;
        border-radius: var(--bar-rounding, $bar-rounding);
        overflow: hidden;
    }
}

.toolbar {
    padding: 0 5px;

    & > div > .g-button {
        color: white;
        padding: 0 20px;
        background-color: var(--secondary, $secondary);

        &:hover {
            background-color: var(--secondary-hover, $secondary-hover);
        }
    }

    & > .save-btn {
        padding: 0 20px;
        min-width: 100px;
        background-color: var(--primary, $primary);

        & > .g-symbol {
            margin-right: 10px;
            vertical-align: middle;
        }

        &:hover {
            background-color: var(--primary-hover, $primary-hover);
        }
    }
}

.container {
    margin-top: 15px;

    & > .pair {
        width: 100%;
        margin: 6px 0;

        .edit-btn {
            cursor: pointer;
            fill: var(--secondary, $secondary);

            &:hover {
                fill: var(--secondary-hover, $secondary-hover);
            }
        }
    }
}

@container pairs (min-width: 1300px) {
    .container {
        column-count: 8;
    }
}

@container pairs (min-width: 1000px) and (max-width: 1300px) {
    .container {
        column-count: 6;
    }
}

@container pairs (min-width: 800px) and (max-width: 1000px) {
    .container {
        column-count: 5;
    }
}

@container pairs (min-width: 700px) and (max-width: 800px) {
    .container {
        column-count: 4;
    }
}

@container pairs (min-width: 500px) and (max-width: 700px) {
    .container {
        column-count: 3;
    }
}

@container pairs (min-width: 350px) and (max-width: 500px) {
    .container {
        column-count: 2;
    }
}
</style>