<script setup>

import { computed, defineEmits, reactive, ref } from 'vue';
import { useOfferActions } from '@offer';
import { i18n } from '@/Ship';

const emit = defineEmits(['performed']);

const getOfferResource = useOfferActions('getOfferResource');
const offers = getOfferResource();
const errorText = ref('');

const payload = reactive({
	name: '',
	description: '',
});

const isPayloadValid = computed(() => {
    return payload.name
});

function makeOffer() {
    const result = offers.state.find(item => item.name === payload.name);

    if (!result) {
        offers.create(payload).then(() => {
            emit('performed');
        }).catch(error => {
            errorText.value = error.response.data.description.name[0];
        });
    } else {
        errorText.value = i18n.global.t('campaign.offer_already_exist');
    }

}

</script>

<template>
    <form class="make-offer" v-on:submit.prevent="makeOffer">
        <fieldset>
            <legend>{{ $t('campaign.offer_name', 'Offer Name') }}</legend>
            <g-input v-on:update:modelValue="errorText = ''" v-model="payload.name" v-bind:error="errorText"/>
        </fieldset>

        <fieldset>
            <legend>{{ $t('base.description', 'Description') }}</legend>
            <g-textarea v-model="payload.description" label="..." rows="5"/>
        </fieldset>
        <g-flex gap="5" justify="center">
            <g-button class="cancel-btn" type="button" v-on:click="$emit('close')">{{ $t('base.cancel', 'Cancel') }}</g-button>
            <g-button class="save-btn" type="submit" v-bind:disabled="!!errorText || !isPayloadValid">{{ $t('base.make', 'Make') }}</g-button>
        </g-flex>
    </form>
</template>

<style scoped lang="scss">
.make-offer {
	width: 300px;
}

fieldset {
	margin: 12px 0;
	text-align: left;
}

.g-input,
.g-textarea {
    background-color: var(--body-bg, $body-bg);
}

.g-button {
	min-width: 100px;
    padding: 0 20px;
}
</style>