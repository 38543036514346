<script setup>

import { defineEmits, reactive, ref, computed } from 'vue';
import { useUserActions } from '@user';
import { useBuyerActions } from '@buyer';

const getAffiliates = useUserActions('getAffiliates');
const getBuyerResource = useBuyerActions('getBuyerResource');
const buyer = getBuyerResource();
const emit = defineEmits(['performed']);

const affiliates = ref([]);
const payload = reactive({
    name: '',
    user_id: null,
    type: 'CPL',
    cost: 1,
});

const isPayloadValid = computed(() => {
    return payload.name && payload.user_id && payload.type && payload.cost
});

getAffiliates().then(response => {
    affiliates.value = response.data.map(affiliate => {
        return {
            id: affiliate.id,
            name: affiliate.name,
        }
    });
});

function makeBuyer() {
    buyer.create(payload).then(() => {
        buyer.load()
        emit('performed');
    })
}

</script>

<template>
    <form class="make-buyer" v-on:submit.prevent="makeBuyer">
        <fieldset>
            <legend>{{ $t('campaign.affiliate', 'Affiliate') }}</legend>

            <g-select v-model="payload.user_id"
                      v-bind:options="affiliates"
                      option-text="name"
                      option-value="id"/>
        </fieldset>

        <fieldset>
            <legend>{{ $t('campaign.buyer_name', 'Buyer Name') }}</legend>
            <g-input v-model="payload.name"/>
        </fieldset>

        <fieldset>
            <legend>{{ $t('campaign.type', 'Type') }}</legend>
            <g-select v-model="payload.type"
                      v-bind:options="[{value: 'CPA'}, {value: 'CPL'}]"
                      option-text="value"
                      option-value="value"/>
        </fieldset>

        <fieldset>
            <legend>{{ $t('campaign.cost', 'Cost') }}</legend>
            <g-input v-model="payload.cost"/>
        </fieldset>

        <g-flex class="btn-bar" gap="5" justify="center">
            <g-button class="cancel-btn" type="button" v-on:click="$emit('close')">Cancel</g-button>
            <g-button class="save-btn" type="submit" v-bind:disabled="!isPayloadValid">Make</g-button>
        </g-flex>
    </form>
</template>

<style scoped lang="scss">
.make-buyer {
    gap: 10px;
    width: 320px;
    display: flex;
    flex-wrap: wrap;
}

fieldset {
    width: 48%;

    & > .g-input,
    & > .g-select {
        background-color: var(--body-bg, $body-bg);
    }
}

.btn-bar {
    margin-top: 20px;
    width: 100%;
}

.g-button {
    min-width: 100px;
    padding: 0 20px;
}
</style>