<script setup>

import { ref, defineEmits, reactive, computed } from 'vue';
import { useLeadActions } from '@lead';
import { useAssetActions } from "@asset";

const emit = defineEmits(['performed']);

const getLeadResource = useLeadActions('getLeadResource');
const lead = getLeadResource();

const getPairResource = useAssetActions('getPairResource');
const pairs = getPairResource();

const market = ref('crypto');
const symbol = ref('BTCUSDT');
const counter = ref('USDT');
const multiplier = ref('1%');

const multipliers = reactive({});
Object.assign(multipliers, lead.model.specifics.multipliers || {});

const marketCurrency = computed(() => {
    switch(market.value) {
        case 'crypto':
            counter.value = 'USDT';
            return [{c: 'USDT'}, {c: 'BTC'}, {c: 'ETH'}];
        case 'stocks':
            counter.value = 'USDT';
            return [{c: 'USDT'}];
        case 'forex':
        case 'commodities':
            counter.value = 'USD';
            return [{c: 'USD'}];
    }
});

function add() {
    multipliers[symbol.value] = (parseInt(multiplier.value) || 0) + '%';
    //lead.model.multipliers = multipliers;
    emit('performed', multipliers);
}
</script>

<template>
    <form class="add-multiplier" v-on:submit.prevent="add">

        <g-flex gap="8">
            <fieldset>
                <legend>{{ $t('trading.market', 'Market') }}</legend>
                <g-select v-model="market"
                          v-bind:options="[{m: 'crypto'}, {m: 'stocks'}, {m: 'commodities'}]"
                          option-value="m"
                          option-text="m"/>
            </fieldset>

            <fieldset>
                <legend>{{ $t('trading.counter_currency', 'Counter Currency') }}</legend>
                <g-select v-model="counter"
                          v-bind:options="marketCurrency"
                          option-value="c"
                          option-text="c"/>
            </fieldset>
        </g-flex>

        <g-flex gap="8" align="end">
            <g-fade-container icon="wallet" v-bind:label="symbol">
                <div class="assets">
                    <label v-for="asset in pairs.state.filter(pair => (pair.market === market && pair.counter === counter))" class="currency">
                        <input type="radio" v-model="symbol" name="currency" v-bind:value="asset.symbol">
                        <span> <g-symbol-currency v-bind:name="asset.base.toLowerCase()" width="22" height="22"/> {{ asset.base }}:{{ asset.counter }}</span>
                    </label>
                </div>
            </g-fade-container>

            <fieldset>
                <legend>{{ $t('lead.multiplier', 'Multiplier') }}</legend>
                <g-input v-model="multiplier"/>
            </fieldset>
        </g-flex>

        <g-flex gap="4" justify="end">
            <g-button class="cancel-btn" type="button" v-on:click="emit('performed')">{{ $t('base.cancel', 'Cancel') }}</g-button>
            <g-button class="save-btn" type="submit">{{ $t('base.add', 'Add') }}</g-button>
        </g-flex>
    </form>
</template>

<style lang="scss" scoped>
.add-multiplier {
    width: 300px;
    gap: 20px;
    display: flex;
    flex-direction: column;
}

.g-button {
    min-width: 100px;
}

.g-fade-container {
    width: 100%;

    &:deep(.icon) {
        fill: gold;
    }

    &:deep(.container) {
        padding: 0 20px;
        max-height: 280px;
        overflow-y: auto !important;
        background-color: var(--secondary, $secondary);

        & > .assets {
            column-count: 2;

            & > .currency {
                display: inline-block;
                width: 100%;
                cursor: pointer;
                white-space: nowrap;
                color: var(--main-text-color, $main-text-color);

                & > span {
                    gap: 10px;
                    display: flex;
                    align-items: center;
                }

                &:hover {
                    color: var(--title-text-color, $title-text-color);
                }
            }
        }
    }
}
</style>