<template>
    <div class="g-preloader">
        <div class="preloader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</template>

<script>

import { computed } from 'vue';

export default {
    name: 'g-preloader',
    props: {
        isGlobal: {
            type: Boolean,
            default: false,
        },
        isModal: {
            type: Boolean,
            default: false,
        }
    },
    setup(props) {

        const mode = computed(() => {
            return props.isGlobal
                ? 'fixed'
                : 'absolute';
        });

        const overlay = computed(() => {
            return props.isModal
                ? '#00000088'
                : 'transparent';
        });

        return {
            mode,
            overlay,
        };
    },
};
</script>

<style lang="scss" scoped>
.g-preloader {
    top: 50%;
    left: 50%;
    width: 80px;
    height: 80px;
    position: absolute;
    transform: translate(-50%, -50%);
    opacity: 0.5;
}

.g-preloader > div {
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 50%;
    border: 6px solid transparent;
    border-top-color: var(--preloader, $preloader);
    animation: rotate 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.g-preloader > div:nth-child(1) {
    animation-delay: -0.6s;
}

.g-preloader > div:nth-child(2) {
    animation-delay: -0.45s;
}

.g-preloader > div:nth-child(3) {
    animation-delay: -0.3s;
}

.g-preloader > div:nth-child(4) {
    animation-delay: -0.15s;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>