<script setup>
import { computed, watch, ref, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { i18n, AutoSaveStack, PopUpManager as PopUp } from '@/Ship';
import { useCampaignActions } from '@campaign';
import { useProfileActions } from '@profile';
import { useTeamActions } from "@team";
import { useOfferActions } from "@offer";
import { useUserActions } from "@user";
import { useBuyerActions } from "@buyer";
import { useBrandActions } from "@brand";
import { useCountryActions } from "@country";

//const CampaignForm = useCampaignComponents('CampaignForm');

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const getCampaignResource = useCampaignActions('getCampaignResource');
const campaign = getCampaignResource();

const getBrandResource = useBrandActions('getBrandResource');
const brands = getBrandResource();

const getTeamResource = useTeamActions('getTeamResource');
const teams = getTeamResource();

const getOfferResource = useOfferActions('getOfferResource');
const offers = getOfferResource();

const getBuyerResource = useBuyerActions('getBuyerResource');
const buyerResource = getBuyerResource();

const getCountryResource = useCountryActions('getCountryResource');
const countryResource = getCountryResource({ is_disabled: [0] });

const buyers = computed(() => {
    return buyerResource.state.filter(buyer => buyer.user_id === (payload.affiliate_id || campaign.model.affiliate_id));
});

const getAffiliates = useUserActions('getAffiliates');
const affiliates = ref([]);

getAffiliates().then(response => {
    affiliates.value = response.data;
});

const showAllCountries = ref(false);
const countries = computed(() => {
    return showAllCountries.value
        ? countryResource.state
        : countryResource.state.filter(country => country.is_favorite || campaign.model.geo.includes(country.code));
});

const router = useRouter();
const route = useRoute();

const languages = ref(['DE', 'EN', 'FR', 'PL', 'RU']);

const payload = reactive({});
const errors = reactive({
    name: false,
    alias: false,
    source: false,
    language: false,
    cost: false,
});

const isRender = ref(true);
watch(() => campaign.index, () => {
    isRender.value = false;
    setTimeout(() => {
        isRender.value = true;
    }, 0);
});

function applyComputed(key) {
    return computed({
        get: () => payload[key] || campaign.model[key],
        set: value => {
            if (campaign.model[key] === value) {
                delete payload[key];
            } else {
                payload[key] = value;
            }
        }
    });
}

const name = applyComputed('name');
const alias = applyComputed('alias');
const brand_id = applyComputed('brand_id');
const offer_id = applyComputed('offer_id');
const language = applyComputed('language');
const source = applyComputed('source');
const target_team = applyComputed('target_team');
const affiliate_id = applyComputed('affiliate_id');
const buyer_id = applyComputed('buyer_id');
const type = applyComputed('type');
const cost = applyComputed('cost');
const geo = applyComputed('geo');

function affiliateChoice() {
    if (buyers.value.length === 1) {
        buyerChoice(buyers.value[0].id);
    } else {
        buyers.index = null;
        buyer_id.value = null;
    }
}

function buyerChoice(id) {
    buyerResource.index = buyerResource.state.findIndex(buyer => buyer.id === id);
    buyer_id.value = buyerResource.model.id;
    type.value = buyerResource.model.type;
    cost.value = buyerResource.model.cost;
}

function removeCampaign() {
    PopUp
        .setCaption(i18n.global.t('campaign.remove_campaign', 'Remove Campaign'))
        .setMessage(`<p class="message"><b>${i18n.global.t('campaign.remove_confirm', { id: campaign.model.id }, 'The Campaign with ID:{id} will be permanently deleted')}!</b></p></p>`)
        .setAction(() => {
            campaign.delete(campaign.model.id).then(()=> {
                campaign.state.splice(campaign.index, 1);
                campaign.index = null;
                router.push({ name: 'Campaigns', params: route.params })
            });

            PopUp.close();
        })
        .open(require('@/Ship/components/PopUps/Confirm'));
}

function saveCampaign() {
    campaign.save(campaign.model.id, payload).then(() => {
        Object.keys(payload).forEach(key => {
            campaign.state[campaign.index][key] = payload[key];
            delete payload[key];
        });
    });
}

function reset() {
    Object.keys(payload).forEach(key => delete payload[key]);
}

const saveStack = AutoSaveStack.getInstance();
saveStack.push(campaign);
</script>

<template>
    <form class="campaign-form custom-scrollbar" v-on:submit.prevent="saveCampaign">
        <g-preloader-overlay v-if="campaign.isLoading"/>

        <g-flex align="center" justify="between" class="campaign-bar">
            <router-link class="close-btn" v-bind:to="{ name: 'Campaigns', params: $route.params }">
                <g-symbol name="close" width="24" height="24"/>
            </router-link>

            <g-caption size="4">ID: {{ campaign.model.id }}</g-caption>

            <g-button v-if="profile.can('Campaign Remove')"
                      class="remove-btn with-tooltip"
                      type="button"
                      v-on:click="removeCampaign"
                      v-bind:data-tooltip="$t('base.remove', 'Remove')">
                <g-symbol name="delete" width="18" height="18"/>
            </g-button>
        </g-flex>

        <template v-if="isRender">
            <g-flex class="container" justify="between" align="end" wrap>

                <fieldset>
                    <legend>{{ $t('settings.brand', 'Brand') }}</legend>
                    <g-select v-model="brand_id"
                              v-bind:options="profile.brands"
                              option-text="name"
                              option-value="id"
                              v-bind:readonly="profile.cant('Campaign Edit')"/>
                </fieldset>

                <fieldset></fieldset>

                <fieldset>
                    <legend>{{ $t('campaign.campaign_name', 'Campaign Name') }}</legend>
                    <g-input v-model="name"
                             v-bind:readonly="profile.cant('Campaign Edit')"
                             v-bind:error="errors.name">
                        <g-symbol v-if="profile.cant('Campaign Edit')" name="lock" width="18" height="18"/>
                    </g-input>
                </fieldset>

                <fieldset>
                    <legend>{{ $t('campaign.alias', 'Alias') }}</legend>
                    <g-input v-model="alias" v-bind:readonly="profile.cant('Campaign Edit')">
                        <g-symbol v-if="profile.cant('Campaign Edit')" name="lock" width="18" height="18"/>
                    </g-input>
                </fieldset>

                <fieldset>
                    <legend>{{ $t('campaign.offer', 'Offer') }}</legend>
                    <g-select v-model="offer_id"
                              v-bind:options="offers.state"
                              option-text="name"
                              option-value="id"
                              v-bind:readonly="profile.cant('Campaign Edit')"/>
                </fieldset>

                <fieldset>
                    <legend>{{ $t('base.language', 'Language') }}</legend>
                    <g-combobox v-model="language"
                                v-bind:options="languages"
                                v-bind:disabled="profile.cant('Campaign Edit')"/>
                </fieldset>

                <fieldset>
                    <legend>{{ $t('campaign.source', 'Source') }}</legend>
                    <g-input v-model="source" v-bind:readonly="profile.cant('Campaign Edit')">
                        <g-symbol v-if="profile.cant('Campaign Edit')" name="lock" width="18" height="18"/>
                    </g-input>
                </fieldset>

                <fieldset>
                    <legend>{{ $t('campaign.assign_team', 'Assign team') }}</legend>
                    <g-select v-model="target_team"
                              v-bind:options="teams.state"
                              option-text="name"
                              option-value="id"
                              v-bind:readonly="profile.cant('Campaign Edit')"/>
                </fieldset>
            </g-flex>

            <!-- GEO -->
            <fieldset class="geo container">
                <legend>{{ $t('campaign.geo') }}</legend>

                <div class="countries">
                    <g-checkbox v-model="geo"
                                v-for="country in countries"
                                v-bind:key="`country-${country.code}`"
                                v-bind:value="country.code"
                                v-bind:disabled="profile.cant('Campaign Edit')">
                        {{ $t(`countries.${country.code}`) }}
                    </g-checkbox>
                </div>

                <g-link-button type="button"
                               class="show-countries-btn"
                               v-bind:class="{ active: showAllCountries }"
                               v-on:click="showAllCountries = !showAllCountries">
                    {{
                        showAllCountries
                            ? $t('countries.hide_fav', 'Hide not favorite Countries')
                            : $t('countries.show_all', 'Show all Countries')
                    }}
                </g-link-button>
            </fieldset>

            <g-flex class="container" justify="between" align="end" wrap>
                <fieldset>
                    <legend>{{ $t('campaign.affiliate', 'Affiliate') }}</legend>
                    <g-select v-model="affiliate_id"
                              v-bind:options="affiliates"
                              option-text="name"
                              option-value="id"
                              v-on:select="affiliateChoice"
                              v-bind:readonly="profile.cant('Campaign Edit')"/>
                </fieldset>

                <fieldset>
                    <legend>{{ $t('campaign.buyer', 'Media Buyer') }}</legend>
                    <g-select v-model="buyer_id"
                              v-bind:options="buyers"
                              option-text="name"
                              option-value="id"
                              v-on:select="buyerChoice"
                              v-bind:readonly="profile.cant('Campaign Edit')"/>
                </fieldset>

                <fieldset>
                    <legend>{{ $t('campaign.type', 'Type') }}</legend>
                    <g-select v-model="type"
                              v-bind:options="[{value: 'CPA'}, {value: 'CPL'}]"
                              option-text="value"
                              option-value="value"
                              v-bind:readonly="profile.cant('Campaign Edit')"/>
                </fieldset>

                <fieldset>
                    <legend>{{ $t('campaign.cost', 'Cost') }}</legend>
                    <g-input v-model="cost" v-bind:readonly="profile.cant('Campaign Edit')">
                        <g-symbol v-if="profile.cant('Campaign Edit')" name="lock" width="18" height="18"/>
                    </g-input>
                </fieldset>
            </g-flex>
        </template>

        <g-flex class="btn-bar" justify="end" gap="20">
            <g-link-button type="button" v-bind:disabled="!Object.keys(payload).length" v-on:click="reset">
                <g-symbol name="refresh" width="20" height="20"/>
                {{ $t('base.reset', 'Reset') }}
            </g-link-button>
            <g-link-button type="submit" v-bind:disabled="!Object.keys(payload).length">
                <g-symbol name="save" width="20" height="20"/>
                {{ $t('base.save', 'Save') }}
            </g-link-button>
        </g-flex>
    </form>
</template>

<style lang="scss" scoped>
.campaign-form {
    overflow: auto;
}

.campaign-bar {
    padding: 0 10px;
    height: var(--bar-height, $bar-height);
    color: var(--env-text-color, $env-text-color);
}

.remove-btn {
    width: 35px;
    height: 35px;
    font-size: 0;
    position: relative;
    background-color: transparent;
    fill: var(--env-text-color, $env-text-color);

    &::after {
        left: 30%;
        transform: translateX(-50%);
        top: calc(100% + var(--tooltip-arrow-size, $tooltip-arrow-size));
    }

    &::before {
        transform: translateX(-50%);
        left: 50%;
        top: calc(100% - var(--tooltip-arrow-size, $tooltip-arrow-size));
    }

    &:hover {
        fill: var(--btn-primary-color, $btn-primary-color);
        border-color: var(--danger, $danger);
        background-color: var(--danger, $danger);

        &::after {
            background-color: var(--danger, $danger);
        }

        &::before {
            border-bottom-color: var(--danger, $danger);
        }
    }
}

.container {
    width: 560px;
    margin: 20px auto;
    color: var(--env-text-color, $env-text-color);

    & > fieldset {
        width: 48%;
        margin: 4px 0;
    }
}

.geo {
    position: relative;
    border-radius: var(--bar-rounding, $bar-rounding);
    border: 1px solid var(--separator-color, $separator-color);
    background-color: var(--input-active-bg, $input-active-bg);

    & > legend {
        margin-left: 16px;
    }

    & > .countries {
        padding: 16px 20px;
        flex-grow: 1;
        height: 200px;
        column-count: 4;
        overflow-y: auto;

        & > .g-checkbox {
            display: inline-block;
            overflow: hidden;
            width: 100%;
            max-width: 120px;
            white-space: nowrap;
        }
    }

    .show-countries-btn {
        position: absolute;
        left: 50%;
        bottom: 8px;
        transform: translateX(-50%);

        &.active {
            color: var(--danger, $danger);
        }
    }
}

.g-input,
.g-combobox:deep(.field),
.g-select:deep(.field) {
    &.filled,
    &:focus-within {
        background-color: var(--input-active-bg, $input-active-bg);
    }
}

.btn-bar {
    width: 560px;
    margin: 20px auto;

    & > .g-link-button {
        font-size: 1.2rem;
    }
}

.close-btn {
    fill: var(--env-text-color, $env-text-color);

    &:hover {
        fill: var(--env-title-color, $env-title-color);
    }
}
</style>