<script setup>
import { defineEmits, computed } from 'vue';
import { useLeadActions } from '@lead';
import { useSettingsActions } from "@settings";

const emit = defineEmits(['performed']);

const getLeadResource = useLeadActions('getLeadResource');
const lead = getLeadResource();

const getSettingsResource = useSettingsActions('getSettingsResource');
const settings = getSettingsResource();

const cryptoFee = computed({
    get: () => lead.model.specifics?.crypto_fee || settings.general?.defaultCryptoFee || 0.001,
    set: value => {
        const specifics = lead.model.specifics;
        specifics.crypto_fee = value;
        lead.model.specifics = specifics;

        return true;
    }
});

const stockFee = computed({
    get: () => lead.model.specifics?.stock_fee || settings.general?.defaultStockFee || 0.001,
    set: value => {
        const specifics = lead.model.specifics;
        specifics.stock_fee = value;
        lead.model.specifics = specifics;

        return true;
    }
});

const commodityFee = computed({
    get: () => lead.model.specifics?.commodity_fee || settings.general?.defaultCommodityFee || 0.001,
    set: value => {
        const specifics = lead.model.specifics;
        specifics.commodity_fee = value;
        lead.model.specifics = specifics;

        return true;
    }
});

const minAmount = computed({
    get: () => lead.model.specifics?.min_amount || settings.general?.defaultMinDealAmount || 100,
    set: value => {
        const specifics = lead.model.specifics;
        specifics.min_amount = value;
        lead.model.specifics = specifics;

        return true;
    }
});

const can_open_position = computed({
    get: () => lead.model.specifics.can_open_position === undefined ? true : lead.model.specifics.can_open_position,
    set: value => {
        const specifics = lead.model.specifics;
        specifics.can_open_position = value;
        lead.model.specifics = specifics;

        return true;
    }
});


const can_close_position = computed({
    get: () => lead.model.specifics.can_close_position === undefined ? true : lead.model.specifics.can_close_position,
    set: value => {
        const specifics = lead.model.specifics;
        specifics.can_close_position = value;
        lead.model.specifics = specifics;

        return true;
    }
});

function save() {
    lead.save();
    emit('performed');
}

</script>

<template>
    <form v-on:submit.prevent="save">

        <div class="controls">
            <div class="amount-container">
                <g-flex class="can-trade" align="center" gap="8">
                    <label>{{ $t('trading.can_open_position', 'Can open positions') }}</label>

                    <g-switch v-model:checked="can_open_position"/>

                    <span class="state" v-bind:class="can_open_position ? 'on' : 'off'">
                        {{ can_open_position ? $t('base.yes') : $t('base.no') }}
                    </span>
                </g-flex>

                <g-flex class="can-trade" align="center" gap="8">
                    <label>{{ $t('trading.can_close_position', 'Can close positions') }}</label>

                    <g-switch v-model:checked="can_close_position"/>

                    <span class="state" v-bind:class="can_close_position ? 'on' : 'off'">
                        {{ can_close_position ? $t('base.yes') : $t('base.no') }}
                    </span>
                </g-flex>

                <fieldset>
                    <legend>{{ $t('trading.min_deal_amount', 'Min deal amount') }}</legend>
                    <g-input v-model="minAmount"/>
                </fieldset>
            </div>

            <div class="fees">
                <fieldset>
                    <legend>{{ $t('lead.crypto_fee', 'Crypto fee') }}</legend>
                    <g-input v-model="cryptoFee"/>
                </fieldset>

                <fieldset>
                    <legend>{{ $t('lead.stock_fee', 'Stock fee') }}</legend>
                    <g-input v-model="stockFee"/>
                </fieldset>

                <fieldset>
                    <legend>{{ $t('lead.commodity_fee', 'Commodity fee') }}</legend>
                    <g-input v-model="commodityFee"/>
                </fieldset>
            </div>
        </div>
        <div class="btn-bar">
            <g-button class="cancel-btn" type="button" v-on:click="$emit('close')">{{ $t('base.cancel', 'Cancel') }}</g-button>
            <g-button class="save-btn" type="submit">{{ $t('base.save', 'Save') }}</g-button>
        </div>
    </form>
</template>

<style scoped lang="scss">
form {
    max-width: 340px;
}

.btn-bar {
    text-align: right;
    padding-top: 10px;

    & > .g-button {
        padding: 0 20px;
        margin: 0 2px;
        min-width: 80px;
    }
}

.can-trade {
    line-height: 14px;

    & > .state {
        width: 30px;

        &.on {
            color: var(--success-hover, $success-hover);
        }

        &.off {
            color: var(--disabled-color, $disabled-color);
        }
    }
}

.fees > fieldset {
    width: 150px;
    margin-bottom: 10px;
}

.g-switch {
    flex-shrink: 0;
}

@media (min-width: 485px) {
    .controls {
        gap: 20px;
        display: flex;
        align-items: stretch;
        flex-direction: row-reverse;
    }

    .amount-container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: stretch;
        padding-bottom: 18px;
    }
}

@media (max-width: 484px) {
    .amount-container {
        padding-bottom: 16px;
        border-bottom: 1px solid var(--separator-color, $separator-color);
    }
}
</style>