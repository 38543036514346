<script setup>

import { useRouter } from 'vue-router';
import { useArchiveActions, useArchiveComponents } from '@archive';
import { useProfileActions } from '@profile';

import filters from '@archive/resources/FilterState';

const DateFilter = useArchiveComponents('DateFilter');

const router = useRouter();

const getArchiveResource = useArchiveActions('getArchiveResource');
const archive = getArchiveResource();

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

if (!profile.state.archive) {
    profile.state.archive = {
        columns: [],
        filters: {},
        sortBy: 'id',
        sortDir: 'desc',
        perPage: 25,
    };
}


Object.assign(filters, profile.state.archive.filters);


archive.setFilters(filters);

function filtrate() {
    archive.setFilters(filters);
    archive.setPage(1);
    archive.load();

    profile.state.archive.filters = filters;
    profile.saveState();
}

function getFilterValueByKey(filter, key) {
    if (archive.filterset[filter]) {

        key = String(key).split(',').pop();
        const filterset = archive.filterset[filter].find(item => item.id === Number(key) || item.value == key);

        let value;
        switch (filter) {
            case 'campaigns':
                value = profile.can('Lead RealCampaignView')
                    ? filterset?.name || key
                    : filterset?.alias || key;
                break;
            default:
                value = filterset
                    ? filterset.key || filterset.value || filterset.name
                    : key || 'Null';
                break;
        }

        return value;
    }
    return key;
}

function dropFilter(filter, value) {
    archive.dropFilterValue(filter, value);
    archive.setPage(1);
    archive.load();

    profile.state.archive.filters = archive.filters;
    profile.saveState();
}

function resetAllFilters() {
    Object.keys(archive.filters).forEach(key => {
        const valuesClone = archive.filters[key].slice();
        valuesClone.forEach(value => {
            archive.dropFilterValue(key, value);
        });
    });

    Object.assign(profile.state.archive.filters, archive.filters);

    archive.setFilters(archive.filters);
    archive.setPage(1);
    archive.load();

    profile.saveState();
}

function find(value) {
    const key = Object.keys(value).pop();

    if ((key === 'id' && value[key].length) || value[key].length > 2) {
        archive.find(value);
    }

}
</script>

<template>
    <g-flex class="toolbar" gap="5" align="end" justify="between">
        <g-search-input v-bind:label="$t('base.search')" v-on:find="find" v-on:drop="archive.load(null, {}, 'LeadEnv Search')"/>
        <date-filter/>
    </g-flex>


    <g-flex class="filters" align="end" gap="4" wrap="wrap">
        <fieldset v-for="(values, key) in archive.filters"
             class="filter"
             v-bind:class="key"
             v-bind:key="key">
            <legend>{{ $t(`filters.${key}`, key) }}:</legend>

            <div class="filter-item" v-for="(value, i) in values" v-bind:key="`${key}-${i}`">
                <div class="value">{{ getFilterValueByKey(key, value) }}</div>
                <g-symbol name="close" width="14" height="14" v-on:click="dropFilter(key, value)"/>
            </div>
        </fieldset>

        <div v-if="Object.keys(archive.filters).length > 0" class="filter reset-all">
            <div class="filter-item" v-on:click="resetAllFilters">
                <div class="value">Reset All</div>
                <g-symbol name="close" width="14" height="14"/>
            </div>
        </div>
    </g-flex>

    <!-- CONVERSION STATUS -->
    <g-filterset v-model="filters.conversion_status"
                 v-bind:label="$t('filters.conversion_status', 'Conversion status')"
                 v-bind:filterset="archive.filterset.conversion_status"
                 v-on:change="filtrate"/>

    <!-- RETENTION STATUS -->
    <g-filterset v-model="filters.retention_status"
                 v-bind:label="$t('filters.retention_status', 'Retention status')"
                 v-bind:filterset="archive.filterset.retention_status"
                 v-on:change="filtrate"/>

    <!-- TEAM -->
    <g-filterset v-model="filters.teams"
                 v-bind:label="$t('filters.teams', 'Teams')"
                 v-bind:filterset="archive.filterset.teams"
                 value-field="id"
                 key-field="value"
                 v-on:change="filtrate"/>

    <!-- AFFILIATE -->
    <g-filterset v-model="filters.affiliates"
                 v-bind:label="$t('filters.affiliates', 'Affiliates')"
                 v-bind:filterset="archive.filterset.affiliates"
                 value-field="id"
                 v-on:change="filtrate"/>

    <!-- SALE -->
    <g-filterset v-model="filters.sales"
                 v-bind:label="$t('filters.sales', 'Sale agents')"
                 value-field="id"
                 v-bind:filterset="archive.filterset.sales"
                 v-on:change="filtrate"/>

    <!-- RETENTION -->
    <g-filterset v-model="filters.retentions"
                 v-bind:label="$t('filters.retentions', 'Retention agents')"
                 value-field="id"
                 v-bind:filterset="archive.filterset.retentions"
                 v-on:change="filtrate"/>

    <!-- LANGUAGE -->
    <g-filterset v-model="filters.language"
                 v-bind:label="$t('filters.languages', 'Languages')"
                 v-bind:filterset="archive.filterset.language"
                 v-on:change="filtrate"/>

    <!-- COUNTRY -->
    <g-filterset v-model="filters.countries"
                 v-bind:label="$t('filters.countries', 'Countries')"
                 value-field="code"
                 v-bind:filterset="archive.filterset.countries"
                 v-on:change="filtrate"/>

    <!-- CAMPAIGN -->
    <g-filterset v-model="filters.campaigns"
                 v-bind:label="$t('filters.campaigns', 'Campaigns')"
                 value-field="id"
                 v-bind:key-field="profile.can('Lead RealCampaignView') ? 'name' : 'alias'"
                 v-bind:filterset="archive.filterset.campaigns"
                 v-on:change="filtrate"/>
</template>

<style lang="scss" scoped>
.toolbar {
    z-index: 2;
    top: 0;
    position: sticky;
    margin: -20px -29px 0 -25px;
    padding: 6px 10px 20px;
    border-radius: 5px;
    background-color: var(--secondary, $secondary);
    box-shadow: 0 5px 15px -4px #000000aa;

    .g-search-input {
        flex-grow: 1;
    }

    .tool {
        font-size: 0;
        cursor: pointer;
        position: relative;
        background-color: transparent;
        border-radius: var(--btn-rounding, $btn-rounding);
        fill: var(--title-text-color, $title-text-color);

        &:hover {
            transform: scale(1.2);

            &::after {
                white-space: nowrap;
                transform: translateX(-50%);
                top: calc(100% + var(--tooltip-arrow-size, $tooltip-arrow-size));
                left: 50%;
            }

            &::before {
                transform: translateX(-50%);
                left: 50%;
                top: calc(100% - var(--tooltip-arrow-size, $tooltip-arrow-size));
                border-bottom-color: var(--tooltip-bg, $tooltip-bg);
            }
        }

        &:first-child:hover::after {
            transform: translateX(-24%);
        }

        &:last-child:hover::after {
            transform: translateX(-78%);
        }
    }
}

.g-search-input {
    flex-grow: 1;
    color: var(--title-text-color, $title-text-color);
}

.filters {
    padding: 10px 0;
    margin: 0 -10px;
}

.filter {
    gap: 4px;
    flex-flow: wrap;
    padding: 4px 0;
    font-size: 0.8rem;
    border-radius: 5px;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    color: var(--sidebar-text-color, $sidebar-text-color);

    & > .filter-item {
        gap: 2px;
        display: inline-flex;
        align-items: center;
        height: 28px;
        color: white;
        border-radius: 5px;
        padding: 0 4px 1px 8px;
        box-shadow: 0 5px 15px -10px #000000aa;

        &:first-child {
            margin-left: 5px;
        }

        & > .g-symbol {
            cursor: pointer;
            fill: var(--btn-primary-color, $btn-primary-color);
        }

        & > .value {
            font-weight: 500;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 56px;
        }
    }

    &.reset-all > .filter-item {
        cursor: pointer;
        background-color: black;
    }

    &.is_ftd > .filter-item {
        background-color: #eaa308;
    }

    &.created_at > .filter-item {
        background-color: var(--primary-hover, $primary-hover);
    }

    &.ftd_date > .filter-item {
        background-color: var(--secondary-hover, $secondary-hover);
    }

    &.conversion_status > .filter-item {
        background-color: #22a585;
    }

    &.retention_status > .filter-item {
        background-color: #b010df;
    }

    &:not(.is_ftd, .created_at, .ftd_date, .conversion_status, .retention_status, .reset-all) > .filter-item {
        background-color: var(--danger-hover, $danger-hover);
    }
}
</style>