<script setup>

import { computed, ref, defineEmits } from 'vue';
import { useUserActions } from '@user';
import { useProfileActions } from '@profile';
import { useSettingsActions } from '@settings';

const impersonate = useProfileActions('impersonate');

const emit = defineEmits(['performed', 'close']);

const getSettingsResource = useSettingsActions('getSettingsResource');
const settings = getSettingsResource('roles');

const getUserResource = useUserActions('getUserResource');
const users = getUserResource();
if (!users.isLoaded && !users.isLoading) {
    users.load();
}

const role = ref();
const id = ref();

const userList = computed(() => users.state.filter(user => {
    return user.roles.map(role => role.name).includes(role.value);
}));
function onImpersonate() {
    impersonate(id.value)
    emit('close');
};

</script>

<template>
    <form class="impersonate" v-on:submit.prevent="onImpersonate">
        <fieldset>
            <legend>Role</legend>
            <g-select v-model="role"
                      v-bind:options="settings.roles || []"
                      option-text="name"
                      option-value="name"/>
        </fieldset>

        <fieldset>
            <legend>{{ $t('parts.users', 'Users') }}</legend>
            <g-select v-model="id"
                      v-bind:options="userList"
                      option-text="name"
                      option-value="id"
                      v-bind:disabled="!role"/>
        </fieldset>

        <g-flex class="btn-bar" gap="5" justify="center">
            <g-button type="submit" class="save-btn" v-bind:disabled="!id">
                {{ $t('profile.impersonate', 'Impersonate') }}
            </g-button>
        </g-flex>
    </form>
</template>


<style scoped lang="scss">
.impersonate {
    width: 300px;
}

fieldset {
    margin: 8px 0;
}

.btn-bar {
    margin-top: 40px;
}

.g-button {
    padding: 0 20px;
}

.g-select {
    background-color: var(--body-bg, $body-bg);
}
</style>