import { useAssetResources } from '@asset';

export default () => {
    const asset = useAssetResources('Asset');
    if (!asset.isLoaded && !asset.isLoading) {
        //asset.setIncludes([]);
        asset.load();
    }

    return asset;
};
