<script setup>
import { useLeadActions } from '@lead';
import { useActivityActions } from '@activity';

const getLeadResource = useLeadActions('getLeadResource');
const lead = getLeadResource();

const getActivityResource = useActivityActions('getActivityResource');
const activity = getActivityResource(
    {filters: { lead_id: [lead.model.id] },},
    true,
    'LeadActivity Component'
);
</script>

<template>
    <div class="activity">
        <fieldset class="action" v-for="entry in activity.state" v-bind:key="`entry-${entry.id}`">
            <legend class="caption">
                <span class="login_at">SignUp: <span class="success">{{ entry.created_at }}</span></span>
                <span v-if="entry.logout_at" class="logout_at">SignOut: <span class="danger">{{ entry.logout_at }}</span></span>
            </legend>
            <div v-bind:class="entry.type.toLowerCase()">{{ entry.type === 'Manual' ? 'Manual SignIn' : entry.type }}</div>
            <g-flex class="client" align="center" gap="8">
                <span class="platform desktop">{{ entry.platform }}</span> /
                <span class="device">{{ entry.device }}</span> /
                <span class="os">{{ entry.os }}</span> /
                <span class="browser">{{ entry.browser }}</span> /
                <span class="ip">{{ entry.ip }}</span>
            </g-flex>
        </fieldset>
    </div>
</template>

<style scoped lang="scss">
.activity {
    margin-left: 60px;
    border-left: 1px solid var(--separator-color);
}

.action {
    margin: 20px 0;
    padding: 10px 0 10px 20px;
    position: relative;
    border-top: 1px solid var(--separator-color, $separator-color);
    color: var(--env-text-color, $env-text-color);

    & > .caption {
        width: 100%;

        &::before {
            content: '';
            position: absolute;
            top: -20px;
            left: -5px;
            width: 10px;
            height: 10px;
            border-radius: 5px;
            display: inline-block;
            background-color: var(--separator-color, $separator-color);
        }

        & > .login_at,
        & > .logout_at {
            color: var(--env-text-color, $env-text-color);
            border: 1px solid var(--separator-color, $separator-color);
            padding: 3px 8px;
            margin-right: 20px;
            display: inline-block;
            position: relative;
        }

        & > .logout_at::before {
            content: '';
            position: absolute;
            width: 20px;
            height: 1px;
            top: 50%;
            left: -20px;
            background-color: var(--separator-color, $separator-color);
        }
    }

    .ip,
    .platform {
        font-weight: bold;
        color: var(--env-title-color, $env-title-color);
    }
}

.manual {
    color: var(--success, $success);
}

.impersonate {
    color: var(--warning, $warning);
}

.autologin {
    color: var(--danger, $danger);
}

.success {
    color: var(--success, $success);
}

.warning {
    color: var(--warning, $warning);
}

.danger {
    color: var(--danger, $danger);
}
</style>