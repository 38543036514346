<script setup>
import { computed, reactive, ref } from 'vue';
import { useProfileActions } from '@profile';
import { useSettingsActions } from '@settings';

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const payload = reactive({
    name: '',
    target: ''
});

const getSettingsResource = useSettingsActions('getSettingsResource');
const settings = getSettingsResource('statuses');

const target = ref('All');
const targets = [
    { name: 'conversion' },
    { name: 'retention' },
    { name: 'KYC' },
    { name: 'deposit' },
    { name: 'withdraw' }
];

const targetFilters = [
    { name: 'All' },
    ...targets
];

const statuses = computed(() => {
    return target.value !== 'All'
        ? settings.statuses.filter(status => status.target === target.value.toLowerCase())
        : settings.statuses;
});

const setTarget = () => {
    if (target.value !== 'All') {
        payload.target = target.value;
    }
};

async function removeStatus(id) {
    const index = settings.statuses.findIndex(item => item.id === id);
    if (index !== -1) {

        settings.delete('status', { status_id: settings.statuses[index].id }).then(() => {
            settings.statuses.splice(index, 1);
        });
    }
}

async function addStatus() {
    if (payload.name && payload.target) {
        const capitalizedStatusName = payload.name.charAt(0).toUpperCase() + payload.name.slice(1);

        settings.create({ name: capitalizedStatusName, target: payload.target, section: 'status'  }).then(response => {
            settings.statuses.push(response);
            payload.name = '';
            payload.target = '';
        });
    }
}
</script>

<template>
    <div class="statuses">

        <g-preloader-overlay v-if="settings.isLoading"/>

        <g-caption size="4" weight="600">{{ $t('settings.statuses') }}</g-caption>

        <fieldset>
            <legend>{{ $t('settings.target', 'Target') }}</legend>
            <g-select v-model="target"
                      v-on:change="setTarget"
                      class="filter"
                      v-bind:options="targetFilters"
                      text-transform="capitalize"
                      option-text="name"
                      option-value="name"/>
        </fieldset>

        <div class="container custom-scrollbar">
            <table>
                <tbody>
                <tr v-for="(status, i) in statuses" v-bind:key="`status-${status.id}`" class="row">
                    <td class="cell index">{{ i + 1 }}</td>
                    <td class="cell status">{{ status.name }}</td>
                    <td class="cell target">{{ status.target }}</td>
                    <td class="cell actions" valign="middle">
                        <g-symbol name="close"
                                  width="16"
                                  height="16"
                                  class="remove-btn"
                                  v-on:click="removeStatus(status.id)"/>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <form v-on:submit.prevent="addStatus">
            <g-flex align="end" justify="flex-start" gap="5">
                <fieldset>
                    <legend>{{ $t('settings.target', 'Target') }}</legend>
                    <g-select v-model="payload.target"
                              v-bind:options="targets"
                              text-transform="capitalize"
                              option-text="name"
                              option-value="name"/>
                </fieldset>
                <fieldset>
                    <legend>{{ $t('settings.new_status', 'New status') }}</legend>
                    <g-input v-model="payload.name"
                             v-bind:disabled="!payload.target || profile.cant('Settings StatusEdit')"
                             v-on:keyup.enter="addStatus"/>
                </fieldset>

                <g-button type="button"
                          v-on:click="addStatus"
                          class="save-btn"
                          v-bind:disabled="!payload.name || profile.cant('Settings StatusEdit')">
                    <g-symbol name="send" width="20" height="20"/>
                </g-button>
            </g-flex>
        </form>
    </div>
</template>


<style scoped lang="scss">
.statuses {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 20px 0;
    color: var(--env-text-color, $env-text-color);
    border-radius: var(--bar-rounding, $bar-rounding);
    border: 1px solid var(--separator-color, $separator-color);
    max-height: 566px;

    & > .container {
        flex-grow: 1;
    }
}

table {
    width: 100%;
    margin-top: 10px;
}

.row {
    &:nth-child(even) {
        background-color: #00000011;
    }

    & > .cell {
        padding: 8px;

        &:last-child {
            text-align: right;
            font-size: 0;
        }
    }
}

.remove-btn {
    cursor: pointer;
    fill: var(--danger, $danger);

    &:hover {
        fill: var(--danger-hover, $danger-hover);
    }
}

form {
    color: white;
    padding: 15px 20px;
    border-radius: 12px 12px 0 0;
    background-color: var(--primary, $primary);
    box-shadow: 0 -5px 15px -5px #00000055;

    .g-select {
        width: 130px;
    }
}

.save-btn {
    padding: 0 15px;
}
</style>