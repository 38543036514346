import { reactive } from 'vue';

const STATE = reactive({
    LOADING: false,
    IS_LOADING: false,
    IS_PROCESSING: false,
    IS_LOGIN: true,
    IS_VISIBLE: true,
    MODE: 'light',
    AUTO_SAVE_INDICATOR: 0,

    FEE: 0.001,
    MIN_AMOUNT: 100,

    MARKET: 'Crypto',
    SYMBOL: 'BTCUSDT',
});

export default STATE;
