<script setup>

import { defineProps, defineEmits } from 'vue';
import { useFinanceResources } from '@finance';
import { i18n, PopUpManager as PopUp } from '@/Ship';

const props = defineProps(['total']);
const emit = defineEmits(['update:total']);

const withdraws = useFinanceResources('Withdraw');
withdraws.setIncludes(['user','lead']);
withdraws.sort('id', 'desc');
//deposits.setFilters(filters)

withdraws.onload = () => {
    emit('update:total', {
        count: withdraws.state.length,
        sum: Math.round(withdraws.state.reduce((a, b) => a + Number(b.amount), 0))
    });
};

withdraws.load(null, {}, 'Withdrawals Component');

const statuses = [
    {slug: 'new', name: 'New'},
    {slug:'approved', name: 'Approved'},
    {slug: 'auto_approved', name: 'Auto approved'},
    {slug: 'processed', name: 'Processed'},
    {slug: 'error', name: 'Error'},
    {slug: 'canceled', name: 'Canceled'}
];

function changeStatus(index, value) {
    const previousValue = withdraws.state[index].status;
    withdraws.save(withdraws.state[index].id, {
        status: value
    });
}

</script>

<template>
    <div class="withdrawals">
        <div v-for="(withdraw, i) in withdraws.state"
             v-bind:key="`deposit-${withdraw.id}`"
             class="row deposit"
             v-bind:class="[withdraw.type]"
             v-bind:data-type="withdraw.type || 'Deposit'">

            <!-- METHOD -->
            <div class="cell method" v-bind:class="withdraw.method">
                {{ withdraw.method }}
            </div>
            <!--/ METHOD -->

            <!-- LEAD -->
            <div class="cell lead">
                <div class="title">Lead: {{ withdraw.lead_id }}</div>
                <router-link v-if="!!withdraw.lead" class="lead-link" v-bind:to="{ name: 'Lead', params: {id: withdraw.lead_id} }">
                    {{ withdraw.lead.first_name }} {{ withdraw.lead.last_name }}
                </router-link>
                <span v-else class="danger">Archived lead</span>
            </div>
            <!--/ LEAD -->

            <!-- AMOUNT -->
            <div class="cell amount">
                <template v-if="!['USDT','USD'].includes(withdraw.currency)">
                    <div class="title">Amount:</div>
                    {{ Number((withdraw.amount / withdraw.rate).toFixed(5)) }}
                    <small class="currency" v-bind:class="withdraw.currency.toLowerCase()">{{ withdraw.currency }}</small>
                    <small v-if="withdraw.rate !== 1" class="rate"> ✕ {{ withdraw.rate }}</small>
                    =
                </template>
                {{ Number(withdraw.amount).toFixed(2) }} <small class="currency usdt">USDT</small>
            </div>
            <!--/ AMOUNT -->

            <!-- ADDRESS -->
            <div v-if="withdraw.method === 'card'" class="cell address">
                <div class="title">{{ withdraw.details?.card_name || 'n/a' }}</div>
                <g-copy  v-bind:text="withdraw.details?.card_number || 'n/a'"></g-copy>
            </div>
            <div v-else-if="withdraw.method === 'paypal'" class="cell address">
                <div class="title">E-Mail:</div>
                <g-copy v-bind:text="withdraw.details?.paypal_email || 'n/a'"></g-copy>
            </div>
            <div v-else-if="withdraw.method === 'bank'" class="cell address">
                <div class="title">SWIFT/BIC</div>
                <g-copy class="value" v-bind:text="withdraw.details?.swift || 'n/a'"></g-copy>
            </div>
            <div v-else class="cell address">
                <div class="title">Address:</div>
                <g-copy class="value" v-bind:text="withdraw.address"></g-copy>
            </div>
            <!--/ ADDRESS -->

            <!-- AGENT -->
            <div class="cell">
                <div class="title">Edited by:</div>
                {{ withdraw.user?.name || '' }}
            </div>
            <!--/ AGENT -->

            <!-- STATUS -->
            <div class="cell status" v-bind:class="withdraw.status">
                <template v-if="withdraw.status === 'processed'">Processed</template>
                <g-select v-else
                          v-model="withdraw.status"
                          label="Status"
                          option-text="name"
                          option-value="slug"
                          v-bind:options="statuses"
                          v-on:select="changeStatus(i, $event)"
                          v-bind::disabled="withdraw.status === 'refund'"/>
            </div>
            <!--/ STATUS -->

            <!-- DATE -->
            <div class="cell date">
                <div class="title">Date:</div>
                {{ withdraw.created_at }}
            </div>
            <!--/ DATE -->
        </div>
    </div>
</template>

<style scoped lang="scss">
.withdrawals {
    padding: 18px;
    display: table;
    min-width: 100%;

    & > .row {
        font-size: 0.9rem;
        padding: 4px;
        display: table-row;
        white-space: nowrap;

        &:nth-child(even) {
            background-color: #00000022;
        }

        & > .cell {
            padding: 4px 10px;
            display: table-cell;
            vertical-align: middle;

            &.method {
                font-size: 1.1rem;
                text-transform: uppercase;
            }

            &.address {
                max-width: 180px;
            }

            &.amount {
                white-space: nowrap;
            }

            &.date {
                width: 140px;
            }

            &.status {
                width: 120px;

                & > .g-select:deep(label) {
                    border: none;
                }

                & > .g-select:deep(input) {
                    padding-left: 0;
                    font-size: 0.9rem;
                }

                &.new > .g-select {
                    color: var(--primary-hover, $primary-hover);
                }

                &.approved > .g-select,
                &.auto_approved > .g-select {
                    color: var(--warning-hover, $warning-hover);
                }

                &.processed {
                    color: var(--success-hover, $success-hover);
                }

                &.error > .g-select {
                    color: var(--danger-hover, $danger-hover);
                }

                &.canceled > .g-select {
                    color: var(--disabled-color, $disabled-color);
                }
            }
        }
    }

    .title {
        font-size: 0.8rem;
        color: var(--label-color, $label-color);
    }
}

.test {
    color: var(--disabled-color, $disabled-color);
}

.lead-link {
    color: var(--secondary, $secondary);
    text-decoration: underline;

    &:hover {
        color: var(--secondary-hover, $secondary-hover);
    }
}

.card {
    color: #d27dbc;
}

.bank {
    color: #4fa58c;
}

.paypal {
    color: #5d80e5;
}

.rate,
.currency {
    font-size: 0.8rem;
}

.btc {
    color: $btc;
    font-weight: 700;
}

.usdt {
    color: $usdt;
    font-weight: 700;
}

.eur {
    color: $eur;
    font-weight: 700;
}

.eth {
    color: $eth;
    font-weight: 700;
}

.danger {
    color: var(--danger, $danger);
}
</style>