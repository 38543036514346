<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';

const overlay = ref();
let overflow = null;

onMounted(() => {
    const container = overlay.value.parentNode;
    overflow = getComputedStyle(container).overflow || 'auto';
    container.scrollTo(0, 0);
    container.style.overflow = 'hidden';
});

onBeforeUnmount(() => {
    overlay.value.parentNode.style.overflow = overflow;
});
</script>

<template>
    <div ref="overlay" class="preloader-overlay">
        <g-preloader/>
    </div>
</template>

<style lang="scss" scoped>
.preloader-overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    position: absolute;
    background-color: var(--popup-overlay, $popup-overlay);
}
</style>