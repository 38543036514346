import { STATE } from '@/Ship';
import { useAuthTasks} from '@auth';
const { reloadProfile, deleteToken } = useAuthTasks();

export default function () {
    STATE.IS_LOGIN = false;
    STATE.IS_LIGHT_MODE = false;
    localStorage.setItem('theme', 'dark');
    document.documentElement.setAttribute('theme', 'dark');
    deleteToken();
    reloadProfile();
}
