<script setup>
import { useLeadActions } from '@lead';
import { useTimelineActions } from '@timeline';

const getLeadResource = useLeadActions('getLeadResource');
const lead = getLeadResource();

const getTimelineResource = useTimelineActions('getTimelineResource');
const timeline = getTimelineResource(
	{filters: { lead_id: [lead.model.id] },},
	true,
	'LeadTimeline Component'
);


</script>

<template>
    <div class="timeline">
        <fieldset class="action" v-for="action in timeline.state" v-bind:key="`action-${action.id}`">
            <legend class="caption">
                <span class="time">{{ action.created_at }}</span>
            </legend>
            <span v-if="action.user" class="initiator">{{ action.user.name }}</span>
            <span v-else class="self-update">Self Update</span>
            <span v-html="action.body "></span>
        </fieldset>
    </div>
</template>

<style scoped lang="scss">
.timeline {
    margin-left: 60px;
    border-left: 1px solid var(--separator-color);
    color: var(--env-text-color, $env-text-color);
}

.action {
    margin: 20px 0;
    padding: 10px 0 10px 20px;
    position: relative;
    border-top: 1px solid var(--separator-color, $separator-color);

    & > .caption {
        width: 100%;

        &::before {
            content: '';
            position: absolute;
			top: -15px;
            left: -5px;
            width: 10px;
            height: 10px;
            border-radius: 5px;
            display: inline-block;
            background-color: var(--separator-color, $separator-color);
        }

        & > .time {
            color: var(--env-text-color, $env-text-color);
            border: 1px solid var(--env-text-color, $env-text-color);
            padding: 4px 8px;
            font-size: 0.9rem;
        }
    }

    & > .initiator {
        margin-right: 10px;
    }

    & > .self-update {
        margin-right: 10px;
        color: var(--danger-hover, $danger-hover);
    }

    &:deep(.container) {
        margin: 5px 0;
        color: var(--env-text-color, $env-text-color);

        & > h5 {
            letter-spacing: 0.4px;
            font-family: $font-title;
            color: var(--secondary, $secondary);
        }

        & > li {
            margin: 2px 0;

            & > .old-value {
                font-weight: bold;
                color: var(--env-text-color, $env-text-color);
            }

            & > .new-value {
                font-weight: bold;
                color: var(--env-title-color, $env-title-color);
            }
        }
    }
}
</style>