<script setup>
import { computed, defineEmits, reactive, ref } from 'vue';
import { useTeamActions } from '@team';
import { useUserActions } from '@user';

const emit = defineEmits(['performed']);

const getTeamResource = useTeamActions('getTeamResource');
const teams = getTeamResource();

const getTeamleads = useUserActions('getTeamleads');
const teamleads = ref([]);
getTeamleads().then(response => {
    teamleads.value = response.data;
});

const payload = reactive({
    name: '',
});


const desks = computed(() => {
    return teams.state.map(team => team.desk).filter(item => item).filter((value, index, array) => {
        return array.indexOf(value) === index;
    });
});

function makeTeam() {
    teams.create(payload).then(() => {
        emit('performed');
    });
}

</script>

<template>
    <div class="make-team">
        <fieldset>
            <legend>{{ $t('teams.name', 'Team Name') }}</legend>
            <g-input v-model="payload.name"/>
        </fieldset>
        <fieldset>
            <legend>{{ $t('teams.desk', 'Desk') }}</legend>
            <g-combobox v-model="payload.desk" v-bind:options="desks"/>
        </fieldset>

        <fieldset>
            <legend>{{ $t('teams.teamlead', 'Teamlead') }}</legend>
            <g-select v-model="payload.teamlead_id"
                      v-bind:options="teamleads"
                      option-text="name"
                      option-value="id"/>
        </fieldset>
        <g-flex class="btn-bar" gap="5" justify="end">
            <g-button class="make" type="submit" v-on:click="makeTeam">{{ $t('base.make', 'Make') }}</g-button>
            <g-button class="cancel" type="button" v-on:click="$emit('performed')">{{
                    $t('base.cancel', 'Cancel')
                }}
            </g-button>
        </g-flex>
    </div>
</template>

<style scoped lang="scss">
.make-team {
    width: 300px;
    max-width: 100%;
    gap: 8px;
    display: flex;
    flex-direction: column;
}

.btn-bar {
    margin-top: 10px;
}

.g-button {
    width: 100px;

    &.make {
        color: white;
        background-color: var(--primary, $primary);

        &:hover {
            background-color: var(--primary-hover, $primary-hover);
        }
    }

    &.cancel {
        color: var(--danger-hover, $danger-hover);
        background-color: transparent;
        border: 1px solid var(--danger, $danger);

        &:hover {
            color: white;
            background-color: var(--danger, $danger);
        }
    }
}
</style>