import { useOfferResources } from '@offer';

export default () => {
    const offer = useOfferResources('Offer');
    if (!offer.isLoaded && !offer.isLoading) {
        //campaign.setIncludes([]);
        offer.load();
    }

    return offer;
};
