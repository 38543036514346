import { useBuyerResources } from '@buyer';

export default () => {
    const buyer = useBuyerResources('Buyer');
    if (!buyer.isLoaded && !buyer.isLoading) {
        //buyer.setIncludes([]);
        buyer.load();
    }

    return buyer;
};
