import { useCampaignResources } from '@campaign';

export default (reload = false) => {
    const campaign = useCampaignResources('Campaign');
    //if (reload || (!campaign.isLoaded && !campaign.isLoading)) {
    //    campaign.setIncludes(['offer', 'buyer.teamlead', 'countries']);
    //    campaign.load();
    //}

    return campaign;
};
