<script setup>
import { ref, onBeforeUnmount, onMounted } from 'vue';
import { useProfileActions } from '@profile';
import { useFinanceResources } from '@finance';

const filters = useFinanceResources('FilterState');

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const deposits = useFinanceResources('Deposit');

const isFilterMenuOpen = ref(false);

const from = ref();
const to = ref();

onMounted(() => {
    window.addEventListener('click', handleGlobalClick);

    [from.value, to.value] = (filters.state.created_at && filters.created_at.length)
        ? filters.state.created_at[0].split('-')
        : [null, null];
});

onBeforeUnmount(() => {
    window.removeEventListener('click', handleGlobalClick);
});

const toggleCalendarMenu = () => {
    isFilterMenuOpen.value = !isFilterMenuOpen.value;
};

const handleGlobalClick = (event) => {
    if (isFilterMenuOpen.value) {
        const menuElement = document.querySelector('.calendar-menu');
        const calendarButton = document.querySelector('.filter-btn');

        if (!calendarButton.contains(event.target) && !menuElement.contains(event.target)) {
            closeCalendarMenu();
        }
    }
};

const closeCalendarMenu = () => {
    isFilterMenuOpen.value = false;
};

const filtering = () => {
    if (from.value && to.value) {
        filters.state.created_at = {[`${from.value}-${to.value}`]: `${from.value}-${to.value}`};
    } else {
        delete filters.state.created_at
    }

    Object.assign(filters.state, deposits.filters);
    deposits.setFilters(filters.state);
    deposits.load();

    profile.state.finances.filters = filters.state;
    profile.saveState();

    closeCalendarMenu();
};

const reset = () => {
    from.value = null;
    to.value = null;
    isFilterMenuOpen.value = false;

    filtering();

    profile.state.finances.filters.created_at = [];
    profile.saveState();
};

const setDateRange = () => {
    if (from.value && to.value) {
        from.value = formatDateToISO(from.value);
        to.value = formatDateToISO(to.value);
    }
};

const formatDateToISO = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}.${month}.${day}`;
};

const formatDateInput = (field, { target }) => {

    let value = target.value.replace(/\D/g, '');

    if (value.length > 4) {
        value = value.replace(/^(\d{4})/, '$1.');

        if (value.length > 7) {
            value = value.replace(/^(.{7})/, '$1.');

            if (value.length > 10) {
                value = value.slice(0, 10);
            }
        }
    }

    target.value = value;
};
</script>

<template>
    <div class="date-filter">
        <g-button class="filter-btn" v-on:click="toggleCalendarMenu" v-bind:class="{'active': isFilterMenuOpen}">
            <g-symbol v-bind:name="isFilterMenuOpen ? 'close' : 'calendar'" width="20" height="20"/>
        </g-button>
        <div class="calendar-menu" v-if="isFilterMenuOpen" v-on:click.stop>
            <g-flex class="action-block" gap="5">
                <g-input v-model="from"
                         v-bind:reset-btn="false"
                         placeholder="YYYY-MM-DD"
                         v-on:input="formatDateInput('from', $event)"/>

                <g-input v-model="to"
                         v-bind:reset-btn="false"
                         placeholder="YYYY-MM-DD"
                         v-on:input="formatDateInput('to', $event)"/>

            </g-flex>
            <div class="date-block">
                <g-date-picker v-model:from="from"
                               v-model:to="to"
                               v-on:change="setDateRange"/>
            </div>
            <g-flex class="action-block" justify="center" gap="4">
                <g-button class="cancel-btn"
                          type="reset"
                          v-on:click="reset"
                          :disabled="!from || !to">
                    {{ $t('base.reset', 'Reset') }}
                </g-button>
                <g-button class="save-btn"
                          v-on:click="filtering"
                          :disabled="!from || !to">
                    {{ $t('base.apply', 'Apply') }}
                </g-button>
            </g-flex>
        </div>
    </div>
</template>

<style scoped lang="scss">
.date-filter {
    position: relative;
    color: var(--main-text-color, $main-text-color);
}

.filter-btn {
    width: 40px;
    height: 40px;
    font-size: 0;
    fill: white;
    cursor: pointer;
    position: relative;
    border-radius: 50% !important;
    background-color: transparent;

    &:not(.active):hover {
        transform: scale(1.2);
    }

    &.active {
        background-color: #1d212f;
        box-shadow: 0 0 10px #00b0b5;
    }
}

.calendar-menu {
    position: absolute;
    top: 50px;
    left: -5px;
    padding: 10px;
    background-color: var(--secondary, $secondary);
    border-radius: var(--dropdown-rounding, $dropdown-rounding);
    box-shadow: 0 6px 15px -5px #00000088;
}

.action-block {
    padding: 8px 4px;

    .g-button {
        padding: 0 20px;

        &:hover {
            background-color: var(--secondary-hover, $secondary-hover);
        }
    }
}

.date-block {
    width: 274px;
    overflow: hidden;
}

.g-input,
.g-select {
    background-color: #1d212f;
    color: var(--main-text-color, $main-text-color);
}
</style>