import { useUserResources } from '@user';

export default () => {
    const team = useUserResources('Team');
    if (!team.isLoaded && !team.isLoading) {
        team.sort('id', 'desc')
        //team.setIncludes([]);
        team.load();
    }

    return team;
};
