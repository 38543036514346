<script setup>

import { computed } from 'vue';
import { i18n, PopUpManager as PopUp } from '@/Ship';
import { useTeamActions } from '@team';

const getTeamResource = useTeamActions('getTeamResource');

const teams = getTeamResource(true);
const desks = computed(() => {
    return teams.state.map(team => team.desk).filter(item => item).filter((value, index, array) => {
        return array.indexOf(value) === index;
    });
});

function removeTeam(index) {
    PopUp
        .setCaption(i18n.global.t('teams.remove_team', 'Remove team'))
        .setMessage(`<p class="message"><b>${i18n.global.t('teams.remove_confirm', { id: teams.state[index].id }, 'The team with ID:{id} will be permanently deleted')}!</b></p>`)
        .setAction(() => {
            teams.delete(teams.state[index].id).then(() => {
                teams.state.splice(index, 1);
            });

            PopUp.close();
        })
        .open(require('@/Ship/components/PopUps/Confirm'));
}

function updateTeam(index, field) {
    teams.save(teams.state[index].id, {
        [field]: teams.state[index][field]
    });
}
</script>

<template>
    <div class="teams">

        <g-preloader-overlay v-if="teams.isLoading"/>

        <g-flex class="toolbar" gap="5" align="center" justify="end">
            <g-button class="tool" v-on:click="PopUp.open(require('@team/components/PopUps/MakeTeam')).setCaption($t('settings.make_team', 'Make Team'))">
                <g-symbol name="users" width="26" height="26"/>
                <g-symbol name="plus" width="18" height="18"/>
            </g-button>
        </g-flex>

        <div class="index">

            <div class="row header">
                <div class="id title cell">ID</div>
                <div class="name title cell">{{ $t('settings.team_name', 'Team name') }}</div>
                <div class="desk title cell"></div>
                <div class="desk title cell"></div>
                <div class="desk title cell">{{ $t('settings.team_members', 'Members') }}</div>
                <div class="date title cell">{{ $t('base.created_at', 'Created at') }}</div>
                <div class="action title cell"></div>
            </div>

            <div class="row" v-for="(team, i) in teams.state" :key="`team-${team.id}`">
                <div class="id cell">{{ team.id }}</div>
                <div class="name cell">{{ team.name }}</div>
                <div class="desk cell">
                    <g-flex align="end" gap="3">
                        <g-combobox v-model="teams.state[i].desk"
                                    v-bind:label="$t('teams.desk', 'Desk')"
                                    v-bind:options="desks"/>
                        <g-button class="action-btn send-btn with-tooltip"
                                  v-on:click="updateTeam(i, 'desk')"
                                  v-bind:data-tooltip="$t('teams.save_desk', 'Save desk')">
                            <g-symbol name="send" width="16" height="16"/>
                        </g-button>
                    </g-flex>
                </div>
                <div class="teamlead cell">
                    <g-select v-model="teams.state[i].teamlead_id"
                              v-bind:options="(team.users || []).filter(user => user.roles.map(role => role.name).includes('Teamlead'))"
                              option-text="name"
                              option-value="id"
                              v-on:change="updateTeam(i, 'teamlead_id')"
                              v-bind:label="$t('teams.team_lead', 'Team lead')"/>
                </div>
                <div class="members cell">{{ (team.users || []).map(user => user.name).join(', ') }}</div>
                <div class="date cell">{{ team.created_at }}</div>
                <div class="action cell">
                    <g-button class="action-btn remove-btn"
                              type="button"
                              v-on:click="removeTeam(i)">
                        <g-symbol name="delete" width="16" height="16"/>
                    </g-button>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.environment {
    background-color: var(--env-bg, $env-bg);
}

.toolbar {
    padding: 12px 20px;

    .tool {
        cursor: pointer;
        position: relative;
        width: 42px;
        fill: #eeeeee;
        background-color: var(--success, $success);

        & > .g-symbol:last-child {
            fill: black;
            padding: 4px;
            right: 4px;
            bottom: 4px;
            border-radius: 50%;
            position: absolute;
            background-color: #ffffffdd;
        }

        &:hover {
            fill: white;
            background-color: var(--success-hover, $success-hover);

            & > .g-symbol:last-child {
                fill: white;
                background-color: black;
            }
        }
    }
}

.remove-btn {
    border: none;
    width: 40px;
    background-color: transparent;
    fill: var(--env-text-color, $env-text-color);

    &:hover {
        fill: var(--danger, $danger);
    }
}

.send-btn {
    width: 50px;
    height: 35px;
    color: white;
    margin: 0 2px;
    font-size: 0;
    background-color: var(--primary, $primary);
    fill: var(--main-text-color, $main-text-color);
    border: 1px solid var(--main-text-color, $main-text-color);
    position: relative;

    &::after {
        left: 50%;
        transform: translateX(-50%);
        top: calc(100% + var(--tooltip-arrow-size, $tooltip-arrow-size));
    }

    &::before {
        transform: translateX(-50%);
        left: 50%;
        top: calc(100% - var(--tooltip-arrow-size, $tooltip-arrow-size));
    }

    &:hover {
        border-color: transparent;
        background-color: var(--primary-hover, $primary-hover);

        &::after {
            background-color: var(--primary-hover, $primary-hover);
        }

        &::before {
            border-bottom-color: var(--primary-hover, $primary-hover);
        }
    }
}

.index {
    min-width: 100%;
    padding: 0 10px;
    position: relative;
    min-height: 100px;
}

.row {
    fill: var(--main-text-color, $main-text-color);

    &:nth-child(even) {
        background-color: #00000011;
    }

    &:not(.header) {
        cursor: pointer;

        & > .cell {
            border: 0 solid transparent;
            border-width: 1px 0;
        }
    }
}

.cell {
    white-space: nowrap;

    &.title {
        cursor: default;
    }

    &.members {
        white-space: normal;
    }

    &.date {
        text-align: center;
    }

    &.action {
        width: 58px;
    }
}

@media (min-width: 1025px) {
    .index {
        display: table;

        .row {
            display: table-row;

            & > .cell {
                padding: 8px;
                display: table-cell;
                vertical-align: middle;
            }
        }
    }
}

@media (max-width: 1024px) {
    .index {
        display: table;

        .row {
            display: table-row;

            & > .cell {
                padding: 8px 5px;
                display: table-cell;
                vertical-align: middle;
            }
        }
    }
}
</style>
