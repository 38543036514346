import { useActivityResources } from '@activity';

export default (params, reload = false, referer = null) => {
    const activity = useActivityResources('Activity');
    if (reload || (!activity.isLoaded && !activity.isLoading)) {
        activity.setFilters(params.filters);
        activity.load(null, {}, referer);
    }

    return activity;
};
