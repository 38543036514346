<script setup>
import { ref, watch, computed, onMounted } from 'vue';
import { useSettingsResources } from '@settings';
import { useProfileActions } from '@profile';

const settings = useSettingsResources('Settings');
settings.load();

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const role = ref('Admin');
const entity = ref('All');
const newRole = ref('');
const rolePermissions = computed({
    get() {
        return (settings.permissions || []).map(permission => permission.id || permission);
    },
    set(value) {
        settings.permissions = value;
    }
});

function addRole() {
    settings.create({
        section: 'role',
        name: newRole.value
    }).then(response => {
        settings.roles.push(response);
        newRole.value = '';
    });
}

const entities = computed(() => {
    if (settings['all-permissions']) {
        const uniquePermissionsDict = { All: { entity: 'All' } };

        for (const permission of settings['all-permissions']) {
            uniquePermissionsDict[permission.entity] = permission;
        }

        return Object.values(uniquePermissionsDict);
    }

    return [];
});

const permissions = computed(() => {
    if (!settings['all-permissions']) { return []; }

    return settings['all-permissions'].filter(item => entity.value === 'All' || item.entity === entity.value);
});

function removeRole(index) {
    settings.delete('role', { name: settings.roles[index].name }).then(() => {
        settings.roles.splice(index, 1);
    });
}

onMounted(() => {
    settings.load('permissions', { role: role.value });
});

watch(role, value => {
    settings.load('permissions', { role: value });
});

function setPermission() {
    settings.save('permissions', {
        role: role.value,
        permissions: settings.permissions
    });
}

</script>

<template>
    <div class="permissions-wrapper">

        <g-preloader-overlay v-if="settings.isLoading"/>

        <!-- ROLES -->
        <div class="roles">

            <g-caption size="4" weight="600">{{ $t('settings.roles') }}</g-caption>

            <div class="container">
                <table>
                    <tbody>
                    <tr v-for="(item, i) in settings.roles"
                        v-bind:key="`role-${item.id}`"
                        v-bind:class="{active: item.name === role}">
                        <td class="index">{{ i + 1 }}</td>
                        <td class="role-name" v-on:click="role = item.name">{{ item.name }}</td>
                        <td class="actions">
                            <g-symbol v-if="profile.can('Permission Edit')"
                                      name="close"
                                      width="16"
                                      height="16"
                                      class="remove-btn with-tooltip"
                                      v-on:click.stop="removeRole(i)"/>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <g-input v-model="newRole"
                     v-bind:placeholder="$t('settings.new_role')"
                     v-on:keypress.enter="addRole"
                     v-bind:disabled="profile.cant('Permission Edit')">
                <button v-if="newRole"
                        type="button"
                        class="add-btn with-tooltip"
                        v-bind:data-tooltip="$t('settings.add_role')"
                        v-on:click="addRole">
                    <g-symbol name="import" width="18" height="18"/>
                </button>
            </g-input>
        </div>
        <!--/ ROLES -->

        <!-- PERMISSIONS -->
        <div class="permissions">
            <g-select v-model="entity"
                      v-bind:options="entities"
                      option-text="entity"
                      option-value="entity"/>

            <div class="container">
                <g-checkbox v-model="rolePermissions"
                            v-for="permission in permissions"
                            v-bind:key="`permission-${permission.id}`"
                            v-bind:value="permission.id"
                            v-on:change="setPermission"
                            v-bind:disabled="profile.cant('Permission Edit')">
                    {{ permission.name }}
                </g-checkbox>
            </div>
        </div>
        <!--/ PERMISSIONS -->
    </div>
</template>

<style scoped lang="scss">
.permissions {
    position: relative;
    container-type: inline-size;
    container-name: permissions;
    flex-grow: 1;
    padding: 60px 15px 15px;
    border: 1px solid var(--separator-color, $separator-color);

    & > .g-select {
        top: 10px;
        left: 10px;
        width: 150px;
        position: absolute;

        &:deep(.field),
        &:deep(.datalist) {
            color: white;
            border-radius: 5px;
            background-color: var(--primary, $primary);
        }

        &:deep(.arrow) {
            fill: white;
        }
    }

    .g-checkbox {
        margin: 5px 0;
        width: 100%;
        display: inline-block;
    }

    :deep(.preloader-wrapper) {
        height: calc(100% + 22px) !important;
        top: -22px !important;
    }
}

.roles {
    position: relative;
    padding: 8px 15px 15px;
    border: 1px solid var(--separator-color, $separator-color);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    :deep(.preloader-wrapper) {
        height: calc(100% + 10px) !important;
        top: -10px !important;
    }
}

.container {
    flex-grow: 1;
    overflow: auto;
}

table {
    width: 100%;

    tr {
        &:nth-child(even) {
            background-color: #00000011;
        }

        &.active {
            color: var(--success-hover, $success-hover);
        }
    }

    td {
        padding: 8px;

        &:last-child {
            width: 24px;
            text-align: center;
        }

        &.role-name {
            cursor: pointer;
        }

        &.actions {
            & > .g-symbol {
                cursor: pointer;
                fill: var(--danger, $danger);

                &:hover {
                    fill: var(--danger-hover, $danger-hover);
                }
            }
        }
    }
}

.add-btn {
    width: 24px;
    height: 24px;
    text-align: center;
    background-color: transparent;
    font-size: 0;

    &::after {
        white-space: nowrap;
        bottom: calc(100% + var(--tooltip-arrow-size, $tooltip-arrow-size));
    }

    &:hover::before {
        border-top-color: var(--tooltip-bg, $tooltip-bg);
        bottom: calc(100% - var(--tooltip-arrow-size, $tooltip-arrow-size));
    }
}

.with-tooltip {
    position: relative;
    fill: var(--title-color, $title-color);

    &::after {
        left: 50%;
        transform: translateX(-50%);
    }

    &::before {
        left: 50%;
        transform: translateX(-50%);
    }
}

.g-checkbox {
    width: 100%;
    margin: 4px 0;
    display: inline-block;

    &:deep(.label) {
        display: inline-block;
        white-space: nowrap;
    }
}

@media (min-width: 745px) {
    .permissions-wrapper {
        gap: 10px;
        display: flex;
        align-items: stretch;
    }

    .roles {
        width: 300px;
    }
}

@container permissions (min-width: 1500px) {
    .permissions > .container {
        column-count: 8;
    }
}

@container permissions (min-width: 1000px) and (max-width: 1500px) {
    .permissions > .container {
        column-count: 6;
    }
}

@container permissions (min-width: 800px) and (max-width: 1000px) {
    .permissions > .container {
        column-count: 5;
    }
}

@container permissions (min-width: 700px) and (max-width: 800px) {
    .permissions > .container {
        column-count: 4;
    }
}

@container permissions (min-width: 500px) and (max-width: 700px) {
    .permissions > .container {
        column-count: 3;
    }
}

@container permissions (min-width: 350px) and (max-width: 500px) {
    .permissions > .container {
        column-count: 2;
    }
}
</style>